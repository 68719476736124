import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import '../index.css';
import ReactTable from 'react-table';
import RegionMenu from "./RegionMenu";
import Modal from "react-responsive-modal";
import AddPupilForm from "./AddPupilForm";

class RegionPupilList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      districts: [],
      schools: [],
      pupils: [],
      addPupilModal: false
    }
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let regionId = userData !== undefined && userData !== null ? userData.stateId : null;
    axios.get(_.apiHost + '/pupil/district/list?stateId=' + regionId)
      .then(result => {
        if (result.data.statusCode === 200) {
          let resultData = result.data.data !== undefined ? result.data.data : [];
          if (resultData.length > 0) {
            let options = [];
            for (let i = 0; i < resultData.length; i++) {
              let item = resultData[i];
              options.push(<option key={item.id} value={item.id}>{item.name}</option>)
            }
            this.setState({
              districts: options
            })
          }
        }
      })
  }

  getSchoolList() {
    let districtId = document.getElementById('districts').value;
    axios.get(_.apiHost + '/pupil/school/list?districtId=' + districtId)
      .then(result => {
        if (result.data.statusCode === 200) {
          let resultData = result.data.data !== undefined ? result.data.data : [];
          if (resultData.length > 0) {
            let options = [];
            for (let i = 0; i < resultData.length; i++) {
              let item = resultData[i];
              options.push(<option key={item.id} value={item.id}>{item.name}</option>)
            }
            this.setState({
              schools: options
            })
          }
        }
      })
  }

  getPupilList() {
    let schoolId = document.getElementById('schools').value;
    let klass = document.getElementById('klass').value;
    let klassLetter = '';
    if (klass === undefined || klass === null) {
      klass = 11;
    }
    axios.get(_.apiHost + '/pupil/list2?schoolId=' + schoolId + '&klass=' + klass + '&klassLetter=' + klassLetter)
      .then(result => {
        if (result.data.statusCode === 200) {
          let resultData = result.data.data !== undefined ? result.data.data : [];
          this.setState({
            pupils: resultData
          });
        }
      })
  }

  openAddPupilForm() {
    this.setState({
      addPupilModal: true
    })
  }

  closeAddPupilForm() {
    this.setState({
      addPupilModal: false
    })
  }

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div>
        <div id='__react-content' className="flt topblock">
          <RegionMenu/>
        </div>
        {userData !== undefined && userData !== null &&
        <table>
          <tbody>

          <tr>
            <td className="left" width={'100%'}>
              <div className="row">
                <div className="col s3">
                  <select id="districts" onChange={this.getSchoolList.bind(this)}>
                    {this.state.districts}
                  </select>
                </div>
                <div className="col s3">
                  <select id="schools" onChange={this.getPupilList.bind(this)}>
                    {this.state.schools}
                  </select>
                </div>
                <div className="col s3">
                  <select id="klass" onChange={this.getPupilList.bind(this)}>
                    <option value="11">11-sinf</option>
                    <option value="10">10-sinf</option>
                    <option value="9">9-sinf</option>
                    <option value="8">8-sinf</option>
                    <option value="7">7-sinf</option>
                  </select>
                </div>

                <div className="right col s3">
                  <button className="btn btn-block" style={{width: 'max-content', float: 'right'}}
                          onClick={this.openAddPupilForm.bind(this)}>
                    O`quvchi qo`shish
                  </button>
                  <Modal open={this.state.addPupilModal} center closeOnEsc={true}
                         onClose={this.closeAddPupilForm.bind(this)}>
                    <AddPupilForm/>
                  </Modal>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <ReactTable data={this.state.pupils}
                          columns={[{
                            columns: [{
                              Header: _.strings.lastName,
                              accessor: 'lastName'
                            }, {
                              Header: _.strings.firstName,
                              accessor: 'firstName'
                            }, {
                              Header: _.strings.middleName,
                              accessor: 'middleName'
                            }, {
                              Header: _.strings.schoolName,
                              accessor: 'schoolName'
                            }]
                          }]}
                          noDataText={_.strings.noDataText}
                          previousText={_.strings.previousText}
                          nextText={_.strings.nextText}
                          pageText={_.strings.pageText}
                          ofText={_.strings.ofText}
                          rowsText={_.strings.rowsText}
                          defaultPageSize={10}
                          multiSort={false}
                          resizable={true}
                          showPageSizeOptions={true}
                          className="-striped -highlight"
                          style={{width: "1000px"}}
              />
            </td>
          </tr>
          </tbody>
        </table>
        }
        {(userData === undefined || userData === null) &&
        <span>Tizimga kirish uchun login varolni to`g`ri tering</span>
        }
      </div>
    )
  }
}

export default RegionPupilList;