import React, {Component} from 'react';
import ReactTable from 'react-table';
import axios from "axios";
import * as _ from './Utils';
import Swal from 'sweetalert2';
import './react-table.css';
import './index.css';

class PsychologistPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      schoolList: [],
      klassList: ["10", "11"],
      reportData: []
    }
  }

  componentDidMount() {
    let options = [];
    axios.get(_.apiHost + '/pupil/school/list?districtId=23')
      .then(data => {
        if (data.data.statusCode === 200) {
          for (let i = 0; i < data.data.data.length; i++) {
            let user = data.data.data[i];
            options.push(<option key={user.id} value={user.id}>{user.name}</option>)
          }
          this.setState({
            schoolList: options
          })
        }
      });
  }

  saveBatchPupilList() {
    let schoolId = document.getElementById('schools').value;
    let files = this.refs.uploadImg.files;
    let formData = new FormData();
    formData.append("schoolId", schoolId);
    formData.append("file", files[0]);
    axios.post(_.apiHost + '/pupil/batchAdd', formData, {
      headers: {'Content-type': 'multipart/form-data'},
    }).then(response => {
      if (response.data.statusCode === 200) {
        Swal.fire("Ma'lumot saqlandi", '', "success");
      } else if (response.data.statusCode === 500) {
        Swal.fire("Xatolik", response.data.data, "error");
      }
    });
  }

  savePupilList() {
    let schoolId = document.getElementById('schools').value;
    let klasses = document.getElementById('klasses').value;
    let lastName = document.getElementById('lastName').value;
    let firstName = document.getElementById('firstName').value;
    let formData = new FormData();
    formData.append("schoolId", schoolId);
    formData.append("klass", klasses);
    formData.append("lastName", lastName);
    formData.append("firstName", firstName);
    axios.post(_.apiHost + '/pupil/add', formData, {
      headers: {'Content-type': 'application/json'},
    }).then(response => {
      if (response.data.statusCode === 200) {
        Swal.fire("Ma'lumot saqlandi", '', "success");
      } else if (response.data.statusCode === 500) {
        Swal.fire("Xatolik", response.data.data, "error");
      }
    });
  }

  getReportData() {
    axios.get(_.apiHost + '/pupil/getReportData').then(response => {
      if (response.data.statusCode === 200) {
        this.setState({
          reportData: response.data.data
        })
      } else if (response.data.statusCode === 500) {
        Swal.fire("Xatolik", response.data.data, "error");
      }
    });
  }

  exportToExcel() {
    let form = document.getElementById('excelForm');
    form.submit();
  }

  render() {
    return (
      <div style={{margin: "10px", alignItems: "left"}}>
        <select id="schools" style={{margin: "10px 0", width: "500px"}}>
          {this.state.schoolList}
        </select>
        <div style={{border: "1px solid #000000", width: "500px", padding: "10px", height: "115px"}}>
          <h5>Exceldan yuklash</h5>
          <input type="file" id="fileName" ref="uploadImg" onChange={this.onChange} style={{float: "left"}}/>
          <button className="btn btn-block" onClick={this.saveBatchPupilList.bind(this)} style={{float: "right"}}>Kiritish
          </button>
        </div>
        <div style={{border: "1px solid #000000", width: "500px", padding: "10px", height: "283px", marginTop: "10px"}}>
          <h5>O`quvchi kiritish</h5>
          <div className="row">
            <div className="col s4"/>
            <div className="col s8">
              <select id="klasses" style={{margin: "10px 0"}}>
                <option key="10" value="10">10-sinf</option>
                <option key="11" value="11">11-sinf</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col s4">
              <span>Familiyasi:</span>
            </div>
            <div className="col s8">
              <input type="text" id="lastName"/>
            </div>
          </div>
          <div className="row">
            <div className="col s4">
              <span>Ismi:</span>
            </div>
            <div className="col s8">
              <input type="text" id="firstName"/>
            </div>
          </div>
          <div className="row">
            <div className="col s12 center">
              <button className="btn btn-block" onClick={this.savePupilList.bind(this)} style={{float: "right"}}>
                Kiritish
              </button>
            </div>
          </div>
        </div>
        <div style={{border: "1px solid #000000", width: "845px", padding: "10px", height: "630px", marginTop: "10px"}}>
          <h5>Kunlik hisobot</h5>
          <div className="row">
            <div className="col s12">
              <ReactTable filterable={true}
                          data={this.state.reportData}
                          columns={[{
                            columns: [{
                              Header: 'Yo`nalish',
                              accessor: 'result',
                            }, {
                              Header: 'Soni',
                              accessor: 'count',
                            }, {
                              Header: '%',
                              accessor: 'percent',
                            }]
                          }]}
                          noDataText={_.strings.noDataText}
                          previousText={_.strings.previousText}
                          nextText={_.strings.nextText}
                          pageText={_.strings.pageText}
                          ofText={_.strings.ofText}
                          rowsText={_.strings.rowsText}
                          defaultPageSize={20}
                          multiSort={false}
                          resizable={true}
                          showPageSizeOptions={true}
                          className="-striped -highlight"
                          style={{width: "800px"}}
              />
            </div>
          </div>

          <div className="row" style={{marginTop: "5px", display: "flex"}}>
            <div className="col s12 center">
              <button className="btn btn-block" style={{float: "left"}} onClick={this.getReportData.bind(this)}>
                Yangilash
              </button>
              <form id="excelForm" method="post" action="/common/dailyReportExcel" style={{float: "left", marginLeft: "5px"}}>
                <button className="btn btn-block" onClick={this.exportToExcel.bind(this)}>Excel</button>
              </form>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default PsychologistPage;