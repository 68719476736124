import React, {Component} from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import * as _ from './Utils';

class ShortCourseRegistrationPage extends Component {

    constructor() {
        super();
        this.state = {
            districtId: null,
            lastName: '',
            firstName: '',
            middleName: '',
            userName: '',
            password: '',
            districts: []
        };
        this.setValue = this.setValue.bind(this);
    }

    setValue(e) {
        let name = e.target.id;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    register() {
        let errors = 0;
        let districtId = document.getElementById('districts').value;
        if (districtId === '' || Number(districtId) === 0) {
            errors += 1;
        }
        if (this.state.lastName === '') {
            errors += 1;
        }
        if (this.state.firstName === '') {
            errors += 1;
        }
        if (this.state.middleName === '') {
            errors += 1;
        }
        if (this.state.userName === '') {
            errors += 1;
        }
        if (this.state.password === '') {
            errors += 1;
        }
        if (+errors > 0) {
            swal('Hamma malumotlarni kiriting', '', 'error');
        } else {
            let formData = new FormData();
            formData.append("districtId", districtId);
            formData.append("lastName", this.state.lastName);
            formData.append("firstName", this.state.firstName);
            formData.append("middleName", this.state.middleName);
            formData.append("userName", this.state.userName);
            formData.append("password", this.state.password);
            axios.post(_.apiHost + '/teacher/shortAdd', formData)
                .then(result=>{
                    if (result.data.statusCode === 200) {
                        swal(result.data.message, '', 'info');
                    } else {
                        swal(result.data.message, '', 'error');
                    }
                })
        }
    }

    getDistricts(e) {
        let stateId = e.target.value;
        axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
            .then(data => {
                if (data.data.statusCode === 200) {
                    let districtList = [];
                    for (let i = 0; i < data.data.data.length; i++) {
                        let user = data.data.data[i];
                        districtList.push(<option key={user.id} value={user.id}>{user.name}</option>)
                    }
                    this.setState({
                        districts: districtList
                    })
                } else {
                    this.setState({
                        districts: []
                    })
                }
            })
    }

    render() {
        return (
            <div className="container text-center">
                <div className="form-row">
                    <div className="col-md-12 text-center">
                        <h1 className="h4 mb-3 font-weight-normal">Tizimda ro`yxatdan o`tish</h1>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="regions" className="right">Viloyat:</label>
                    </div>
                    <div className="col-md-6">
                        <select id="regions" name="regions" className="form-control" onChange={this.getDistricts.bind(this)}>
                            <option value="1">Qoraqalpog`iston Respublikasi</option>
                            <option value="2">Andijon viloyati</option>
                            <option value="3">Buxoro viloyati</option>
                            <option value="4">Qashqadaryo viloyati</option>
                            <option value="5">Jizzax viloyati</option>
                            <option value="6">Navoiy viloyati</option>
                            <option value="7">Namangan viloyati</option>
                            <option value="8">Samarqand viloyati</option>
                            <option value="9">Sirdaryo viloyati</option>
                            <option value="10">Surxondaryo viloyati</option>
                            <option value="11">Toshkent viloyati</option>
                            <option value="12">Farg`ona viloyati</option>
                            <option value="13">Xorazm viloyati</option>
                            <option value="14">Toshkent shahri</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="districts" className="right">Tuman:</label>
                    </div>
                    <div className="col-md-6">
                        <select id="districts" name="districts" className="form-control">
                            <option value={0}>Tanlang</option>
                            {this.state.districts}
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="lastName" className="right">Familiyasi:</label>
                    </div>
                    <div className="col-md-6">
                        <input type="text" id="lastName" name="lastName" className="form-control" required autoFocus="" onChange={this.setValue}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="firstName" className="right">Ismi:</label>
                    </div>
                    <div className="col-md-6">
                        <input type="text" id="firstName" name="firstName" className="form-control" required onChange={this.setValue}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="middleName" className="right">Sharifi:</label>
                    </div>
                    <div className="col-md-6">
                        <input type="text" id="middleName" name="middleName" className="form-control" required onChange={this.setValue}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="userName" className="right">Login:</label>
                    </div>
                    <div className="col-md-6">
                        <input type="text" id="userName" name="userName" className="form-control" required onChange={this.setValue}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="password" className="right">Parol:</label>
                    </div>
                    <div className="col-md-6">
                        <input type="password" id="password" name="password" className="form-control" required onChange={this.setValue}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-primary btn-block" type="button" onClick={this.register.bind(this)}>Ro`yxatdan o`tish</button>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <span className="red">Yakuniy sertifikat to`g`ri chiqishi uchun familiya, ismi, sharifingizni to`g`ri kiriting</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortCourseRegistrationPage;