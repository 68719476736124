import React, {Component} from 'react';
import Header from "./Header";
import Modal from "react-responsive-modal";
import ShortCourseRegistrationPage from "./ShortCourseRegistrationPage";
import ShortCourseLoginPage from "./ShortCourseLoginPage";

class ShortTimeCoursesStartPage extends Component {

    constructor() {
        super();
        this.state = {
            showRegisterModal: false,
            showLoginModal: false
        }
    }

    openModal(modalName) {
        if (modalName === 'showLoginModal') {
            this.setState({
                showLoginModal: true
            })
        } else if (modalName === 'showRegisterModal') {
            this.setState({
                showRegisterModal: true
            })
        }
    }

    hideModal(modalName) {
        if (modalName === 'showLoginModal') {
            this.setState({
                showLoginModal: false
            })
        } else if (modalName === 'showRegisterModal') {
            this.setState({
                showRegisterModal: false
            })
        }
    }

    render() {
        return (
            <div>
                <Header/>
                <p className="text-center"><span>Psixologik-pedagogik diagnostika metodikalarni<br/>elektron dasturiga xush kelibsiz</span></p>

                <div className="container text-center">
                    <div className="form-row">
                        <div className="col-md-6">
                            <div className="classBox right">
                                <span className="linkStyle" onClick={this.openModal.bind(this, 'showRegisterModal')}>Ro`yxatdan o`tish</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="classBox">
                                <span className="linkStyle" onClick={this.openModal.bind(this, 'showLoginModal')}>Tizimga kirish</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.showRegisterModal} center closeOnEsc={true} onClose={this.hideModal.bind(this, 'showRegisterModal')}>
                    <ShortCourseRegistrationPage/>
                </Modal>
                <Modal open={this.state.showLoginModal} center closeOnEsc={true} onClose={this.hideModal.bind(this, 'showLoginModal')}>
                    <ShortCourseLoginPage/>
                </Modal>
            </div>
        )
    }
}

export default ShortTimeCoursesStartPage;