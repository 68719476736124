import React, {Component} from 'react';
import * as _ from './Utils';
import {strings} from './Utils';
import axios from 'axios';
import TestItem from './TestItem';
import Swal from 'sweetalert2';
import {Navigate} from "react-router";

class Testing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            question: '',
            questions: [],
            questionCount: 0,
            questionNumber: 0,
            pupilsAnswers: [],
            selectedTest: {},
            pupil: {},
            language: '1',
            tendencies: [],
            answers: [],
            answers1: [],
            fullAnswered: false,
            fullAnswer: '',
            toDownload: false,
            toHome: false,
            toTest: false,
            downloadLink: '',
	        toTesting: false,
	        NavigateToHomePage: false,
            NavigateToShortCoursesPage: false
        };
        this.init = this.init.bind(this);
        this.onFinishClick = this.onFinishClick.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        let selectedTest = JSON.parse(localStorage.getItem('selectedTest'));
        let language = localStorage.getItem('language');
        strings.setLanguage(language === '2' ? "ru" : "uz");
        let questions = [];
        if (selectedTest !== null && +selectedTest.id === 11) {
            questions = new Array(30).fill(+language === 1 ? "Қуйидаги вазиятлардан бирини танланг:" : "Выберите одну из следующих ситуаций:");
        } else {
	        questions = JSON.parse(localStorage.getItem('selectedTestQuestions'));
        }
        let selectedPupil = JSON.parse(localStorage.getItem('pupil'));

        let pupilsAnswers = [];
        for (let i = 0; i < questions.length; i++) {
            pupilsAnswers.push('');
        }
        let tendencies = [];
        switch (Number(selectedTest.id)) {
            case 1:
                if (Number(language) === 1) {
                    tendencies = ["Fizika va Matematika", "Kimyo-biologiya", "Radiotexnika va elektronika", "Mexanika-konstruksiyalash",
                        "Geografiya va geologiya", "Adabiyot va sanat", "Tarix va siyosat", "Pedagogika va tibbiyot",
                        "Tadbirkorlik va uy-rozgor", "Sport va harbiy ish"];
                } else if (Number(language) === 2) {
                    tendencies = ["Физика и математика", "Химия и биология", "Радиотехника и электроника", "Механика и конструирование",
                        "География и геология", "Литература и искусство", "История и политика", "Педагогика и медицина",
                        "Предпринимательство и домоводство", "Спорт и военное дело"];
                }
                break;
            case 2:
                if (Number(language) === 1) {
                    tendencies = ["Inson-tabiat", "Inson-texnika", "Inson-inson", "Inson-belgili tizim", "Inson-badiiy obraz"];
                } else if (Number(language) === 2) {
                    tendencies = ["Человек-природа", "Человек-техника", "Человек-человек", "Человек-знаковая система", "Человек-художественный образ"];
                }
                break;
            case 3:
                tendencies = ["Sanoat", "Transport", "Aloqa", "Qurilish", "Uy-joy va kommunal xo`jaligi", "Tibbiyot", "Qishloq va o`rmon xo`jaligi", "Pedagogika", "San`at va madaniyat", "Ijtimoiy, iqtisodiy soha", "Savdo, umumiy ovqatlanish va xizmat ko`rsatish"];
                break;
            case 7:
                tendencies = ["Ota onaga", "Hayvonlarga", "Qariyalarga", "Bolalarga", "Badiiy qahramonlarga", "Notanish odamlarga"];
                break;
            case 11:
                tendencies = language === '1' ? ["Raqobat", "Hamkorlik", "Murosachilik", "Tortishuvdan qochish", "Vaziyatga moslashish"] : ["Соперничество", "Сотрудничество", "Компромисс", "Избежание", "Приспособление"];
                break;
            case 13:
                tendencies = language === '1' ? ["Hissiy charchoq", "Shaxsiylashtirish", "Shaxsiy yutuqlarning pasayishi"] : ["Эмоциональное истощение", "Деперсонализация", "Редукция персональных достижений"];
                break;
            case 14:
                if (Number(language) === 1) {
                    tendencies = ["Mantiqiy-matematik intellekt", "Vizual-fazoviy intellekt", "Verbal yoki lingvistik intellekt", "Tana-kinestetik intellekt", "Musiqiy intellekt-musiqa yozish va baholash", "Emotsional yoki shaxslararo intellekt", "Ekzistensial yoki manaviy intellekt"];
                } else if (Number(language) === 2) {
                    tendencies = ["Математико-логический", "Визуально-пространственный", "Лингвистический", "Кинестетический", "Музыкальный", "Межличностный", "Внутриличностный"];
                }
                break;
            default:
                tendencies = [];
        }

        let answers = [];
        if (Number(selectedTest.id) === 1) {
            if (language === '2') {
                answers.push({"id": "1", 'answer': "Очень нравится"});
                answers.push({"id": "2", "answer": "Просто нравится"});
                answers.push({"id": "3", "answer": "Сомневаетесь"});
                answers.push({"id": "4", "answer": "Не нравится"});
                answers.push({"id": "5", "answer": "Очень не нравиться"});
            } else {
                answers.push({"id": "1", 'answer': "Juda yoqadi"});
                answers.push({"id": "2", "answer": "Yoqadi"});
                answers.push({"id": "3", "answer": "Yoqish/yoqmasligini bilmayman"});
                answers.push({"id": "4", "answer": "Yoqmaydi"});
                answers.push({"id": "5", "answer": "Umuman yoqmaydi"});
            }
        } else if (Number(selectedTest.id) === 2) {
            let items = questions[this.state.questionNumber].question.split('$');
            answers.push({"id": "1", "answer": items[0]});
            answers.push({"id": "2", "answer": items[1]});
        } else if (Number(selectedTest.id) === 3) {
            answers.push({"id": "1", 'answer': "Umuman qiziqarli emas"});
            answers.push({"id": "2", "answer": "Unchalik qiziqarli emas"});
            answers.push({"id": "3", "answer": "Qiziqarli"});
            answers.push({"id": "4", "answer": "Juda qiziqarli"});
        } else if (Number(selectedTest.id) === 5) {
            answers.push({"id": "1", 'answer': "+"});
            answers.push({"id": "2", "answer": "-"});
        } else if (Number(selectedTest.id) === 7) {
            answers.push({"id": "0", 'answer': "Bilmayman"});
            answers.push({"id": "1", "answer": "Yo`q yoki hech qachon"});
            answers.push({"id": "2", "answer": "Ba'zida"});
            answers.push({"id": "3", "answer": "Ko`pincha"});
            answers.push({"id": "4", "answer": "Deyarli doim"});
            answers.push({"id": "5", "answer": "Ha yoki har doim"});
        } else if (Number(selectedTest.id) === 8) {
            answers.push({"id": "0", 'answer': "kvadrat"});
            answers.push({"id": "1", "answer": "turtburchak"});
            answers.push({"id": "2", "answer": "uchburchak"});
            answers.push({"id": "3", "answer": "zigzag"});
            answers.push({"id": "4", "answer": "doira"});
        } else if (Number(selectedTest.id) === 9) {
            answers.push({"id": "0", 'answer': "0"});
            answers.push({"id": "1", "answer": "1"});
            answers.push({"id": "2", "answer": "2"});
        } else if (Number(selectedTest.id) === 11) {
            if (+language === 1) {
                answers.push(
	                [//1
		                {
			                "id": "0",
			                'answer': "а) Баъзан мен ўзгаларга бирон-бир тортишувли масалани ҳал этишда маъсулиятни ўз зиммасига олишига имкон бераман."
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен суҳбатдошим билан, биз нима масалада келиша олмаслигимизни муҳокама қилгандан кўра, иккаламиз ҳам келиша оладиган масалага эътиборни қаратишга ҳаракат қиламан."
		                }
	                ],
	                [//2
		                {"id": "0", 'answer': "а) Мен ўзаро келишув йўлини топишга ҳаракат қиламан."},
		                {
			                "id": "1",
			                "answer": "б) Мен низони бошқа одамнинг барча манфаатларини ва ўзимнинг шахсий манфаатларимни ҳам ҳисобга олган ҳолда бартараф этишга ҳаракат қиламан."
		                }
	                ],
	                [//3
		                {"id": "0", 'answer': "а) Мен одатда ўз манфаатларимга эришишга қатъий интиламан."},
		                {
			                "id": "1",
			                "answer": "б) Мен бошқа одамни тинчлантиришга ва ўзаро муносабатларимизни сақлаб қолишга ҳаракат қиламан."
		                }
	                ],
	                [//4
		                {"id": "0", 'answer': "а) Мен ўзаро келишилган қарорни топишга ҳаракат қиламан."},
		                {
			                "id": "1",
			                "answer": "б) Баъзан мен ўз манфаатларимни бошқа одамнинг қизиқишлари учун қурбон қиламан."
		                }
	                ],
	                [//5
		                {
			                "id": "0",
			                'answer': "а) Тортишувли вазиятни бартараф қилар эканман, мен ҳар доим бошқа бир одамдан мадад топишга интиламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен беҳуда зўриқишдан қутилиш учун ҳамма нарсани қилишга ҳаракат қиламан"
		                }
	                ],
	                [//6
		                {"id": "0", 'answer': "а) Мен ўзим учун ноҳушликдан қочишга ҳаракат қиламан"},
		                {"id": "1", "answer": "б) Мен ўз мақсадимга эришишга ҳаракат қиламан."}
	                ],
	                [//7
		                {
			                "id": "0",
			                'answer': "а) Тортишувли масалаларни кейинчалик (вақти келиб) Батафсил ҳал қилиш учун бошқа кунга қолдиришга ҳаракат қиламан."
		                },
		                {
			                "id": "1",
			                "answer": "б) Бирон нимага эришиш учун бошқа нимадандир воз кечиш мумкин деб ҳисоблайман"
		                }
	                ],
	                [//8
		                {"id": "0", 'answer': "а) Ўз мақсадимга эришиш учун мен одатда қатъий интиламан"},
		                {
			                "id": "1",
			                "answer": "б) Мен биринчи навбатда ҳамма кўриб чиқилаётган масалалар ва тортишувли масалалар нимадан иборат эканлигини аниқлаб олишга ҳаракат қиламан"
		                }
	                ],
	                [//9
		                {
			                "id": "0",
			                'answer': "а) Юзага келган қандайдир келишмовчиликлар туфайли ҳамма вақт ҳам безовта бўлишнинг ҳожати йўқ деб ўйлайман"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен ўз мақсадимга эришиш учун барча имкониятларимни (кучимни) жалб қиламан"
		                }
	                ],
	                [//10
		                {"id": "0", 'answer': "а) Мен кўзлаган нарсамга (мақсадимга) эришиш йўлида қатъий интиламан"},
		                {"id": "1", "answer": "б) Мен муросага олиб келадиган қарор топишга ҳаракат қиламан"}
	                ],
	                [//11
		                {
			                "id": "0",
			                'answer': "а) Мен биринчи навбатда барча кўриб чиқилаётган масалалар ва тортишувли масалалар нимадан иборат эканлигини аниқ белгилаб олишга ҳаракат қиламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен суҳбатдошимни тинчлантиришга ва шу билан бирга асосан муносабатларимизни сақлаб қолишга ҳаракат қиламан"
		                }
	                ],
	                [//12
		                {
			                "id": "0",
			                'answer': "а) Мен кўпинча, тортишувни юзага келтирадиган ҳолат (вазият, позиция)ни эгаллашдан ўзимни четга оламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Агар бирон-бир киши менга имконият бераётган бўлса, унга ҳам қайсидир масалада ўз фикрида қолиш имкониятини бераман."
		                }
	                ],
	                [//13
		                {"id": "0", 'answer': "а) Мен ўртача, оралиқ вазиятни (позицияни) таклиф қиламан."},
		                {"id": "1", "answer": "б) Мен ҳамма нарса мен хоҳлагандек қилинишини қатъий талаб қиламан."}
	                ],
	                [//14
		                {
			                "id": "0",
			                'answer': "а) Мен ўз нуқтаи-назаримни (фикримни) Бошқа бир одамга айтаман ва ундан унинг бу масалага қандай қарашлиги ҳақида сўрайман"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен бошқаларга ўз қарашларим мазмуни ва афзалликларини кўрсатишга ҳаракат қиламан"
		                }
	                ],
	                [//15
		                {
			                "id": "0",
			                'answer': "а) Мен бошқа бир одамни тинчлантиришга ва шу билан муносабатларимизни сақлаб қолишга ҳаракат қиламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Зўриқишдан қутилиш учун (унинг олдини олиш учун) зарур бўлган ҳамма нарсаларни қилишга ҳаракат қиламан"
		                }
	                ],
	                [//16
		                {
			                "id": "0",
			                'answer': "а) Мен бошқа одамнинг ҳиссиётларига тегиб кетмасликка (унинг ҳиссиётларини қўзғатиб юбормасликка) ҳаракат қиламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен одатда ўз позициямнинг афзаллигига бошқа одамни (суҳбатдошимни) Ишонтиришга ҳаракат қиламан"
		                }
	                ],
	                [//17
		                {"id": "0", 'answer': "а) Одатда мен, ўз мақсадимга эришишга қатъий интиламан"},
		                {
			                "id": "1",
			                "answer": "б) Мен беҳуда зўриқишларнинг олдини олиш учун ҳамма нарсани қилишга ҳаракат қиламан"
		                }
	                ],
	                [//18
		                {
			                "id": "0",
			                'answer': "а) Агар ўша одамни хурсанд қиладиган бўлса, мен унга ўз фикрида қолишга имконият яратиб бераман."
		                },
		                {
			                "id": "1",
			                "answer": "б) Агар бирон бир одам менинг фикримга ҳам пешвоз чиқса, мен унга ўз фикрида қолишга имкон бераман."
		                }
	                ],
	                [//19
		                {
			                "id": "0",
			                'answer': "а) Мен биринчи навбатда ўрганилаётган манфаатлар ва тортишувли масалалар нимадан иборат эканлигини аниқлаб олишга ҳаракат қиламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен тортишувли масалаларни кейинчалик вақти келганда батафсил ҳал этиш учун, бошқа кунга қолдиришга ҳаракат қиламан"
		                }
	                ],
	                [//20
		                {
			                "id": "0",
			                'answer': "а) Мен бизнинг келишмовчилигимизни зудлик билан енгиб ўтишга ҳаракат қиламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен тортишувли вазиятларда ҳар иккимиз учун ҳам бирдек фойда ва бой беришнинг энг мақбул нисбатини топишга ҳаракат қиламан"
		                }
	                ],
	                [//21
		                {
			                "id": "0",
			                'answer': "а) Бирон бир одам билан суҳбатлашаётган вақтимда, мен унга нисбатан эътиборлироқ бўлишга ҳаракат қиламан"
		                },
		                {"id": "1", "answer": "б) Мен доимо муаммони тўғридан-тўғри муҳокама қилишга мойилман"}
	                ],
	                [//22
		                {
			                "id": "0",
			                'answer': "а) Мен, ўзим ва бошқа бир одам икковимиз ўртамизда бўлган позицияни топишга ҳаракат қиламан"
		                },
		                {"id": "1", "answer": "б) Мен ўз позициямни ҳимоя қиламан"}
	                ],
	                [//23
		                {
			                "id": "0",
			                'answer': "а) Мен одатда ҳар биримизнинг хоҳишимиз қониқтирилиши учун қайғураман (ҳаракат қиламан)"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен баъзан бошқаларга тортишувли масалани ҳал этиш маъсулиятини ўзига олишига йўл қўяман"
		                }
	                ],
	                [//24
		                {
			                "id": "0",
			                'answer': "а) Агар суҳбатдошимнинг позицияси унинг ўзи учун жуда муҳим туюлса, унда мен унга имкон яратиб беришга ҳаракат қиламан"
		                },
		                {"id": "1", "answer": "б) Мен суҳбатдошимни ўзимнинг ҳақлигимга ишонтиришга ҳаракат қиламан"}
	                ],
	                [//25
		                {"id": "0", 'answer': "а) Мен суҳбатдошимни ўзимнинг ҳақлигимга ишонтиришга ҳаракат қиламан"},
		                {
			                "id": "1",
			                "answer": "б) Суҳбатдошим билан мулоқотда бўлар эканман, мен у келтираётган далилларга диққат-эътиборли бўлишга ҳаракат қиламан"
		                }
	                ],
	                [//26
		                {"id": "0", 'answer': "а) Мен одатда ўз позициямни таклиф қиламан"},
		                {
			                "id": "1",
			                "answer": "б) Суҳбатдошим билан мунозара олиб бораётган вақтларимда мен деярли ҳар доим, ҳар икковимизнинг ҳам манфаатларимиз қондирилишига ҳаракат қиламан"
		                }
	                ],
	                [//27
		                {"id": "0", 'answer': "а) Мен кўпинча тортишувлардан қочишга ҳаракат қиламан"},
		                {
			                "id": "1",
			                "answer": "б) Агар суҳбатдошимни шу нарса бахтиёр қиладиган бўлса, унда мен унга ўз фикрида қолишга имкон яратиб бераман"
		                }
	                ],
	                [//28
		                {"id": "0", 'answer': "а) Мен одатда ўз мақсадимга эришишга қатъий интиламан"},
		                {"id": "1", "answer": "б) Вазиятни енгиллаштирар эканман, мен одатда бошқалардан далда кутаман"}
	                ],
	                [//29
		                {
			                "id": "0",
			                'answer': "а) Мен суҳбатдошимиз икковимиз учун ҳам ўртача бўлган позицияни таклиф қиламан"
		                },
		                {
			                "id": "1",
			                "answer": "б) Мен юзага келиб қолган келишмовчиликлар учун ҳамма вақт ҳам безовта бўлаверишнинг ҳожати йўқ деб ўйлайман"
		                }
	                ],
	                [//30
		                {"id": "0", 'answer': "а) Мен суҳбатдошимнинг ҳиссиётига тегиб кетмасликка ҳаракат қиламан"},
		                {
			                "id": "1",
			                "answer": "б) Тортишувларда мен одатда доимо иккаламиз ҳам бирга муваффақиятга эришишимиз мумкин бўлган вазият (позиция)ни эгаллайман"
		                }
	                ]
                );
            } else {
                answers.push(
                    [//1
                        {
                            "id": "0",
                            'answer': "а) Иногда я предоставляю возможность другим взять на себя ответственность за решение спорного вопроса."
                        },
                        {
                            "id": "1",
                            "answer": "б) Чем обсуждать то, в чем мы расходимся, я стараюсь обратить внимание на то, в чем мы оба согласны."
                        }
                    ],
                    [//2
                        {"id": "0", 'answer': "а) Я стараюсь найти компромиссное решение."},
                        {
                            "id": "1",
                            "answer": "б) Я пытаюсь уладить конфликт с учетом всех интересов другого человека и моих собственных."
                        }
                    ],
                    [//3
                        {"id": "0", 'answer': "а) Обычно я настойчиво стремлюсь добиться своего."},
                        {
                            "id": "1",
                            "answer": "б) Иногда я жертвую своими собственными интересами ради интересов другого человека."
                        }
                    ],
                    [//4
                        {"id": "0", 'answer': "а) Я стараюсь найти компромиссное решение."},
                        {"id": "1", "answer": "б) Б) Я стараюсь не задеть чувств другого человека."}
                    ],
                    [//5
                        {
                            "id": "0",
                            'answer': "а) Улаживая спорную ситуацию, я все время пытаюсь найти поддержку у другого."
                        },
                        {"id": "1", "answer": "б) Я стараюсь делать все, чтобы избежать бесполезной напряженности."}
                    ],
                    [//6
                        {"id": "0", 'answer': "а) Я пытаюсь избежать неприятности для себя."},
                        {"id": "1", "answer": "б) Я стараюсь добиться своего."}
                    ],
                    [//7
                        {
                            "id": "0",
                            'answer': "а) Я стараюсь отложить решение спорного вопроса, с тем чтобы со временем решить его окончательно."
                        },
                        {"id": "1", "answer": "б) Я считаю возможным в чем-то уступить, чтобы добиться другого."}
                    ],
                    [//8
                        {"id": "0", 'answer': "а) Обычно я настойчиво стремлюсь добиться своего."},
                        {
                            "id": "1",
                            "answer": "б) Я первым делом стараюсь определить то, в чем состоят все затронутые интересы и спорные вопросы."
                        }
                    ],
                    [//9
                        {
                            "id": "0",
                            'answer': "а) Думаю, что не всегда стоит волноваться из-за каких-то возникших разногласий."
                        },
                        {"id": "1", "answer": "б) Я предпринимаю усилия, чтобы добиться своего."}
                    ],
                    [//10
                        {"id": "0", 'answer': "а) Я твердо стремлюсь добиться своего."},
                        {"id": "1", "answer": "б) Я пытаюсь найти компромиссное решение."}
                    ],
                    [//11
                        {
                            "id": "0",
                            'answer': "а) Первым делом я стремлюсь ясно определить то, в чем состоят все затронутые интересы и спорные вопросы."
                        },
                        {
                            "id": "1",
                            "answer": "б) Я стараюсь успокоить другого и главным образом сохранить наши отношения."
                        }
                    ],
                    [//12
                        {"id": "0", 'answer': "а) Зачастую я избегаю занимать позицию, которая может вызвать споры."},
                        {
                            "id": "1",
                            "answer": "б) Я даю возможность другому в чем-то остаться при своем мнении, если он также идет навстречу."
                        }
                    ],
                    [//13
                        {"id": "0", 'answer': "а) Я предлагаю среднюю позицию."},
                        {"id": "1", "answer": "б) Я настаиваю, чтобы все было сделано по-моему."}
                    ],
                    [//14
                        {"id": "0", 'answer': "а) Я сообщаю другому свою точку зрения и спрашиваю о его взглядах."},
                        {"id": "1", "answer": "б) Я пытаюсь показать другому логику и преимущество моих взглядов."}
                    ],
                    [//15
                        {"id": "0", 'answer': "а) Я стараюсь успокоить другого и сохранить наши отношения."},
                        {"id": "1", "answer": "б) Я стараюсь сделать все необходимое, чтобы избежать напряжения."}
                    ],
                    [//16
                        {"id": "0", 'answer': "а) Я стараюсь не задеть чувств другого."},
                        {"id": "1", "answer": "б) Я обычно пытаюсь убедить другого в преимуществах моей позиции."}
                    ],
                    [//17
                        {"id": "0", 'answer': "а) Обычно я настойчиво стремлюсь добиться своего."},
                        {"id": "1", "answer": "б) Я стараюсь сделать все, чтобы избежать бесполезной напряженности."}
                    ],
                    [//18
                        {
                            "id": "0",
                            'answer': "а) Если это сделает другого счастливым, я дам ему возможность настоять на своем."
                        },
                        {
                            "id": "1",
                            "answer": "б) Я даю другому возможность остаться при своем мнении, если он идет мне навстречу."
                        }
                    ],
                    [//19
                        {
                            "id": "0",
                            'answer': "а) Первым делом я пытаюсь определить то, в чем состоят все затронутые интересы и спорные вопросы."
                        },
                        {
                            "id": "1",
                            "answer": "б) Я стараюсь отложить спорные вопросы с тем, чтобы со временем решить их окончательно."
                        }
                    ],
                    [//20
                        {"id": "0", 'answer': "а) Я пытаюсь немедленно преодолеть наши разногласия."},
                        {"id": "1", "answer": "б) Я стараюсь найти наилучшее сочетание выгод и потерь для нас обоих."}
                    ],
                    [//21
                        {"id": "0", 'answer': "а) Ведя переговоры, стараюсь быть внимательным к другому."},
                        {"id": "1", "answer": "б) Я всегда склоняюсь к прямому обсуждению проблемы."}
                    ],
                    [//22
                        {
                            "id": "0",
                            'answer': "а) Я пытаюсь найти позицию, которая находится посередине между моей и другого человека."
                        },
                        {"id": "1", "answer": "б) Я отстаиваю свою позицию."}
                    ],
                    [//23
                        {
                            "id": "0",
                            'answer': "а) Как правило, я озабочен тем, чтобы удовлетворить желания каждого из нас."
                        },
                        {
                            "id": "1",
                            "answer": "б) Иногда предоставляю другим взять на себя ответственность за решение спорного вопроса."
                        }
                    ],
                    [//24
                        {
                            "id": "0",
                            'answer': "а) Если позиция другого кажется ему очень важной, я стараюсь идти ему навстречу."
                        },
                        {"id": "1", "answer": "б) Я стараюсь склонить другого на компромисс."}
                    ],
                    [//25
                        {"id": "0", 'answer': "а) Я пытаюсь убедить другого в своей правоте."},
                        {"id": "1", "answer": "б) Ведя переговоры, я стараюсь быть внимательным к аргументам другого."}
                    ],
                    [//26
                        {"id": "0", 'answer': "а) Я обычно предлагаю среднюю позицию."},
                        {"id": "1", "answer": "б) Я почти всегда стремлюсь удовлетворить интересы каждого из нас."}
                    ],
                    [//27
                        {"id": "0", 'answer': "а) Зачастую стремлюсь избежать споров."},
                        {
                            "id": "1",
                            "answer": "б) Если это сделает другого человека счастливым, я дам ему возможность настоять на своем."
                        }
                    ],
                    [//28
                        {"id": "0", 'answer': "а) Обычно я настойчиво стремлюсь добиться своего."},
                        {"id": "1", "answer": "б) Улаживая ситуацию, я обычно стремлюсь найти поддержку у другого."}
                    ],
                    [//29
                        {"id": "0", 'answer': "а) Я предлагаю среднюю позицию."},
                        {
                            "id": "1",
                            "answer": "б) Думаю, что не всегда стоит волноваться из-за возникающих разногласий."
                        }
                    ],
                    [//30
                        {"id": "0", 'answer': "а) Я стараюсь не задеть чувств другого."},
                        {
                            "id": "1",
                            "answer": "б) Я всегда занимаю такую позицию в споре, чтобы мы совместно могли добиться успеха."
                        }
                    ]
                );
            }

        } else if (Number(selectedTest.id) === 12) {
            answers.push({"id": "0", 'answer': "hech qachon"});
            answers.push({"id": "1", "answer": "juda kamdan-kam"});
            answers.push({"id": "2", "answer": "juda kam"});
            answers.push({"id": "3", "answer": "kamdan-kam hollarda"});
            answers.push({"id": "4", "answer": "ba'zan"});
            answers.push({"id": "5", "answer": "ko`pincha"});
            answers.push({"id": "6", "answer": "juda tez-tez"});
            answers.push({"id": "7", "answer": "doimiy"});
        } else if (Number(selectedTest.id) === 13) {
            if (+language === 1) {
                answers.push({"id": "0", "ball": "0", 'answer': "Hech qachon"});
                answers.push({"id": "1", "ball": "1", "answer": "Juda kamdan-kam"});
                answers.push({"id": "2", "ball": "2", "answer": "Kam hollarda"});
                answers.push({"id": "3", "ball": "3", "answer": "Ba'zan"});
                answers.push({"id": "4", "ball": "4", "answer": "Tez-tez"});
                answers.push({"id": "5", "ball": "5", "answer": "Juda ko`p hollarda"});
                answers.push({"id": "6", "ball": "6", "answer": "Har kuni"});
            } else {
                answers.push({"id": "0", "ball": "0", 'answer': "Никогда"});
                answers.push({"id": "1", "ball": "1", "answer": "Очень редко"});
                answers.push({"id": "2", "ball": "2", "answer": "Редко"});
                answers.push({"id": "3", "ball": "3", "answer": "Иногда"});
                answers.push({"id": "4", "ball": "4", "answer": "Часто"});
                answers.push({"id": "5", "ball": "5", "answer": "Очень часто"});
                answers.push({"id": "6", "ball": "6", "answer": "Ежедневно"});
            }
        } else if (Number(selectedTest.id) === 14) {
            if (language === '2') {
                answers.push({"id": "1", 'answer': "Да"});
                answers.push({"id": "2", "answer": "Нет"});
            } else {
                answers.push({"id": "1", 'answer': "Ha"});
                answers.push({"id": "2", "answer": "Yo`q"});
            }
        } else if (Number(selectedTest.id) === 16) {
            answers = localStorage.getItem('answers');
        } else if (Number(selectedTest.id) === 10 || Number(selectedTest.id) === 19 || Number(selectedTest.id) === 20
            || Number(selectedTest.id) === 21 || Number(selectedTest.id) === 22
            || Number(selectedTest.id) === 23 || Number(selectedTest.id) === 24 || Number(selectedTest.id) === 25 || Number(selectedTest.id) === 32
	        || Number(selectedTest.id) === 39 || Number(selectedTest.id) === 43 || Number(selectedTest.id) === 44 || Number(selectedTest.id) === 45) {
            for (let i = 0; i < questions.length; i++) {
                answers.push(questions[i].answers);
            }
        }
        let answers1 = selectedTest !== undefined && selectedTest !== null && (+selectedTest.id === 10 || +selectedTest.id === 11 || +selectedTest.id === 16 || +selectedTest.id === 19
            || +selectedTest.id === 20 || +selectedTest.id === 21 || +selectedTest.id === 22 || +selectedTest.id === 23 || +selectedTest.id === 24 || +selectedTest.id === 25
	        || +selectedTest.id === 39 || +selectedTest.id === 43 || +selectedTest.id === 44 || +selectedTest.id === 45) ? answers[0] : answers;
        this.setState({
            fullAnswered: false,
            language: language,
            selectedTest: selectedTest,
            pupil: selectedPupil,
            questions: questions,
            question: selectedTest !== null && selectedTest.id !== null && +selectedTest.id === 11 ? questions[0] : questions[0].question,
            questionCount: questions.length,
            questionNumber: 0,
            pupilsAnswers: pupilsAnswers,
            tendencies: tendencies,
            answers: answers,
            answers1: answers1
        });
    }

    toNextQuestion(pupilsAnswer) {
        let q_number = this.state.questionNumber < this.state.questions.length - 1 ? this.state.questionNumber + 1 : 0;
        let pupilsAnswers = [...this.state.pupilsAnswers];
        if (+this.state.selectedTest.id === 16) {
            let qa = this.state.answers[this.state.questionNumber];
            for (let i = 0; i < qa.length; i++) {
                if (qa[i].id === pupilsAnswer) {
                    pupilsAnswers[this.state.questionNumber] = qa[i].ball;
                }
            }
        } else {
            pupilsAnswers[this.state.questionNumber] = pupilsAnswer;
        }

        let question = +this.state.selectedTest.id === 11 ? this.state.questions[q_number] : this.state.questions[q_number].question;

        let unAnswered = 0;
        for (let i = 0; i < pupilsAnswers.length; i++) {
            if (pupilsAnswers[i] === '') {
                unAnswered = unAnswered + 1;
            }
        }
        this.setState({
            pupilsAnswers: pupilsAnswers,
            questionNumber: q_number,
            question: question,
            fullAnswered: unAnswered === 0
        });
        if (Number(this.state.selectedTest.id) === 2) {
            let answers = [];
            let items = question.split('$');
            answers.push({"id": "1", "answer": items[0]});
            answers.push({"id": "2", "answer": items[1]});
            this.setState({
                answers1: answers
            });
        } else if (Number(this.state.selectedTest.id) === 13) {
            this.setState({
                answers1: this.state.answers
            });
        } else if (Number(this.state.selectedTest.id) === 10 || Number(this.state.selectedTest.id) === 11 ||
                    Number(this.state.selectedTest.id) === 16 || Number(this.state.selectedTest.id) === 19 || Number(this.state.selectedTest.id) === 20
            || Number(this.state.selectedTest.id) === 21 || Number(this.state.selectedTest.id) === 22 || Number(this.state.selectedTest.id) === 23
            || Number(this.state.selectedTest.id) === 24 || Number(this.state.selectedTest.id) === 25 || Number(this.state.selectedTest.id) === 39
	        || Number(this.state.selectedTest.id) === 43 || Number(this.state.selectedTest.id) === 44 || Number(this.state.selectedTest.id) === 45) {
            let answers1 = this.state.answers[q_number];
            this.setState({
                answers1: answers1
            });
        }
    }

    onFinishClick(e) {
        let unAnswered = [];
        let tensSum = new Array(14).fill(0);
        if (Number(this.state.selectedTest.id) !== 8) {
            for (let i = 0; i < this.state.questionCount; i++) {
                if (this.state.pupilsAnswers[i] === undefined || this.state.pupilsAnswers[i] === null || this.state.pupilsAnswers[i] === '') {
                    unAnswered.push(i + 1);
                }
            }
        } else {
            let figures = document.getElementById('figures');
            if (figures.childNodes.length < 5) {
                this.state.language === '1' ? Swal.fire('Hamma figuralarni tanlang', '', "warning") : Swal.fire('Выберите все фигуры', '', "warning");
            }
        }
        let tendencyName = '';
        if (unAnswered.length > 0) {
            Swal.fire('Siz quyidagi savollarga javob bermadingiz: ' + unAnswered, '', "warning");
        } else {
            if (Number(this.state.selectedTest.id) === 1) {
                document.getElementById('testFinishBtn').disabled = true;
                tensSum[0] = this.getBall(this.state.pupilsAnswers[0]) + this.getBall(this.state.pupilsAnswers[10]) + this.getBall(this.state.pupilsAnswers[20]) + this.getBall(this.state.pupilsAnswers[30]) + this.getBall(this.state.pupilsAnswers[40]);
                tensSum[1] = this.getBall(this.state.pupilsAnswers[1]) + this.getBall(this.state.pupilsAnswers[11]) + this.getBall(this.state.pupilsAnswers[21]) + this.getBall(this.state.pupilsAnswers[31]) + this.getBall(this.state.pupilsAnswers[41]);
                tensSum[2] = this.getBall(this.state.pupilsAnswers[2]) + this.getBall(this.state.pupilsAnswers[12]) + this.getBall(this.state.pupilsAnswers[22]) + this.getBall(this.state.pupilsAnswers[32]) + this.getBall(this.state.pupilsAnswers[42]);
                tensSum[3] = this.getBall(this.state.pupilsAnswers[3]) + this.getBall(this.state.pupilsAnswers[13]) + this.getBall(this.state.pupilsAnswers[23]) + this.getBall(this.state.pupilsAnswers[33]) + this.getBall(this.state.pupilsAnswers[43]);
                tensSum[4] = this.getBall(this.state.pupilsAnswers[4]) + this.getBall(this.state.pupilsAnswers[14]) + this.getBall(this.state.pupilsAnswers[24]) + this.getBall(this.state.pupilsAnswers[34]) + this.getBall(this.state.pupilsAnswers[44]);
                tensSum[5] = this.getBall(this.state.pupilsAnswers[5]) + this.getBall(this.state.pupilsAnswers[15]) + this.getBall(this.state.pupilsAnswers[25]) + this.getBall(this.state.pupilsAnswers[35]) + this.getBall(this.state.pupilsAnswers[45]);
                tensSum[6] = this.getBall(this.state.pupilsAnswers[6]) + this.getBall(this.state.pupilsAnswers[16]) + this.getBall(this.state.pupilsAnswers[26]) + this.getBall(this.state.pupilsAnswers[36]) + this.getBall(this.state.pupilsAnswers[46]);
                tensSum[7] = this.getBall(this.state.pupilsAnswers[7]) + this.getBall(this.state.pupilsAnswers[17]) + this.getBall(this.state.pupilsAnswers[27]) + this.getBall(this.state.pupilsAnswers[37]) + this.getBall(this.state.pupilsAnswers[47]);
                tensSum[8] = this.getBall(this.state.pupilsAnswers[8]) + this.getBall(this.state.pupilsAnswers[18]) + this.getBall(this.state.pupilsAnswers[28]) + this.getBall(this.state.pupilsAnswers[38]) + this.getBall(this.state.pupilsAnswers[48]);
                tensSum[9] = this.getBall(this.state.pupilsAnswers[9]) + this.getBall(this.state.pupilsAnswers[19]) + this.getBall(this.state.pupilsAnswers[29]) + this.getBall(this.state.pupilsAnswers[39]) + this.getBall(this.state.pupilsAnswers[49]);
                let max = tensSum[0];
                tendencyName = this.state.tendencies[0];
                for (let i = 0; i < 10; i++) {
                    if (max < tensSum[i]) {
                        max = tensSum[i];
                        tendencyName = this.state.tendencies[i];
                    }
                }
                let klass = localStorage.getItem('klass');
                localStorage.setItem('fullAnswer', localStorage.getItem('fullAnswer') + '_____' + tendencyName);
                if (klass === '7' || klass === '11') {
                    localStorage.setItem('klass', klass);
                    localStorage.setItem('selectedTest', {
                        "id": 14,
                        "name": "Intellektual qobiliyatni aniqlash"
                    });
                    axios.get(_.apiHost + '/test/list?methodId=14&language=' + this.state.language)
                        .then(testApiResult => {
                            if (+testApiResult.data.statusCode === 200) {
                                document.getElementById('testFinishBtn').disabled = false;
                                localStorage.setItem('selectedTestQuestions', JSON.stringify(testApiResult.data.data));
                                this.init();
                            }
                        });
                } else {
                    localStorage.setItem('selectedTest', {
                        "id": 16,
                        "name": "O`quvchini maktabga motivatsiyasini aniqlash"
                    });
                    axios.get(_.apiHost + '/test/list?methodId=16&language=' + this.state.language)
                        .then(testApiResult => {
                            if (+testApiResult.data.statusCode === 200) {
                                localStorage.setItem('selectedTestQuestions', JSON.stringify(testApiResult.data.data));
                                axios.get(_.apiHost + '/test/answerList?methodId=16&language=' + this.state.language)
                                    .then(result => {
                                        if (+result.data.statusCode === 200) {
                                            document.getElementById('testFinishBtn').disabled = false;
                                            localStorage.setItem('klass', 9);
                                            localStorage.setItem('answers', result.data.data);
                                            this.init();
                                        }
                                    });
                            }
                        });
                }
            } else if (Number(this.state.selectedTest.id) === 2) {
                document.getElementById('testFinishBtn').disabled = true;
                let tens = new Array(5).fill(0);

                if (this.state.pupilsAnswers[0] === '1') tens[1] = tens[1] + 1; else if (this.state.pupilsAnswers[0] === '2') tens[0] = tens[0] + 1;
                if (this.state.pupilsAnswers[1] === '1') tens[3] = tens[3] + 1; else if (this.state.pupilsAnswers[1] === '2') tens[2] = tens[2] + 1;
                if (this.state.pupilsAnswers[2] === '1') tens[0] = tens[0] + 1; else if (this.state.pupilsAnswers[2] === '2') tens[4] = tens[4] + 1;
                if (this.state.pupilsAnswers[3] === '1') tens[2] = tens[2] + 1; else if (this.state.pupilsAnswers[3] === '2') tens[1] = tens[1] + 1;
                if (this.state.pupilsAnswers[4] === '1') tens[4] = tens[4] + 1; else if (this.state.pupilsAnswers[4] === '2') tens[3] = tens[3] + 1;
                if (this.state.pupilsAnswers[5] === '1') tens[2] = tens[2] + 1; else if (this.state.pupilsAnswers[5] === '2') tens[0] = tens[0] + 1;
                if (this.state.pupilsAnswers[6] === '1') tens[1] = tens[1] + 1; else if (this.state.pupilsAnswers[6] === '2') tens[4] = tens[4] + 1;
                if (this.state.pupilsAnswers[7] === '1') tens[4] = tens[4] + 1; else if (this.state.pupilsAnswers[7] === '2') tens[2] = tens[2] + 1;
                if (this.state.pupilsAnswers[8] === '1') tens[3] = tens[3] + 1; else if (this.state.pupilsAnswers[8] === '2') tens[1] = tens[1] + 1;
                if (this.state.pupilsAnswers[9] === '1') tens[3] = tens[3] + 1; else if (this.state.pupilsAnswers[9] === '2') tens[0] = tens[0] + 1;
                if (this.state.pupilsAnswers[10] === '1') tens[1] = tens[1] + 1; else if (this.state.pupilsAnswers[10] === '2') tens[0] = tens[0] + 1;
                if (this.state.pupilsAnswers[11] === '1') tens[3] = tens[3] + 1; else if (this.state.pupilsAnswers[11] === '2') tens[2] = tens[2] + 1;
                if (this.state.pupilsAnswers[12] === '1') tens[0] = tens[0] + 1; else if (this.state.pupilsAnswers[12] === '2') tens[4] = tens[4] + 1;
                if (this.state.pupilsAnswers[13] === '1') tens[2] = tens[2] + 1; else if (this.state.pupilsAnswers[13] === '2') tens[1] = tens[1] + 1;
                if (this.state.pupilsAnswers[14] === '1') tens[4] = tens[4] + 1; else if (this.state.pupilsAnswers[14] === '2') tens[3] = tens[3] + 1;
                if (this.state.pupilsAnswers[15] === '1') tens[2] = tens[2] + 1; else if (this.state.pupilsAnswers[15] === '2') tens[0] = tens[0] + 1;
                if (this.state.pupilsAnswers[16] === '1') tens[1] = tens[1] + 1; else if (this.state.pupilsAnswers[16] === '2') tens[4] = tens[4] + 1;
                if (this.state.pupilsAnswers[17] === '1') tens[4] = tens[4] + 1; else if (this.state.pupilsAnswers[17] === '2') tens[2] = tens[2] + 1;
                if (this.state.pupilsAnswers[18] === '1') tens[3] = tens[3] + 1; else if (this.state.pupilsAnswers[18] === '2') tens[1] = tens[1] + 1;
                if (this.state.pupilsAnswers[19] === '1') tens[3] = tens[3] + 1; else if (this.state.pupilsAnswers[19] === '2') tens[0] = tens[0] + 1;

                let max = -1;
                let j = 0;
                for (j = 0; j < 5; j++) {
                    if (Number(max) < Number(tens[j])) {
                        max = Number(tens[j]);
                        tendencyName = this.state.tendencies[j];
                    }
                }

                localStorage.setItem('fullAnswer', tendencyName);
                localStorage.setItem('selectedTest', {
                    "id": 1,
                    "name": "Qiziqishlar xaritasi"
                });
                axios.get(_.apiHost + '/test/list?methodId=1&language=' + this.state.language)
                    .then(testApiResult => {
                        if (+testApiResult.data.statusCode === 200) {
                            document.getElementById('testFinishBtn').disabled = false;
                            localStorage.setItem('selectedTestQuestions', testApiResult.data.data);
                            this.init();
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 3) {
                let tens = new Array(11).fill(0);

                tens[0] = Number(this.state.pupilsAnswers[0]) + Number(this.state.pupilsAnswers[11]) + Number(this.state.pupilsAnswers[22]) + Number(this.state.pupilsAnswers[33]) + Number(this.state.pupilsAnswers[44]) + Number(this.state.pupilsAnswers[55]) + Number(this.state.pupilsAnswers[66]) + Number(this.state.pupilsAnswers[77]);
                tens[1] = Number(this.state.pupilsAnswers[1]) + Number(this.state.pupilsAnswers[12]) + Number(this.state.pupilsAnswers[23]) + Number(this.state.pupilsAnswers[34]) + Number(this.state.pupilsAnswers[45]) + Number(this.state.pupilsAnswers[56]) + Number(this.state.pupilsAnswers[67]) + Number(this.state.pupilsAnswers[78]);
                tens[2] = Number(this.state.pupilsAnswers[2]) + Number(this.state.pupilsAnswers[13]) + Number(this.state.pupilsAnswers[24]) + Number(this.state.pupilsAnswers[35]) + Number(this.state.pupilsAnswers[46]) + Number(this.state.pupilsAnswers[57]) + Number(this.state.pupilsAnswers[68]) + Number(this.state.pupilsAnswers[79]);
                tens[3] = Number(this.state.pupilsAnswers[3]) + Number(this.state.pupilsAnswers[14]) + Number(this.state.pupilsAnswers[25]) + Number(this.state.pupilsAnswers[36]) + Number(this.state.pupilsAnswers[47]) + Number(this.state.pupilsAnswers[58]) + Number(this.state.pupilsAnswers[69]) + Number(this.state.pupilsAnswers[80]);
                tens[4] = Number(this.state.pupilsAnswers[4]) + Number(this.state.pupilsAnswers[15]) + Number(this.state.pupilsAnswers[26]) + Number(this.state.pupilsAnswers[37]) + Number(this.state.pupilsAnswers[48]) + Number(this.state.pupilsAnswers[59]) + Number(this.state.pupilsAnswers[70]) + Number(this.state.pupilsAnswers[81]);
                tens[5] = Number(this.state.pupilsAnswers[5]) + Number(this.state.pupilsAnswers[16]) + Number(this.state.pupilsAnswers[27]) + Number(this.state.pupilsAnswers[38]) + Number(this.state.pupilsAnswers[49]) + Number(this.state.pupilsAnswers[60]) + Number(this.state.pupilsAnswers[71]) + Number(this.state.pupilsAnswers[82]);
                tens[6] = Number(this.state.pupilsAnswers[6]) + Number(this.state.pupilsAnswers[17]) + Number(this.state.pupilsAnswers[28]) + Number(this.state.pupilsAnswers[39]) + Number(this.state.pupilsAnswers[50]) + Number(this.state.pupilsAnswers[61]) + Number(this.state.pupilsAnswers[72]) + Number(this.state.pupilsAnswers[83]);
                tens[7] = Number(this.state.pupilsAnswers[7]) + Number(this.state.pupilsAnswers[18]) + Number(this.state.pupilsAnswers[29]) + Number(this.state.pupilsAnswers[40]) + Number(this.state.pupilsAnswers[51]) + Number(this.state.pupilsAnswers[62]) + Number(this.state.pupilsAnswers[73]) + Number(this.state.pupilsAnswers[84]);
                tens[8] = Number(this.state.pupilsAnswers[8]) + Number(this.state.pupilsAnswers[19]) + Number(this.state.pupilsAnswers[30]) + Number(this.state.pupilsAnswers[41]) + Number(this.state.pupilsAnswers[52]) + Number(this.state.pupilsAnswers[63]) + Number(this.state.pupilsAnswers[74]) + Number(this.state.pupilsAnswers[85]);
                tens[9] = Number(this.state.pupilsAnswers[9]) + Number(this.state.pupilsAnswers[20]) + Number(this.state.pupilsAnswers[31]) + Number(this.state.pupilsAnswers[42]) + Number(this.state.pupilsAnswers[53]) + Number(this.state.pupilsAnswers[64]) + Number(this.state.pupilsAnswers[75]) + Number(this.state.pupilsAnswers[86]);
                tens[10] = Number(this.state.pupilsAnswers[10]) + Number(this.state.pupilsAnswers[21]) + Number(this.state.pupilsAnswers[32]) + Number(this.state.pupilsAnswers[43]) + Number(this.state.pupilsAnswers[54]) + Number(this.state.pupilsAnswers[65]) + Number(this.state.pupilsAnswers[76]) + Number(this.state.pupilsAnswers[87]);

                let max = -1;
                let j = 0;
                for (j = 0; j < 11; j++) {
                    if (Number(max) < Number(tens[j])) {
                        max = Number(tens[j]);
                        tendencyName = this.state.tendencies[j];
                    }
                }
                Swal.fire('Test natijalari sizda ' + tendencyName + ' yo`nalishiga qiziqish yuqoriligini ko`rsatmoqda', '', "success");
            } else if (Number(this.state.selectedTest.id) === 7) {
                let tens = new Array(6).fill(0);
                tens[0] = Number(this.state.pupilsAnswers[9]) + Number(this.state.pupilsAnswers[12]) + Number(this.state.pupilsAnswers[16]);
                tens[1] = Number(this.state.pupilsAnswers[18]) + Number(this.state.pupilsAnswers[21]) + Number(this.state.pupilsAnswers[24]);
                tens[2] = Number(this.state.pupilsAnswers[1]) + Number(this.state.pupilsAnswers[4]) + Number(this.state.pupilsAnswers[7]);
                tens[3] = Number(this.state.pupilsAnswers[25]) + Number(this.state.pupilsAnswers[28]) + Number(this.state.pupilsAnswers[34]);
                tens[4] = Number(this.state.pupilsAnswers[8]) + Number(this.state.pupilsAnswers[11]) + Number(this.state.pupilsAnswers[14]);
                tens[5] = Number(this.state.pupilsAnswers[20]) + Number(this.state.pupilsAnswers[23]) + Number(this.state.pupilsAnswers[26]);

                let max = -1;//, tensNumber = -1;
                let j = 0;
                for (j = 0; j < 6; j++) {
                    if (Number(max) < Number(tens[j])) {
                        max = Number(tens[j]);
                        //tensNumber = j;
                        tendencyName = this.state.tendencies[j];
                    }
                }
                let level = '';
                if (max === 15) {
                    level = 'juda yuqori';
                } else if (max >= 13 && max <= 14) {
                    level = 'yuqori';
                } else if (max >= 5 && max <= 12) {
                    level = 'o`rta';
                } else if (max >= 2 && max <= 4) {
                    level = 'past';
                } else if (max >= 0 && max <= 1) {
                    level = 'juda past';
                }
                Swal.fire('Test natijalari sizda ' + tendencyName + ' nisbatan ' + level + ' darajada empatiya mavjudligini ko`rsatmoqda', '', "success");
                tendencyName = tendencyName + ' nisbatan ' + level + ' darajada empatiya';
            } else if (Number(this.state.selectedTest.id) === 8) {
                let resultStr = '';
                let figures = document.getElementById('figures');
                if (figures.hasChildNodes()) {
                    for (let i = 0; i < figures.childNodes.length; i++) {
                        let el = figures.childNodes[i].id;
                        tendencyName = tendencyName + this.getFigureDescription(el);
                        resultStr = resultStr.concat(el);
                        if (i < 4) {
                            resultStr = resultStr.concat('_');
                        }
                    }
                }

                Swal.fire({
                    title: this.state.language === '1' ? 'Test natijasi' : 'Результаты теста',
                    text: tendencyName,
                    type: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.value) {
                        axios.get(_.apiHost + '/test/saveResult?pupilId=' + this.state.pupil.id + '&testMethodId=' + this.state.selectedTest.id + '&testResult=' + resultStr)
                            .then(result => {
                                console.log(result.data.message);
                            });
                        localStorage.removeItem('pupil');
                        localStorage.removeItem('selectedTest');
                        this.setState = {
                            question: '',
                            questions: [],
                            questionCount: 0,
                            questionNumber: 0,
                            pupilsAnswers: [],
                            selectedTest: {},
                            pupil: {}
                        };
                        window.open('/', '_self');
                    }
                })
            } else if (Number(this.state.selectedTest.id) === 9) {
                let tens = new Array(4).fill(0);
                for (let i = 0; i < 10; i++) {
                    tens[0] = tens[0] + this.state.pupilsAnswers[i];
                }
                for (let i = 10; i < 20; i++) {
                    tens[1] = tens[1] + this.state.pupilsAnswers[i];
                }
                for (let i = 20; i < 30; i++) {
                    tens[2] = tens[2] + this.state.pupilsAnswers[i];
                }
                for (let i = 30; i < 40; i++) {
                    tens[3] = tens[3] + this.state.pupilsAnswers[i];
                }
                let block1 = '', block2 = '', block3 = '', block4 = '';

                //Block 1
                if (tens[0] >= 0 && tens[0] <= 7) {
                    block1 = 'Xavotirlanish mavjud emas';
                } else if (tens[0] >= 8 && tens[0] <= 14) {
                    block1 = 'Xavotirlanish o`rta, me`yor darajasida';
                } else if (tens[0] >= 15 && tens[0] <= 20) {
                    block1 = 'Yuqori xavotirlanish';
                }

                //Block 2
                if (tens[1] >= 0 && tens[1] <= 7) {
                    block2 = 'O`zingingizni baholashingiz yuqori, muvaffaqiyatsizliklarga mustahkamsiz, qiyinchiliklardan qo`rqmaysiz';
                } else if (tens[1] >= 8 && tens[1] <= 14) {
                    block2 = 'O`rta daraja, frustratsiya xolatlari bor';
                } else if (tens[1] >= 15 && tens[1] <= 20) {
                    block2 = 'Sizning o`zingizga baho berishingiz past, qiyinchiliklardan qochasiz, muvaffaqiyatsizliklardan qo`rqasiz, frustratsiya holatidasiz';
                }

                //Block 3
                if (tens[2] >= 0 && tens[2] <= 7) {
                    block3 = 'Siz xotirjamsiz, vazminsiz';
                } else if (tens[2] >= 8 && tens[2] <= 14) {
                    block3 = 'Agressivlikning o`rta darajasi';
                } else if (tens[2] >= 15 && tens[2] <= 20) {
                    block3 = 'Siz agressivsiz, o`zingizni tuta bilmaysiz, odamlar bilan muloqotda va ishlashda qiyinchiliklar mavjud';
                }

                //Block 4
                if (tens[3] >= 0 && tens[3] <= 7) {
                    block4 = 'Rigidlik yo`q, boshqa faoliyatga yengil o`tish';
                } else if (tens[3] >= 8 && tens[3] <= 14) {
                    block4 = 'O`rta darajada';
                } else if (tens[3] >= 15 && tens[3] <= 20) {
                    block4 = 'Yuqori namoyon bo`lgan rigidlik, xulq-atvor';
                }

                Swal.fire({
                    title: this.state.language === '1' ? 'Test natijasi' : 'Результаты теста',
                    text: this.state.language === '1' ? 'Test natijalari sizda: </br>' + block1 + '</br>' + block2 + '</br>' + block3 + '</br>' + block4 :
                        'Результаты теста показывает что, у вас: </br>' + block1 + '</br>' + block2 + '</br>' + block3 + '</br>' + block4,
                    type: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.value) {
                        tendencyName = block1 + ', ' + block2 + ', ' + block3 + ', ' + block4;
                        axios.get(_.apiHost + '/test/saveResult?pupilId=' + this.state.pupil.id + '&testMethodId=' + this.state.selectedTest.id + '&testResult=' + tendencyName)
                            .then(result => {
                                console.log(result.data.message);
                            });
                        localStorage.removeItem('pupil');
                        localStorage.removeItem('selectedTest');
                        this.setState = {
                            question: '',
                            questions: [],
                            questionCount: 0,
                            questionNumber: 0,
                            pupilsAnswers: [],
                            selectedTest: {},
                            pupil: {}
                        };
                    }
                })
            } else if (Number(this.state.selectedTest.id) === 10) {
                let result = this.state.pupilsAnswers.join('_');
                axios.post(_.apiHost + '/test/saveTeacherResult2?methodId=' + this.state.selectedTest.id + '&teacherId=' + this.state.pupil.id + '&language=' + this.state.language + '&testResult=' + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            localStorage.setItem('selectedTest', JSON.stringify({
                                "id": 11,
                                "name": this.state.language === '1' ?"Shaxslararo munosabatlarni aniqlash metodikasi" : "Метод определения межличностных отношений"
                            }));
                            Swal.fire({
                                title: strings.testResult,
                                text: result.data.data.data.name,
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            }).then((result1) => {
                                axios.get(_.apiHost + '/test/list?methodId=11&language=' + this.state.language)
                                    .then(testApiResult => {
                                        if (+testApiResult.data.statusCode === 200) {
                                            localStorage.setItem('selectedTestQuestions', JSON.stringify(testApiResult.data.data));
                                            this.NavigateToTesting();
                                        }
                                    });
                            });
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 11) {
                let tens = new Array(5).fill(0);
                for (let i = 0; i < this.state.pupilsAnswers.length; i++) {
                    //Соперничество
                    if (i === 2 || i === 7 || i === 9 || i === 16 || i === 24 || i === 27) {
                        if (+this.state.pupilsAnswers[i] === 0) {
                            tens[0] = tens[0] + 1;
                        }
                    } else if (i === 5 || i === 8 || i === 12 || i === 13 || i === 15 || i === 21) {
                        if (+this.state.pupilsAnswers[i] === 1) {
                            tens[0] = tens[0] + 1;
                        }
                    }
                    //Сотрудничество — 2б, 5а, 8б, 11а, 14а, 19а, 20а, 21б, 23а, 26б, 28б, 30б.
                    if (i === 4 || i === 10 || i === 13 || i === 18 || i === 19 || i === 22) {
                        if (+this.state.pupilsAnswers[i] === 0) {
                            tens[1] = tens[1] + 1;
                        }
                    } else if (i === 1 || i === 7 || i === 20 || i === 25 || i === 27 || i === 29) {
                        if (+this.state.pupilsAnswers[i] === 1) {
                            tens[1] = tens[1] + 1;
                        }
                    }
                    //Компромисс — 2а, 4а, 7б, 10б, 12б, 13а, 18б, 20б, 22а, 24б, 26а, 29а.
                    if (i === 1 || i === 3 || i === 12 || i === 21 || i === 25 || i === 28) {
                        if (+this.state.pupilsAnswers[i] === 0) {
                            tens[2] = tens[2] + 1;
                        }
                    } else if (i === 6 || i === 9 || i === 11 || i === 17 || i === 19 || i === 23) {
                        if (+this.state.pupilsAnswers[i] === 1) {
                            tens[2] = tens[2] + 1;
                        }
                    }
                    //Избежание — 1а, 5б, 7а, 9а, 12а, 15б, 17б, 19б, 21а, 23б, 27а, 29б.
                    if (i === 0 || i === 6 || i === 8 || i === 11 || i === 20 || i === 26) {
                        if (+this.state.pupilsAnswers[i] === 0) {
                            tens[3] = tens[3] + 1;
                        }
                    } else if (i === 4 || i === 14 || i === 16 || i === 18 || i === 22 || i === 28) {
                        if (+this.state.pupilsAnswers[i] === 1) {
                            tens[3] = tens[3] + 1;
                        }
                    }
                    //Приспособление — 1б, 3б, 4б, 6а, 11б, 15а, 16а, 18а, 24а, 25б, 27б, 30а.
                    if (i === 5 || i === 14 || i === 15 || i === 17 || i === 23 || i === 29) {
                        if (+this.state.pupilsAnswers[i] === 0) {
                            tens[4] = tens[4] + 1;
                        }
                    } else if (i === 0 || i === 2 || i === 3 || i === 10 || i === 24 || i === 26) {
                        if (+this.state.pupilsAnswers[i] === 1) {
                            tens[4] = tens[4] + 1;
                        }
                    }
                }
                let ball = 0;
                let selectedDirection = 0;
                for (let i = 0; i < tens.length; i++) {
                    if (+tens[i] >= ball) {
                        ball = Number(tens[i]);
                        selectedDirection = i;
                    }
                }
                console.log(ball);
                tendencyName = this.state.tendencies[selectedDirection];
                let oldAnswer = localStorage.getItem('fullAnswer');
                localStorage.setItem('fullAnswer', oldAnswer + '_____' + tendencyName);
                localStorage.setItem('selectedTest', JSON.stringify({
                    "id": 13,
                    "name": this.state.language === '1' ? "Kasbiy so`nish" : "Профессиональное выгорание"
                }));
	            Swal.fire({
		            title: strings.testResult,
		            text: tendencyName,
		            icon: 'info',
		            showCancelButton: false,
		            confirmButtonColor: '#3085d6',
		            confirmButtonText: 'OK'
	            }).then((result1) => {
		            axios.get(_.apiHost + '/test/list?methodId=13&language=' + this.state.language)
			            .then(testApiResult => {
				            if (+testApiResult.data.statusCode === 200) {
					            localStorage.setItem('selectedTestQuestions', JSON.stringify(testApiResult.data.data));
					            this.NavigateToTesting();
				            }
			            });
	            });
            } else if (Number(this.state.selectedTest.id) === 13) {
	            let data = new FormData();
	            data.append('teacherId', this.state.pupil.id);
	            data.append('methodId', this.state.selectedTest.id);
	            data.append('testResult', this.state.pupilsAnswers.join('_'));
	            axios.post(_.apiHost + '/test/saveTeacherResult2', data)
		            .then(apiResult => {
			            Swal.fire({
				            title: strings.testResult,
				            text: apiResult.data.data.data.name,
				            icon: 'info',
				            showCancelButton: false,
				            confirmButtonColor: '#3085d6',
				            confirmButtonText: 'OK'
			            }).then((result1) => {
                            this.setState = {
                                question: '',
                                questions: [],
                                answers: [],
                                answers1: [],
                                questionCount: 0,
                                questionNumber: 0,
                                pupilsAnswers: [],
                                selectedTest: {},
                                pupil: {}
                            };
                            localStorage.removeItem('answers');
                            localStorage.removeItem('fullAnswer');
                            localStorage.removeItem('selectedTest');
                            localStorage.removeItem('selectedTestQuestions');
                            document.getElementById('testFinishBtn').disabled = false;
				            axios.get(_.apiHost + '/test/list?methodId=44&language=1')
					            .then(testApiResult => {
						            if (testApiResult.data.statusCode === 200) {
							            localStorage.setItem('selectedTest', JSON.stringify({
								            "id": 44,
								            "name": "O`qituvchi shaxsining faoliyati yo`nalganligi xususiyatini baholash"
							            }));
							            localStorage.setItem('selectedTestQuestions', JSON.stringify(testApiResult.data.data));
							            this.NavigateToTesting();
						            }
					            });
			            });
		            });

            } else if (Number(this.state.selectedTest.id) === 14) {
                document.getElementById('testFinishBtn').disabled = true;
                let tens = new Array(7).fill(0);
                for (let i = 0; i < this.state.pupilsAnswers.length; i++) {
                    //1. Mantiqiy-matematik intellekt
                    tens[0] = Number(this.state.pupilsAnswers[0]) + Number(this.state.pupilsAnswers[7]) + Number(this.state.pupilsAnswers[14]) + Number(this.state.pupilsAnswers[21]);

                    //2. Vizual-fazoviy intellekt
                    tens[1] = Number(this.state.pupilsAnswers[1]) + Number(this.state.pupilsAnswers[8]) + Number(this.state.pupilsAnswers[15]) + Number(this.state.pupilsAnswers[22]);

                    //3. Verbal yoki lingvistik intellekt
                    tens[2] = Number(this.state.pupilsAnswers[2]) + Number(this.state.pupilsAnswers[9]) + Number(this.state.pupilsAnswers[16]) + Number(this.state.pupilsAnswers[23]);

                    //4. Tana-kinestetik intellekt
                    tens[3] = Number(this.state.pupilsAnswers[3]) + Number(this.state.pupilsAnswers[10]) + Number(this.state.pupilsAnswers[17]) + Number(this.state.pupilsAnswers[24]);

                    //5. Musiqiy intellekt-musiqa yozish va baholash
                    tens[4] = Number(this.state.pupilsAnswers[4]) + Number(this.state.pupilsAnswers[11]) + Number(this.state.pupilsAnswers[18]) + Number(this.state.pupilsAnswers[25]);

                    //6. Emotsional yoki shaxslararo intellekt
                    tens[5] = Number(this.state.pupilsAnswers[5]) + Number(this.state.pupilsAnswers[12]) + Number(this.state.pupilsAnswers[19]) + Number(this.state.pupilsAnswers[26]);

                    //7. Ekzistensial yoki ma`naviy intellekt
                    tens[5] = Number(this.state.pupilsAnswers[6]) + Number(this.state.pupilsAnswers[13]) + Number(this.state.pupilsAnswers[20]) + Number(this.state.pupilsAnswers[27]);

                }
                let maxValue = 0, maxValueIndex = 0;
                for (let i = 0; i < tens.length; i++) {
                    if (tens[i] > maxValue) {
                        maxValueIndex = i;
                    }
                }
                tendencyName = this.state.tendencies[maxValueIndex];
                localStorage.setItem('fullAnswer', localStorage.getItem('fullAnswer') + '_____' + tendencyName);
                axios.get(_.apiHost + '/test/saveResult?pupilId=' + this.state.pupil.id + '&testMethodId=' + this.state.selectedTest.id + '&testResult=' + localStorage.getItem('fullAnswer').split(' ').join('_'))
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            document.getElementById('testFinishBtn').disabled = false;
                            localStorage.removeItem('pupil');
                            localStorage.removeItem('selectedTest');
                            localStorage.removeItem('selectedTestQuestions');
                            this.setState = {
                                question: '',
                                questions: [],
                                questionCount: 0,
                                questionNumber: 0,
                                pupilsAnswers: [],
                                selectedTest: {},
                                pupil: {}
                            };
                            let split = localStorage.getItem('fullAnswer').split('_____');
                            let response = 'Differensial-diagnostik so`rovnoma: ' + split[0] + '\n Qiziqishlar xaritasi: ' + split[1] + '\n Intellektual qobiliyatni aniqlash: ' + split[2];
                            Swal.fire({
                                title: 'Test natijasi',
                                text: response,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            })
                                .then((result1) => {
                                    let klass = localStorage.getItem('klass');
                                    if (klass === 11) {
                                        const link = document.createElement('a');
                                        link.href = _.apiHost + result.data.data;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }
                                    localStorage.removeItem('answers');
                                    localStorage.removeItem('fullAnswer');
                                    localStorage.removeItem('language');
                                    setTimeout(function () {
                                        window.open("/", "_self");
                                    }, 1000);
                                });
                        } else {
                            document.getElementById('testFinishBtn').disabled = false;
                            Swal.fire({
                                title: 'Test natijasi',
                                text: 'Test natijalari saqlanmadi',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            })
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 16) {
                document.getElementById('testFinishBtn').disabled = true;
                let tens = 0;
                for (let i = 0; i < this.state.pupilsAnswers.length; i++) {
                    tens = tens + Number(this.state.pupilsAnswers[i]);
                }
                let result = localStorage.getItem('fullAnswer') + '_____' + tens;
                axios.get(_.apiHost + '/test/saveResult?pupilId=' + this.state.pupil.id + '&testMethodId=' + this.state.selectedTest.id + '&testResult=' + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            localStorage.removeItem('pupil');
                            localStorage.removeItem('selectedTest');
                            localStorage.removeItem('answers');
                            this.setState = {
                                question: '',
                                questions: [],
                                questionCount: 0,
                                questionNumber: 0,
                                pupilsAnswers: [],
                                selectedTest: {},
                                pupil: {}
                            };
                            document.getElementById('testFinishBtn').disabled = false;
                            window.open('/', '_self');
                        } else {
                            document.getElementById('testFinishBtn').disabled = false;
                            Swal.fire({
                                title: 'Test natijasi',
                                text: 'Test natijalari saqlanmadi',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            })
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 19) {
                document.getElementById('testFinishBtn').disabled = true;
                let result = this.state.pupilsAnswers.join('_');
                axios.get(_.apiHost + '/test/checkResult?testMethodId=' + this.state.selectedTest.id + '&testResult=' + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            document.getElementById('testFinishBtn').disabled = false;
                            Swal.fire({
                                title: 'Test natijasi',
                                text: result.data.data,
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            }).then((result1) => {
                                localStorage.removeItem('pupil');
                                localStorage.removeItem('selectedTest');
                                localStorage.removeItem('language');
                                localStorage.removeItem('selectedTestQuestions');
                                this.setState = {
                                    question: '',
                                    questions: [],
                                    questionCount: 0,
                                    questionNumber: 0,
                                    pupilsAnswers: [],
                                    selectedTest: {},
                                    pupil: {},
                                    NavigateToShortCoursesPage: true
                                };
                                window.open("/", "_self");
                            });
                        } else {
                            Swal.fire({
                                title: 'Test natijasi',
                                text: result.data.data,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            })
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 39) {
                document.getElementById('testFinishBtn').disabled = true;
                let result = this.state.pupilsAnswers.join('_');
                axios.get(_.apiHost + '/test/checkResult3?testMethodId=' + this.state.selectedTest.id + '&teacherId=' + this.state.pupil.id + '&language=' + this.state.language + '&testResult=' + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            document.getElementById('testFinishBtn').disabled = false;
                            let a = document.createElement('a');
                            a.setAttribute('href', result.data.data);
                            a.setAttribute('download', 'Natija.pdf');
                            document.body.appendChild(a);
                            a.click();

	                        setTimeout(function(){ window.open(window.location.protocol + '//' + window.location.host + "/", '_self'); }, 3000);
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 32) {
                document.getElementById('testFinishBtn').disabled = true;
                let result = this.state.pupilsAnswers.join('_');
	            axios.get(_.apiHost + "/test/checkResult2?testMethodId=32&pupilId=" + this.state.pupil.id  + "&language=" + (this.state.language === 'uz' ? 1 : 2) + "&testResult=" + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
	                        Swal.fire({
		                        title: 'Test natijasi',
		                        text: result.data.data,
		                        icon: 'error',
		                        showCancelButton: false,
		                        confirmButtonColor: '#3085d6',
		                        confirmButtonText: 'OK'
	                        })
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 43) {
                let pupil = JSON.parse(localStorage.getItem('pupil'));
                document.getElementById('testFinishBtn').disabled = true;
                let result = this.state.pupilsAnswers.join('_');
                axios.post(_.apiHost + '/test/saveTeacherResult?methodId=' + this.state.selectedTest.id + '&teacherId=' + pupil.id + '&testResult=' + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            document.getElementById('testFinishBtn').disabled = false;
                            Swal.fire({
                                title: 'Test natijasi',
                                text: result.data.data,
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            }).then((result1) => {
	                            localStorage.removeItem('selectedTest');
	                            localStorage.removeItem('selectedTestQuestions');
	                            localStorage.removeItem('answers');
                                this.setState = {
                                    question: '',
                                    questions: [],
                                    questionCount: 0,
                                    questionNumber: 0,
                                    answers: [],
                                    answers1: [],
                                    pupilsAnswers: [],
                                    selectedTest: {},
                                };
	                            localStorage.setItem('selectedTest', JSON.stringify({"id": 44, "name": 'Shaxs faoliyatini yo`naltirishida natijalarni oldindan ko`ra bilish'}));
	                            localStorage.setItem('language', "1");
	                            axios.get(_.apiHost + '/test/list?methodId=44&language=1')
		                            .then(result2 => {
			                            if (result2.data.statusCode === 200) {
				                            localStorage.setItem('selectedTestQuestions', JSON.stringify(result2.data.data));
				                            this.NavigateToTesting();
			                            }
		                            });
                            });
                        } else {
                            Swal.fire({
                                title: 'Test natijasi',
                                text: result.data.data,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            })
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 44) {
                document.getElementById('testFinishBtn').disabled = true;
                let result = this.state.pupilsAnswers.join('_');
                axios.post(_.apiHost + '/test/saveTeacherResult2?methodId=44&teacherId=' + this.state.pupil.id + '&testResult=' + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
	                        document.getElementById('testFinishBtn').disabled = false;
	                        localStorage.removeItem('selectedTest');
	                        localStorage.removeItem('selectedTestQuestions');
	                        localStorage.removeItem('answers');
	                        this.setState = {
		                        question: '',
		                        questions: [],
		                        answers: [],
		                        answers1: [],
		                        questionCount: 0,
		                        questionNumber: 0,
		                        pupilsAnswers: [],
		                        selectedTest: {},
                                pupil: {},
	                        };
	                        localStorage.setItem('selectedTest', JSON.stringify({
		                        "id": 39,
		                        "name": "Umumta'lim maktablari pedagoglarining kompetentsiyasini ijtimoiy – psixologik baholash"
	                        }));
	                        axios.get(_.apiHost + '/test/list?methodId=39&language=1')
		                        .then(result2 => {
			                        if (result2.data.statusCode === 200) {
				                        localStorage.setItem('selectedTestQuestions', JSON.stringify(result2.data.data));
				                        this.NavigateToTesting();
			                        }
		                        });
                        }
                    });
            } else if (Number(this.state.selectedTest.id) === 45) {
	            document.getElementById('testFinishBtn').disabled = true;
	            let pupil = JSON.parse(localStorage.getItem('pupil'));
	            let result = this.state.pupilsAnswers.join('_');
	            axios.post(_.apiHost + '/test/saveTeacherResult?methodId=45&teacherId=' + pupil.id + '&testResult=' + result)
		            .then(result => {
			            if (result.data.statusCode === 200) {
				            Swal.fire({
					            title: 'Test natijasi',
					            text: result.data.data,
					            icon: 'info',
					            showCancelButton: false,
					            confirmButtonColor: '#3085d6',
					            confirmButtonText: 'OK'
				            }).then((result1) => {
					            localStorage.removeItem('pupil');
					            localStorage.removeItem('selectedTest');
					            localStorage.removeItem('selectedTestQuestions');
					            localStorage.removeItem('answers');
					            this.setState = {
						            question: '',
						            questions: [],
						            questionCount: 0,
						            questionNumber: 0,
						            pupilsAnswers: [],
						            selectedTest: {},
						            pupil: {}
					            };
					            document.getElementById('testFinishBtn').disabled = false;
					            window.open('/', '_self');
				            });
			            } else {
				            document.getElementById('testFinishBtn').disabled = false;
				            Swal.fire({
					            title: 'Test natijasi',
					            text: 'Test natijalari saqlanmadi',
					            icon: 'error',
					            showCancelButton: false,
					            confirmButtonColor: '#3085d6',
					            confirmButtonText: 'OK'
				            })
			            }
		            });
            } else if (Number(this.state.selectedTest.id) === 20 || Number(this.state.selectedTest.id) === 21 || Number(this.state.selectedTest.id) === 22
                || Number(this.state.selectedTest.id) === 23 || Number(this.state.selectedTest.id) === 24 || Number(this.state.selectedTest.id) === 25) {
                document.getElementById('testFinishBtn').disabled = true;
                let result = this.state.pupilsAnswers.join('_');
                axios.post(_.apiHost + '/test/checkCourseResult?teacherId=' + this.state.pupil.id + '&testMethodId=' + this.state.selectedTest.id + '&testResult=' + result)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            document.getElementById('testFinishBtn').disabled = false;
                            Swal.fire({
                                title: 'Test natijasi',
                                text: result.data.data.name,
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            }).then((result1) => {
                                localStorage.removeItem('pupil');
                                localStorage.removeItem('selectedTest');
                                localStorage.removeItem('language');
                                localStorage.removeItem('selectedTestQuestions');
                                this.setState = {
                                    question: '',
                                    questions: [],
                                    questionCount: 0,
                                    questionNumber: 0,
                                    pupilsAnswers: [],
                                    selectedTest: {},
                                    pupil: {}
                                };
                                window.open("/", "_self");
                            });
                        } else {
                            Swal.fire({
                                title: 'Test natijasi',
                                text: result.data.data,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            })
                        }
                    });
            }
        }
    }

    NavigateToTesting() {
	    window.location.reload();
    }

    getBall(answer) {
        let result = 0;
        switch (Number(answer)) {
            case 1:
                result = 2;
                break;
            case 2:
                result = 1;
                break;
            case 3:
                result = 0;
                break;
            case 4:
                result = -1;
                break;
            case 5:
                result = -2;
                break;
            default:
                result = 0;
                break;
        }
        return result;
    }

    render() {
	    if (this.state.toTesting) {
		    return <Navigate to='/testing'/>
	    }
        if (this.state.NavigateToShortCoursesPage) {
            return <Navigate to='/shortCourses'/>
        }
        if (this.state.NavigateToHomePage) {
            return <Navigate to='/'/>
        }
        return (
            <div style={{width: '95%', padding: '20px'}}>
                <div style={{width: '50%', float: 'left'}}><p>{(+this.state.selectedTest.id === 10 || +this.state.selectedTest.id === 11 || +this.state.selectedTest.id === 13 || +this.state.selectedTest.id === 39 || +this.state.selectedTest.id === 43 ||
	                +this.state.selectedTest.id === 44 || +this.state.selectedTest.id === 45) ? strings.teacher : strings.pupil}: {this.state.pupil.name}</p></div>
                <div style={{width: '50%', float: 'right'}}><p>{strings.method}: {this.state.selectedTest.name}</p>
                </div>
                <hr style={{marginTop: '50px'}}/>
                <div style={{width: '100%'}}>
                    <TestItem questionOrder={this.state.questionNumber + 1} testId={+this.state.selectedTest.id}
                              question={(+this.state.selectedTest.id === 2 || +this.state.selectedTest.id === 8) ? [] : this.state.question}
                              answers={this.state.answers1} toNextQuestion={this.toNextQuestion.bind(this)}/>

                </div>

                {this.state.fullAnswered &&
                <div style={{width: '100%', padding: '20px'}}>
                    <button id='testFinishBtn' className="btn btn-success" onClick={this.onFinishClick}>{strings.finish}</button>
                </div>
                }
            </div>
        )
    }
}

export default Testing;