import React, {Component} from 'react';
import axios from "axios";
import * as _ from './Utils';
import Swal from 'sweetalert2';
import './App.css';
import './bootstrap/4.4.1/css/bootstrap.min.css';

class ChangePasswordView extends Component {

  changePassword() {
    let userData = localStorage.getItem('userData');
    let userId = userData.id;
    let oldPassword = document.getElementById('oldPassword').value;
    let newPassword = document.getElementById('newPassword').value;
    let formData = new FormData();
    formData.append("userId", userId);
    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);
    axios.post(_.apiHost + '/user/changePassword', formData)
      .then(result=>{
        if (result.data.statusCode === 200) {
          Swal.fire(
            'Info',
            result.data.message,
            'success'
          ).then((result) => {
            this.props.onClose();
          })
        } else {
          Swal.fire(
            'Xatolik!',
            result.data.message,
            'error'
          )
        }
      })
  }

  render() {
    return (
      <div style={{padding: "20px"}}>
        <div className="row">
          <div className="col s6">
            <span>Eski parol:</span>
          </div>
          <div className="col s6">
            <input type="password" id="oldPassword" className="form-control"/>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <span>Yangi parol:</span>
          </div>
          <div className="col s6">
            <input type="password" id="newPassword" className="form-control"/>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            &nbsp;
          </div>
          <div className="col s6">
            <button className="btn btn-block btn-success" onClick={this.changePassword.bind(this)}>Saqlash</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePasswordView;