import React, {Component} from 'react';
import swal from 'sweetalert';
import axios from "axios";
import * as _ from './Utils';
import {Navigate} from "react-router";
import SchoolMenu from "./school/SchoolMenu";

class TeachersTest extends Component {

    constructor(props) {
        super(props);
        let userData = JSON.parse(localStorage.getItem('userData'));
        this.state = {
            states: [],
            schools: [],
            pupils: [],
            methods: [],
            sciences: [],
            userData: userData,
            toTesting: false
        }
    }

    onChangeTest() {
        let language = document.getElementById('language');
        localStorage.setItem('selectedTest', {"id": 10, "name": "\"Kasbiy stress shkalasi\" savolnomasi"});

        axios.get(_.apiHost + '/test/list?methodId=10&language=' + language.value)
            .then(result => {
                if (result.data.statusCode === 200) {
                    localStorage.setItem('selectedTestQuestions', result.data.data);
                }
            });
    }

    startTest() {
        this.onChangeTest();
        let errors = 0;
        let schoolId = localStorage.getItem('userData').stateId;
        let lastName = document.getElementById('lastName').value;
        errors = _.validateField('lastName', lastName, errors);
        let firstName = document.getElementById('firstName').value;
        errors = _.validateField('firstName', firstName, errors);
        let science = '';
        if (this.state.userData.role === 'SCHOOL') {
            science = document.getElementById('sciences').value;
            errors = _.validateSelect('sciences', errors);
        }
        let scienceLanguage = '';
        if (this.state.userData.role === 'SCHOOL') {
            scienceLanguage = document.getElementById('scienceLanguages').value;
            errors = _.validateSelect('scienceLanguages', errors);
        }
        let scienceKlass = '';
        if (this.state.userData.role === 'SCHOOL') {
            scienceKlass = document.getElementById('scienceKlasses').value;
            errors = _.validateSelect('scienceKlasses', errors);
        }
        let language = document.getElementById('language').value;
        errors = _.validateSelect('language', errors);
        if (errors > 0) {
            swal("Barcha ma'lumotlarni kiriting", '', 'error');
            return;
        }
        let transferData = {};
        if (this.state.userData.role === 'SCHOOL') {
            transferData = {
                "schoolId": schoolId,
                "lastName": lastName,
                "firstName": firstName,
                "science": {
                    "id": science
                },
                "scienceLanguage": {
                    "id": scienceLanguage
                },
                "scienceKlass": {
                    "id": scienceKlass
                }
            };
        } else {
            transferData = {
                "lastName": lastName,
                "firstName": firstName,
            };
        }

        axios.post(_.apiHost + '/user/addTeacher2', transferData)
            .then(result => {
                if (result.data.statusCode === 200) {
                    localStorage.setItem('pupil', {
                        "id": result.data.data.id,
                        "name": result.data.data.lastName + ' ' + result.data.data.firstName
                    });
                    localStorage.setItem('selectedTest', {
                        "id": 10,
                        "name": 'Касбий стресс шкаласи'
                    });
                    localStorage.setItem('language', language);
                    axios.get(_.apiHost + '/test/list?methodId=10&language=' + language)
                        .then(result => {
                            if (result.data.statusCode === 200) {
                                localStorage.setItem('selectedTestQuestions', result.data.data);
                                this.setState({
                                    toTesting: true
                                })
                            }
                        });

                }
            });
    }

    componentDidMount() {
        this.onChangeTest();
        axios.get(_.apiHost + '/user/sciences')
            .then(result => {
                if (result.data.statusCode === 200) {
                    let scienceList = [];
                    for (let i = 0; i < result.data.data.length; i++) {
                        let science = result.data.data[i];
                        scienceList.push(<option key={science.id} value={science.id}>{science.name}</option>)
                    }
                    this.setState({
                        sciences: scienceList
                    })
                }
            });
    }

    render() {
        if (this.state.toTesting) {
            return <Navigate to='/testing'/>
        }
        let userData = JSON.parse(localStorage.getItem('userData'));
        return (
            <div className="content">
                {userData.role === 'SCHOOL' &&
                    <SchoolMenu/>
                }
                {this.state.userData !== undefined && this.state.userData !== null &&
                <div className="container card card-body my-4 shadow-sm">
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Familiyasi:</span>
                        </div>
                        <div className="col-md-9">
                            <input id='lastName' className="form-control" type="text"/>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Ismi:</span>
                        </div>
                        <div className="col-md-9">
                            <input id='firstName' className="form-control" type="text"/>
                        </div>
                    </div>
                    {userData.role === 'SCHOOL' &&
                    <div>
                        <div className="form-row form-group">
                            <div className="col-md-3">
                                <span>Dars beradigan fani:</span>
                            </div>
                            <div className="col-md-9">
                                <select id="sciences" className="form-control">
                                    {this.state.sciences}
                                </select>
                            </div>
                        </div>
                        <div className="form-row form-group">
                            <div className="col-md-3">
                                <span>Dars berish tili</span>
                            </div>
                            <div className="col-md-9">
                                <select id="scienceLanguages" className="form-control">
                                    <option value="1">O`zbek</option>
                                    <option value="2">Rus</option>
                                    <option value="3">Qoraqalpoq</option>
                                    <option value="4">Qozoq</option>
                                    <option value="5">Qirg`iz</option>
                                    <option value="6">Tojik</option>
                                    <option value="7">Turkman</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row form-group">
                            <div className="col-md-3">
                                <span>Qaysi sinflarga dars beradi</span>
                            </div>
                            <div className="col-md-9">
                                <select id="scienceKlasses" className="form-control">
                                    <option value="1">1-4 sinflarda</option>
                                    <option value="2">5-9 sinflarda</option>
                                    <option value="3">10-11 sinflarda</option>
                                    <option value="4">Aralash sinflarda</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Test tili:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='language' className="form-control">
                                <option value="1">O`zbek</option>
                                <option value="2">Rus</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row text-center">
                        <div className="col-md-3">
                            
                        </div>
                        <div className="col-md-9">
                            <button className="btn btn-success" onClick={this.startTest.bind(this)}>Testni boshlang</button>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default TeachersTest;