import React, {Component} from 'react';
import '../App.css';
import AdminMenu from "./AdminMenu";

class AdminHomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      teachers: []
    }
  }

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div style={{width: "100%"}}>
        {userData !== undefined && userData !== null &&
        <AdminMenu/>
        }
        {(userData === undefined || userData === null) &&
        <span>Tizimga kirish uchun login varolni to`g`ri tering</span>
        }
      </div>
    )
  }
}

export default AdminHomePage;