import React, {Component} from 'react';
import AdminMenu from "./AdminMenu";
import axios from 'axios';
import Cookies from 'universal-cookie';
import {apiHost} from "../Utils";
const cookies = new Cookies();

export default class TeacherPsychTestResultList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			methodId: 10,
			regionId: 0,
			districtId: 0,
			districts: [],
			schools: [],
			testResult: []
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
	}

	handleChange(e) {
		let name = e.target.id;
		if (name === 'regionId') {
			let value = e.target.value;
			axios.get(apiHost + '/pupil/district/list?stateId=' + value)
				.then(data => {
					if (data.data.statusCode === 200) {
						let districtList = data.data.data.map(row=> {
							return <option key={row.id} value={row.id}>{row.name}</option>
						});
						districtList.unshift(<option key="0" value="0">Tanlang</option>);
						this.setState({
							districts: districtList,
							schools: []
						})
					} else {
						this.setState({
							districts: []
						})
					}
				})
		} else if (name === 'districtId') {
			let value = e.target.value;
			axios.get(apiHost + '/pupil/school/list?districtId=' + value)
				.then(data => {
					if (data.data.statusCode === 200) {
						let schoolList = data.data.data.map(school=> {
							return <option key={school.id} value={school.id}>{school.name}</option>
						});
						schoolList.unshift(<option key="0" value="0">Tanlang</option>);
						this.setState({
							schools: schoolList
						})
					} else {
						this.setState({
							schools: []
						})
					}
				})
		}
		let regionId = document.getElementById('regionId').value;
		let districtId = document.getElementById('districtId').value;
		let methodId = document.getElementById('methodId').value;
		let schoolId = document.getElementById('schoolId').value;
		this.fetchTestResult(regionId, districtId, schoolId, methodId);
	}

	fetchTestResult(regionId, districtId, schoolId, methodId) {
		axios.get(apiHost + '/test/teacherTestResults?regionId=' + regionId + '&districtId=' + districtId + '&schoolId=' + schoolId + '&methodId=' + methodId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let testResult = data.data.data.map((row, index)=>{
						return <tr><td>{index+1}</td><td>{row.districtName}</td><td>{row.schoolName}</td><td>{row.pupilName}</td><td>{row.dateOfTest}</td><td>{row.testResult}</td></tr>
					});
					this.setState({
						testResult: testResult
					})
				} else {
					this.setState({
						testResult: []
					})
				}
			})
	}

	exportToExcel() {
		let regionId = document.getElementById('regionId').value;
		let districtId = document.getElementById('districtId').value;
		let methodId = document.getElementById('methodId').value;
		let schoolId = document.getElementById('schoolId').value;
		cookies.set('regionId', regionId, {path: '/'});
		cookies.set('districtId', districtId, {path: '/'});
		cookies.set('methodId', methodId, {path: '/'});
		cookies.set('schoolId', schoolId, {path: '/'});
		let form = document.getElementById('excelForm');
		form.submit();
	}

	render() {
		let userData = JSON.parse(localStorage.getItem('userData'));
		return (
			<div>
				{userData !== undefined && userData !== null &&
				<div style={{width: "100%"}}>
					<AdminMenu/>
					<div className="container card card-body my-4 shadow-sm mt-5">
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="methodId">Metodika</label>
								<select id="methodId" className="form-control" onChange={this.handleChange.bind(this)}>
									<option value={10}>“Kasbiy stress shkalasi” savolnomasi</option>
									<option value={11}>Shaxslararo munosabatlarni aniqlash metodikasi</option>
									<option value={13}>"Kasbiy so`nish" metodikasi</option>
									<option value={43}>"Xavotirlanish darajasini aniqlash" metodikasi</option>
									<option value={44}>Shaxs faoliyatini yo`naltirishida natijalarni oldindan ko`ra bilish</option>
									<option value={45}>"O`qituvchi shaxsini psixologik o`rganish" testi</option>
								</select>
							</div>
							<div className="col-md-3">
								<label htmlFor="regionId">Hudud</label>
								<select id="regionId" className="form-control" onChange={this.handleChange.bind(this)}>
									<option value="0">Tanlang</option>
									<option value="1">Qoraqalpog`iston Respublikasi</option>
									<option value="2">Andijon viloyati</option>
									<option value="3">Buxoro viloyati</option>
									<option value="4">Qashqadaryo viloyati</option>
									<option value="5">Jizzax viloyati</option>
									<option value="6">Navoiy viloyati</option>
									<option value="7">Namangan viloyati</option>
									<option value="8">Samarqand viloyati</option>
									<option value="9">Sirdaryo viloyati</option>
									<option value="10">Surxondaryo viloyati</option>
									<option value="11">Toshkent viloyati</option>
									<option value="12">Farg`ona viloyati</option>
									<option value="13">Xorazm viloyati</option>
									<option value="14">Toshkent shahri</option>
								</select>
							</div>
							<div className="col-md-3">
								<label htmlFor="districtId">Tuman(shahar)</label>
								<select id="districtId" className="form-control" onChange={this.handleChange.bind(this)}>
									{this.state.districts}
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="schoolId">Maktabi</label>
								<select id="schoolId" className="form-control" onChange={this.handleChange.bind(this)}>
									{this.state.schools}
								</select>
							</div>
							<div className="col-md-1" style={{marginTop: "32px"}}>
								<form id="excelForm" method="post" action={apiHost + "/common/teacherPsychTestResultExcel"}>
									<button className="btn btn-info" onClick={this.exportToExcel.bind(this)}>Excel</button>
								</form>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>№</th>
											<th>Tuman(shahar)</th>
											<th>Maktab</th>
											<th>F.I.Sh</th>
											<th>Vaqti</th>
											<th>Natija</th>
										</tr>
									</thead>
									<tbody>
										{this.state.testResult}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				}
			</div>
		);
	}
}