import React, {Component} from 'react';
import swal from 'sweetalert';
import axios from "axios";
import * as _ from './Utils';
import {Navigate} from "react-router";

class TeachersPublicTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            states: [],
            districts: [],
            schools: [],
            toTesting: false
        }
    }

    startTest() {
        let errors = 0;
        let schoolId = document.getElementById('school').value;
        let lastName = document.getElementById('lastName').value;
        errors = _.validateField('lastName', lastName, errors);
        let firstName = document.getElementById('firstName').value;
        errors = _.validateField('firstName', firstName, errors);
        let middleName = document.getElementById('middleName').value;
        errors = _.validateField('middleName', middleName, errors);
        let experience = document.getElementById('experience').value;
        errors = _.validateField('experience', experience, errors);
        let pension = document.getElementById('pension').value;
        errors = _.validateField('pension', pension, errors);
        if (errors > 0) {
            swal("Barcha ma'lumotlarni kiriting", '', 'error');
            return;
        }
        let transferData = {
            "schoolId": schoolId,
            "lastName": lastName,
            "firstName": firstName,
            "middleName": middleName,
            "experience": {
                "id": experience
            },
            "pension": {
                "id": pension
            }
        };

        axios.post(_.apiHost + '/user/addTeacher3', transferData)
            .then(result => {
                if (result.data.statusCode === 200) {
                    localStorage.setItem('pupil', JSON.stringify({
                    	"id": result.data.data.id,
                    	"name": result.data.data.lastName + ' ' + result.data.data.firstName + ' ' + result.data.data.middleName,
                    }));

	                let methodId = document.getElementById('method').value;
	                let language = +methodId===43 ? 1 : document.getElementById('language').value;

                    localStorage.setItem('language', language);
	                localStorage.setItem('selectedTest', JSON.stringify({"id": methodId, "name": +methodId === 10 ? (+language === 1 ? '“Касбий стресс шкаласи” саволномаси' : 'Анкета "Шкала профессионального стресса"') :
		                '"Xavotirlanish darajasini aniqlash" metodikasi'}));
	                axios.get(_.apiHost + '/test/list?methodId=' + methodId + '&language=' + language)
		                .then(result => {
			                if (result.data.statusCode === 200) {
				                localStorage.setItem('selectedTestQuestions', JSON.stringify(result.data.data));
				                this.setState({
					                toTesting: true
				                });
			                }
		                });
                }
            });
    }

	onChangeStates() {
		let stateId = document.getElementById('region').value;
		axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let districtList = [];
					for (let i = 0; i < data.data.data.length; i++) {
						let user = data.data.data[i];
						districtList.push(<option key={user.id} value={user.id}>{user.name}</option>)
					}
					districtList.unshift(<option key="0" value="0">Tanlang</option>);
					this.setState({
						districts: districtList
					})
				} else {
					this.setState({
						districts: []
					})
				}
			})
	}

	onChangeDistrict() {
		let districtId = document.getElementById('district').value;
		axios.get(_.apiHost + '/pupil/school/list?districtId=' + districtId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let schoolList = data.data.data.map(school=> {
						return <option key={school.id} value={school.id}>{school.name}</option>
					});
					schoolList.unshift(<option key="0" value="0">Tanlang</option>);
					this.setState({
						schools: schoolList
					})
				} else {
					this.setState({
						schools: []
					})
				}
			})
	}

    render() {
	    if (this.state.toTesting) {
		    return <Navigate to='/testing'/>
	    }
        return (
            <div className="content">
                <div className="container card card-body my-4 shadow-sm">
                    <div className="form-row">
                        <div className="col-md-4">
                            <label htmlFor="region">Hududi:</label>
                            <select id='region' className="form-control" onChange={this.onChangeStates.bind(this)}>
	                            <option value="0">Tanlang</option>
	                            <option value="1">Qoraqalpog`iston Respublikasi</option>
	                            <option value="2">Andijon viloyati</option>
	                            <option value="3">Buxoro viloyati</option>
	                            <option value="4">Qashqadaryo viloyati</option>
	                            <option value="5">Jizzax viloyati</option>
	                            <option value="6">Navoiy viloyati</option>
	                            <option value="7">Namangan viloyati</option>
	                            <option value="8">Samarqand viloyati</option>
	                            <option value="9">Sirdaryo viloyati</option>
	                            <option value="10">Surxondaryo viloyati</option>
	                            <option value="11">Toshkent viloyati</option>
	                            <option value="12">Farg`ona viloyati</option>
	                            <option value="13">Xorazm viloyati</option>
	                            <option value="14">Toshkent shahri</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="district">Tumani(shahar):</label>
                            <select id='district' className="form-control" onChange={this.onChangeDistrict.bind(this)}>
	                            {this.state.districts}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="school">Maktabi:</label>
                            <select id='school' className="form-control">
	                            {this.state.schools}
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-4">
                            <label htmlFor="lastName">Familiyasi:</label>
                            <input id='lastName' className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="firstName">Ismi:</label>
                            <input id='firstName' className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="middleName">Otasining ismi:</label>
                            <input id='middleName' className="form-control" type="text"/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            <label htmlFor="experience">Ish staji:</label>
                            <select id="experience" className="form-control">
                                <option value={0}>Tanlang</option>
                                <option value={1}>0 - 5 yil</option>
                                <option value={2}>6 - 10 yil</option>
                                <option value={3}>11 - 15 yil</option>
                                <option value={4}>16 - 20 yil</option>
                                <option value={5}>21 - 25 yil</option>
                                <option value={5}>25 dan yuqori</option>
                            </select>
                        </div>

	                    <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            <label htmlFor="pension">Pensiyaga chiqqanmi</label>
                            <select id="pension" className="form-control">
                                <option value="1">Ha</option>
                                <option value="2">Yo`q</option>
                            </select>
                        </div>

	                    <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            <label htmlFor="language">Test tili</label>
                            <select id="language" className="form-control">
                                <option value="1">O`zbek</option>
                                <option value="2">Rus</option>
                            </select>
                        </div>

	                    <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            <label htmlFor="method">Test to`plami</label>
                            <select id="method" className="form-control">
                                <option value="43">1-to`plam</option>
                                <option value="10">2-to`plam</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-success" onClick={this.startTest.bind(this)}>Testni boshlang</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeachersPublicTest;