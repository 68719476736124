import React, {Component} from 'react';
import '../App.css';
import RegionMenu from "./RegionMenu";
import {NavigateToLogin} from "../Utils";

class RegionHomePage extends Component {

	render() {
		let userData = JSON.parse(localStorage.getItem('userData'));
		if (userData === undefined || userData === null || userData.role !== 'STATE') {
			NavigateToLogin();
		}
		return (
      <div style={{width: "100%"}}>
				{userData !== undefined && userData !== null && userData.role === 'STATE' &&
        <div id='__react-content' className="flt topblock">
          <RegionMenu/>
        </div>
				}
      </div>
		)
	}
}

export default RegionHomePage;