import React, {Component} from 'react';
import './App.css';
const logo = require('./header.jpg');

class Header extends Component {

    render() {
        return (
            <div className="text-center" style={{width: "100%", height: "202px",marginBottom: "20px"}}>
                <img src={logo} alt="Respublika Tashxis Markazi"/>
                <p style={{position: "absolute", top: "35px", width: "100%", textAlign: "center"}}><span
                    style={{fontSize: "30px"}}><b>O‘zbekiston Respublikasi Xalq ta’limi vazirligi</b></span></p>
                <p style={{position: "absolute", top: "110px", width: "100%", textAlign: "center"}}><span
                    style={{fontSize: "21px"}}><b>O‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik<br/>respublika tashxis markazi</b></span>
                </p>
            </div>
        )
    }

}

export default Header;