import React, {Component} from 'react';
import '../App.css';
import TashxisMenu from "./TashxisMenu";

class TashxisHomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      teachers: []
    }
  }

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div style={{width: "1000px"}}>
        {(userData === undefined || userData === null) &&
        <div>
          <span>Tizimga kirish uchun login varolni to`g`ri tering</span>
        </div>
        }
        <div id='__react-content' className="flt topblock">
          <TashxisMenu/>
        </div>
      </div>
    )
  }
}

export default TashxisHomePage;