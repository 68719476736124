import React, {Component} from 'react';
import './App.css';
import axios from "axios";
import * as _ from './Utils';
import {apiHost} from './Utils';
import swal from "sweetalert";

class ReTrainingTestStartPage2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teachers: [],
            showListeners: false,
            selectedGroup: null
        }
    }

    onChangeGroup(groupId) {
        axios.get(_.apiHost + '/teacher/listByGroup?groupId=' + groupId)
            .then(data => {
                if (data.data.statusCode === 200) {
                    let teacherList = [];
                    for (let i = 0; i < data.data.data.length; i++) {
                        let pupil = data.data.data[i];
                        teacherList.push(<option key={pupil.id} value={pupil.id}>{pupil.fullName}</option>)
                    }
                    this.setState({
                        teachers: teacherList
                    })
                } else {
                    this.setState({
                        teachers: []
                    })
                }
            })
    }

    startTest() {
        let teachers = document.getElementById('teachers');
        if (Number(teachers.value) > 0) {
            localStorage.setItem('teacher', JSON.stringify({
                "id": teachers.value,
                "name": teachers.options[teachers.selectedIndex].text
            }));
            localStorage.setItem('methodId', 53);
            window.open('/moTest2', '_self');
        } else {
            swal('Tinglovchini tanlang!', '', 'warning');
        }
    }

    login() {
        let userName = document.getElementById('userName').value;
        let password = document.getElementById('password').value;
        let data = {
            'userName': userName,
            'password': password
        };
        axios.post(apiHost + '/teacher/secEdu/login', data, _.requestHeader()).then(result=>{
            if (result.data.statusCode === 200) {
                let groupNumber = Number(result.data.data);
                if (groupNumber!==undefined && groupNumber!==null && groupNumber > 0) {
                    this.setState({
                        selectedGroup: groupNumber,
                        showListeners: true
                    });
                    this.onChangeGroup(groupNumber);
                }
            }
        });
    }

    render() {
        return (
            <div className="container card card-body shadow-sm mt-3 w-50">
                {!this.state.showListeners &&
                <div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <label htmlFor="userName">Login:</label>
                            <input id="userName" className="form-control"/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <label htmlFor="password">Parol:</label>
                            <input id="password" type="password" className="form-control"/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-success" onClick={this.login.bind(this)}>Kirish</button>
                        </div>
                    </div>
                </div>
                }
                {this.state.showListeners &&
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="teachers">Tinglovchi:</label>
                                <select id="teachers" className="form-control">
                                    <option value="0">Tanlang</option>
                                    {this.state.teachers}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.startTest.bind(this)}>Testni boshlash</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default ReTrainingTestStartPage2;