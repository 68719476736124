import React, {Component} from 'react';
import '../App.css';
import axios from "axios";
import * as _ from '../Utils';
import ReactTable from 'react-table';
import Cookies from 'universal-cookie';

import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();

class ReportForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      teachers: [],
      groups: [],
      showAddButton: props.showAddButton !== undefined && props.showAddButton !== null ? props.showAddButton : true
    }
  }

  componentDidMount() {
    let groupList = [];
    for (let i = 1; i <= 50; i++) {
      groupList.push(<option key={i} value={i}>{i + '-guruh'}</option>)
    }
    this.setState({
      groups: groupList
    })
  }

  handleChangeStartDate(e) {
    this.setState({
      startDate: e.value
    });
    console.log(this.state.startDate);
  }

  handleChangeEndDate(e) {
    this.setState({
      endDate: e.value
    });
    console.log(this.state.endDate);
  }

  onChangeGroup(e) {
    let groupNumber = e.target.value;
    axios.get(_.apiHost + '/test/reTrainingTestResult?groupNumber=' + groupNumber)
      .then(result => {
        if (result.data.statusCode === 200) {
          this.setState({
            teachers: result.data.data !== undefined ? result.data.data : []
          })
        }
      })
  }

  exportToExcel() {
    let groupNumber = document.getElementById('districts').value;
    cookies.set('groupNumber', groupNumber, {path: '/'});
    let form = document.getElementById('excelForm');
    form.submit();
  }

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div>
        {userData !== undefined && userData !== null &&
        <table>
          <tbody>
          <tr>
            <td style={{float: "right"}}>
              <table>
                <tr>
                  <td>
                    <span>Guruhni tanlang:</span>
                  </td>
                  <td>
                    <select id='districts' style={{float: "right"}} onChange={this.onChangeGroup.bind(this)}>
                      <option value="0">Tanlang</option>
                      {this.state.groups}
                    </select>
                  </td>
                  <td>
                    <form id="excelForm" method="post" action={_.apiHost + "/common/testReportPDF"}
                          style={{float: "left", marginLeft: "5px"}}>
                      <button className="btn btn-block" onClick={this.exportToExcel.bind(this)}>PDF</button>
                    </form>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <ReactTable
                data={this.state.teachers}
                columns={[{
                  columns: [{
                    Header: _.strings.teacherName,
                    accessor: 'pupilName'
                  }, {
                    Header: _.strings.districtName,
                    accessor: 'districtName'
                  }, {
                    Header: _.strings.schoolName,
                    accessor: 'schoolName'
                  }, {
                    Header: _.strings.testDate,
                    accessor: 'dateOfTest'
                  }, {
                    Header: _.strings.testResult,
                    accessor: 'testResult'
                  }]
                }]}
                noDataText={_.strings.noDataText}
                previousText={_.strings.previousText}
                nextText={_.strings.nextText}
                pageText={_.strings.pageText}
                ofText={_.strings.ofText}
                rowsText={_.strings.rowsText}
                defaultPageSize={10}
                resizable={true}
                showPageSizeOptions={true}
                className="-striped -highlight"
                style={{width: "1000px"}}
              />
            </td>
          </tr>
          </tbody>
        </table>
        }
      </div>
    )
  }
}

export default ReportForm;