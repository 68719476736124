import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import {strings} from '../Utils';
import '../index.css';
import ReactTable from 'react-table';
import Modal from "react-responsive-modal";
import SchoolMenu from "./SchoolMenu";
import PupilSurveyView from "./PupilSurveyView";

class SchoolPupilSurveyList extends Component {

    constructor(props) {
        super(props);
        let userData = JSON.parse(localStorage.getItem('userData'));
        this.state = {
            districts: [],
            schools: [],
            survey: null,
            pupil: null,
            pupils: [],
            surveyModal: false,
            userData: userData
        }
    }

    componentDidMount() {
        this.getSurveyList();
    }

    getSurveyList() {
        let schoolId = this.state.userData !== undefined && this.state.userData !== null ? this.state.userData.stateId : null;
        let klass = document.getElementById('klass').value;
        let klassLetter = document.getElementById('klassLetter').value;
        if (klass === undefined || klass === null) {
            klass = 11;
        }
        axios.get(_.apiHost + '/pupil/surveyList?schoolId=' + schoolId + '&klass=' + klass + '&klassLetter=' + klassLetter)
            .then(result => {
                if (result.data.statusCode === 200) {
                    let resultData = result.data !== undefined && result.data !== null && result.data.data !== undefined && result.data.data !== null ? result.data.data : [];
                    this.setState({
                        pupils: resultData
                    });
                }
            })
    }

    openPupilSurveyForm(survey) {
        this.setState({
            survey: survey,
            pupil: survey.pupil,
            surveyModal: true
        })
    }

    closePupilSurveyForm() {
        this.setState({
            survey: null,
            pupil: null,
            surveyModal: false
        })
    }

    render() {
        return (
            <div className="content">
                <SchoolMenu/>
                {this.state.userData !== undefined && this.state.userData !== null &&
                <div className="card card-body my-4 shadow-sm">
                    <div className="form-row">
                        <div className="col-md-2">
                            <select id="klass" className="form-control" onChange={this.getSurveyList.bind(this)}>
                                <option value="9">9</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select id="klassLetter" className="form-control" onChange={this.getSurveyList.bind(this)}>
                                <option value=""> sinf</option>
                                <option value="A">A-sinf</option>
                                <option value="B">B-sinf</option>
                                <option value="V">V-sinf</option>
                                <option value="G">G-sinf</option>
                                <option value="D">D-sinf</option>
                                <option value="E">E-sinf</option>
                                <option value="Yo">Yo-sinf</option>
                                <option value="J">J-sinf</option>
                                <option value="Z">Z-sinf</option>
                                <option value="I">I-sinf</option>
                                <option value="Y">Y-sinf</option>
                                <option value="K">K-sinf</option>
                                <option value="L">L-sinf</option>
                                <option value="M">M-sinf</option>
                                <option value="N">N-sinf</option>
                                <option value="O">O-sinf</option>
                                <option value="P">P-sinf</option>
                                <option value="Q">Q-sinf</option>
                                <option value="R">R-sinf</option>
                                <option value="S">S-sinf</option>
                                <option value="T">T-sinf</option>
                                <option value="U">U-sinf</option>
                                <option value="F">F-sinf</option>
                                <option value="X">X-sinf</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12">
                            <ReactTable data={this.state.pupils}
                                        columns={[{
                                            columns: [{
                                                Header: '№',
                                                accessor: 'order',
                                                width: 50
                                            }, {
                                                Header: _.strings.pupilName,
                                                accessor: 'pupil.name'
                                            }, {
                                                Header: 'Ma`lumotga egaligi',
                                                accessor: 'haveReduction.name'
                                            }, {
                                                Header: 'O`qish turi',
                                                accessor: 'studyType.name'
                                            }, {
                                                Header: 'TM nomi',
                                                accessor: 'institution.name'
                                            }, {
                                                Header: 'Yo`nalish nomi',
                                                accessor: 'tendency.name'
                                            }, {
                                                Header: 'Tanlov turi',
                                                accessor: 'choiceType.name'
                                            }, {
                                                Header: _.strings.language,
                                                accessor: 'language.name'
                                            }, {
                                                Header: _.strings.action,
                                                Cell: p => (
                                                    <div style={{width: "100px"}}>
                                                        <button className="btn btn-success btn-sm"
                                                              onClick={this.openPupilSurveyForm.bind(this, p.original)}>{strings.survey}</button>
                                                    </div>
                                                )
                                            }]
                                        }]}
                                        noDataText={_.strings.noDataText}
                                        previousText={_.strings.previousText}
                                        nextText={_.strings.nextText}
                                        pageText={_.strings.pageText}
                                        ofText={_.strings.ofText}
                                        rowsText={_.strings.rowsText}
                                        defaultPageSize={20}
                                        multiSort={false}
                                        resizable={true}
                                        showPageSizeOptions={true}
                                        className="-striped -highlight"
                                        style={{height: "100%", width: "100%"}}
                            />
                        </div>
                    </div>
                    <Modal open={this.state.surveyModal} center closeOnEsc={true} onClose={this.closePupilSurveyForm.bind(this)}>
                        <PupilSurveyView pupil={this.state.pupil} survey={this.state.survey}
                                         reloadList={this.getSurveyList.bind(this)} onClose={this.closePupilSurveyForm.bind(this)}/>
                    </Modal>
                </div>
                }
            </div>
        )
    }
}

export default SchoolPupilSurveyList;