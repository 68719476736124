import React, {Component} from 'react';
import Modal from "react-responsive-modal";
import {Link, NavLink} from "react-router-dom";
import ChangePasswordView from "../ChangePasswordView";
import {logout} from "../Utils";

class SchoolMenu extends Component {

  constructor() {
    super();
    this.state = {
      openModal: false
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.setState({
        user: user
    });
  }

  openModal() {
    this.setState({
      openModal: true
    })
  }

  closeModal() {
    this.setState({
      openModal: false
    })
  }

  render() {
    let user = JSON.parse(localStorage.getItem('userData'));
    return (
      <div>
				<ul className="header fixed-top">
					<li><NavLink to="/schoolHome">Bosh sahifa</NavLink></li>
					<li className="nav-item dropdown">
						<Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink-555" data-toggle="dropdown"
							 aria-haspopup="true" aria-expanded="false">O`quvchilar
						</Link>
						<div className="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-555">
							<NavLink className="dropdown-item" to="/spList">Ro`yxat</NavLink>
							<NavLink className="dropdown-item" to="/pupilsTest">Psixologik test</NavLink>
							{/*<a className="dropdown-item" href="/pupilsTest5">Test 5</a>*/}
							<NavLink className="dropdown-item" to="/testResults">Test natijasi</NavLink>
							<NavLink className="dropdown-item" to="/surveyList">9-sinf SO`ROVNOMA</NavLink>
						</div>
					</li>
					<li className="nav-item dropdown">
						<Link className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-666" data-toggle="dropdown"
							 aria-haspopup="true" aria-expanded="false">O`qituvchilar
						</Link>
						<div className="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-666">
							<NavLink className="dropdown-item" to="/teachersTest">Psixologik test</NavLink>
						</div>
					</li>
					<li><NavLink to="/help">Yordam</NavLink></li>
					<li className="nav-item dropdown float-right">
						<Link className="nav-link dropdown-toggle" to="#"id="navbarDropdownMenuLink-777" data-toggle="dropdown"
							 aria-haspopup="true" aria-expanded="false">Profil
						</Link>
						<div className="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-777">
							<Link className="dropdown-item" href="#" onClick={this.openModal.bind(this)}>Parolni o`zgartirish</Link>
							<Link className="dropdown-item" onClick={logout.bind(this)}>Chiqish</Link>
						</div>
					</li>
				</ul>
				<div className="padding-right text-right">
					<span>{user.stateName} {user.districtName} {user.schoolName}</span>
				</div>

        <Modal open={this.state.openModal} center closeOnEsc={true} onClose={this.closeModal.bind(this)}>
          <ChangePasswordView onClose={this.closeModal.bind(this)}/>
        </Modal>
      </div>
    )
  }
}

export default SchoolMenu;