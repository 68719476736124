import React, {Component} from 'react';
import '../App.css';
import SchoolMenu from "./SchoolMenu";
import {NavigateToLogin} from "../Utils";

class SchoolHomePage extends Component {

  render() {
		let user = JSON.parse(localStorage.getItem('userData'));
		if (user.role !== 'SCHOOL') {
			NavigateToLogin();
		}
    return (
      <div style={{width: "100%"}}>
        {user !== undefined && user !== null && user.role === 'SCHOOL' &&
        <SchoolMenu/>
        }
      </div>
    )
  }
}

export default SchoolHomePage;