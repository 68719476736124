import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import '../index.css';
import swal from 'sweetalert';

class BatchImportPupils extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groupList: []
    }
  }

  componentDidMount() {

  }

  batchImportPupils() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let schoolId = userData !== undefined && userData !== null ? userData.stateId : null;
    let klass = document.getElementById('klasses').value;
    let klassLetter = document.getElementById('klassLetters').value;
    let files = this.refs.uploadImg.files;
    let formData = new FormData();
    formData.append("schoolId", schoolId);
    formData.append("klass", klass);
    formData.append("klassLetter", klassLetter);
    formData.append("file", files[0]);
    axios.post(_.apiHost + '/pupil/batchImport', formData, {
      headers: {'Content-type': 'multipart/form-data'},
    }).then(response => {
      if (response.data.statusCode === 200) {
        swal("Ma'lumot saqlandi", '', 'success');
      } else if (response.data.statusCode === 500) {
        swal("Xatolik", '', "error");
      }
    });
  }

  render() {
    return (
      <div className="row" style={{padding: "20px"}}>
        <div className="col s12">
          <select id="klasses">
            <option value="11">11</option>
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <select id="klassLetters">
            <option value="">__</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="V">V</option>
            <option value="G">G</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="J">J</option>
            <option value="Z">Z</option>
            <option value="I">I</option>
            <option value="K">K</option>
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="N">N</option>
            <option value="O">O</option>
            <option value="P">P</option>
            <option value="Q">Q</option>
            <option value="R">R</option>
            <option value="S">S</option>
            <option value="T">T</option>
            <option value="U">U</option>
            <option value="F">F</option>
            <option value="X">X</option>
          </select>
        </div>
        <div className="col s12">
          <div style={{border: "1px solid #000000", padding: "10px", height: "115px"}}>
            <h5>Exceldan yuklash</h5>
            <input type="file" id="fileName" ref="uploadImg" onChange={this.onChange}
                   style={{float: "left"}}/>
            <button className="btn btn-block" onClick={this.batchImportPupils.bind(this)}
                    style={{float: "right"}}>
              Import qilish
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default BatchImportPupils;