import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import '../index.css';
import swal from 'sweetalert';
import {apiHost} from "../Utils";
import Swal from "sweetalert2";

export default class BatchImportTeachers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupNumber: '',
            groupType: 'MALAKA_OSHIRISH',
            credentials: ''
        }
    }

    componentDidMount() {
        axios.get(apiHost + '/teacher/secEdu/newGroupNumber').then(response => {
            if (response.data.statusCode === 200) {
                this.setState({
                    groupNumber: response.data.data
                })
            } else if (response.data.statusCode === 500) {
                Swal.fire("Xatolik", "Xatolik", "error");
            }
        })
    }

    setGroupType(e) {
        this.setState({
            groupType: e.target.value
        })
    }

    saveBatchTeacher() {
        let files = this.refs.uploadImg.files;
        let formData = new FormData();
        formData.append("groupNumber", this.state.groupNumber);
        formData.append("relatedTO", this.state.groupType);
        formData.append("file", files[0]);
        axios.post(_.apiHost + '/teacher/secEdu/listeners/import', formData, {
            headers: {'Content-type': 'multipart/form-data'},
        }).then(response => {
            if (response.data.statusCode === 200) {
                let parse = JSON.parse(response.data.data);
                let credentials = 'Login: ' + parse.userName + '     Parol: ' + parse.password;
                this.setState({
                    credentials: credentials
                })
                swal("Ma'lumot saqlandi", '', 'success');
            } else if (response.data.statusCode === 500) {
                swal("Xatolik", '', "error");
            }
        });
    }

    render() {
        return (
            <div className="container-md card card-content p-4 text-center">
                <div className="form-row">
                    <div className="col-md-6">
                        <h5>Guruh nomi</h5>
                    </div>
                    <div className="col-md-6">
                        <h5>{this.state.groupNumber !== '' ? this.state.groupNumber + '-guruh' : ''}</h5>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <h5>Guruh turi</h5>
                    </div>
                    <div className="col-md-6">
                        <select id="relatedTo" className="form-control" onChange={this.setGroupType.bind(this)}>
                            <option value="MALAKA_OSHIRISH">Malaka oshirish</option>
                            <option value="QAYTA_TAYYORLOV">Qayta tayyorlov</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <h5>MS Excel fayl</h5>
                    </div>
                    <div className="col-md-6">
                        <input type="file" id="fileName" ref="uploadImg" className="form-control"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <h5>Autentifikatsiya</h5>
                    </div>
                    <div className="col-md-6 text-left">
                        <h5>{this.state.credentials}</h5>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 text-center mt-3">
                        <button className="btn btn-info" onClick={this.saveBatchTeacher.bind(this)}>Import qilish
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}