import React, {Component} from 'react';
import axios from "axios";
import * as _ from './Utils';
import swal from 'sweetalert';
import SchoolMenu from "./school/SchoolMenu";
import {Navigate} from "react-router";

class PupilsTest11 extends Component {

	constructor(props) {
		super(props);
		let userData = JSON.parse(localStorage.getItem('userData'));
		this.state = {
			states: [],
			districts: [],
			schools: [],
			pupils: [],
			methods: [],
			userData: userData,
			toTest: false
		}
	}

	onChangeSchool() {
		let userData = JSON.parse(localStorage.getItem('userData'));
		let schoolId = userData.stateId;
		let classNumber = document.getElementById('klass').value;
		let classLetter = document.getElementById('klassLetter').value;
		axios.get(_.apiHost + '/pupil/list2?schoolId=' + schoolId + '&klass=' + classNumber + '&klassLetter=' + classLetter)
			.then(data => {
				if (data.data.statusCode === 200) {
					let pupilList = [];
					for (let i = 0; i < data.data.data.length; i++) {
						let pupil = data.data.data[i];
						pupilList.push(<option key={pupil.id}
						                       value={pupil.id}>{pupil.lastName} {pupil.firstName} {pupil.middleName}</option>)
					}
					this.setState({
						pupils: pupilList
					})
				} else {
					this.setState({
						pupils: []
					})
				}
			})
	}

	onChangePupil() {
		let pupilList = document.getElementById('pupilList');
		localStorage.setItem('pupil', {"id": pupilList.value, "name": pupilList.options[pupilList.selectedIndex].text});
	}

	startTest() {
		let pupilList = document.getElementById('pupilList');
		if (pupilList.value === null || pupilList.value === '' || pupilList.value === '0') {
			swal('O`quvchini tanlang', '', 'error');
			return;
		}
		let classNumber = document.getElementById('klass').value;
		let language = document.getElementById('language').value;
		localStorage.setItem('pupil', {"id": pupilList.value, "name": pupilList.options[pupilList.selectedIndex].text});
		localStorage.setItem('selectedTest', {"id": 2, "name": "Differensial-diagnostik so`rovnoma"});
		localStorage.setItem('language', language);
		axios.get(_.apiHost + '/test/list?methodId=2&language=' + language)
			.then(result => {
				if (result.data.statusCode === 200) {
					localStorage.setItem('klass', classNumber);
					localStorage.setItem('selectedTestQuestions', result.data.data);
					this.setState({
						toTest: true
					})
				}
			});
	}

	render() {
		if (this.state.toTest === true) {
			return <Navigate to='/testing'/>
		}
		return (
			<div className="content">
				<SchoolMenu/>
				{this.state.userData !== undefined && this.state.userData !== null &&
				<div className="container card card-body my-4 shadow-sm">
					<div className="form-row">
						<div className="col-md-6">
							<span>Sinfi:</span>
						</div>
						<div className="col-md-3">
							<select id="klass" className="form-control" onChange={this.onChangeSchool.bind(this)}>
								<option value="11">11</option>
								<option value="10">10</option>
								<option value="9">9</option>
								<option value="8">8</option>
								<option value="7">7</option>
							</select>
						</div>
						<div className="col-md-3">
							<select id="klassLetter" className="form-control" onChange={this.onChangeSchool.bind(this)}>
								<option value=""> sinf</option>
								<option value="A">A-sinf</option>
								<option value="B">B-sinf</option>
								<option value="V">V-sinf</option>
								<option value="G">G-sinf</option>
								<option value="D">D-sinf</option>
								<option value="E">E-sinf</option>
								<option value="J">J-sinf</option>
								<option value="Z">Z-sinf</option>
								<option value="I">I-sinf</option>
								<option value="K">K-sinf</option>
								<option value="L">L-sinf</option>
								<option value="M">M-sinf</option>
								<option value="N">N-sinf</option>
								<option value="O">O-sinf</option>
								<option value="P">P-sinf</option>
								<option value="Q">Q-sinf</option>
								<option value="R">R-sinf</option>
								<option value="S">S-sinf</option>
								<option value="T">T-sinf</option>
								<option value="U">U-sinf</option>
								<option value="F">F-sinf</option>
								<option value="X">X-sinf</option>
							</select>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-6">
							<span>O`quvchining F.I.Sh:</span>
						</div>
						<div className="col-md-6">
							<select id='pupilList' className="form-control" onChange={this.onChangePupil.bind(this)}>
								<option value="0">Tanlang</option>
								{this.state.pupils}
							</select>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-6">
							<span>Test tili:</span>
						</div>
						<div className="col-md-6">
							<select id='language' className="form-control">
								<option value="1">O`zbek</option>
								<option value="2">Rus</option>
							</select>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							&nbsp;
						</div>
						<div className="col-md-6">
							<button className="btn btn-success btn-block" style={{width: "inherit", marginTop: "20px"}}
							        onClick={this.startTest.bind(this)}>Testni boshlang
							</button>
						</div>
					</div>
				</div>
				}
			</div>
		)
	}
}

export default PupilsTest11;