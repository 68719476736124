import React, {Component} from 'react';
import axios from "axios";
import * as _ from './Utils';
import {Navigate} from "react-router";
import ReactPlayer from 'react-player';
import './App.css';

class CertificationTest extends Component {

	constructor(props) {
		super(props);
		this.state = {
			toTest: false
		}
	}

	startTest() {
		let language = document.getElementById('language').value;
		localStorage.setItem('pupil', {"id": 1, "name": document.getElementById('fullName').value});
		localStorage.setItem('selectedTest', {"id": 19, "name": "Attestatsiya sinov testi"});
		localStorage.setItem('language', language);
		axios.get(_.apiHost + '/test/list?methodId=19&language=' + language)
			.then(result => {
				if (result.data.statusCode === 200) {
					localStorage.setItem('selectedTestQuestions', result.data.data);
					this.setState({
						toTest: true
					})
				}
			});
	}

	render() {
		if (this.state.toTest === true) {
			return <Navigate to='/testing'/>
		}
		return (
			<div className="content">
				<div className="container card card-body my-4 shadow-sm">
					<div className="form-row">
						<div className="col-md-6">
							<span>Test topshiruvchining F.I.Sh:</span>
						</div>
						<div className="col-md-6">
							<input type="text" className="form-control" id="fullName"/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<span>Test tili:</span>
						</div>
						<div className="col-md-6">
							<select id='language' className="form-control">
								<option value="1">O`zbek</option>
								<option value="2">Rus</option>
							</select>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							&nbsp;
						</div>
						<div className="col-md-6">
							<button className="btn btn-success btn-block" onClick={this.startTest.bind(this)}>Testni boshlang</button>
						</div>
					</div>
				</div>
				<div className="container card card-body my-4 shadow-sm">
					<fieldset>
						<legend>Me'yoriy hujjatlar</legend>
						<div className="form-row">
							<div className="col-md-12" style={{display: "contents"}}>
								<div className="docBox"><a href="http://rppm.uz/documents/listDocuments.docx.pdf">Me'yoriy hujjatlar ro`yxati</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/pq3907.pdf">Prezident qarori №3907</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/pp3907.pdf">Постановление президента №3907</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/pq3931.pdf">Prezident qarori №3931</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/pp3931.pdf">Постановление президента №3931</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/pf4947.pdf">Prezident farmoni №4947</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/up4947.pdf">Указ президента №4947</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/pf5712.pdf">Prezident farmoni №5712</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/vm472.pdf">Vazirlar mahkamasi qarori №472</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/vm577.pdf">Vazirlar mahkamasi qarori №577</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/vm841.pdf">Vazirlar mahkamasi qarori №841</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/Talim_tugrisidagi_qonun_uz.pdf">Ta'lim to`g`risidagi qonun</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/Talim_tugrisidagi_qonun_ru.pdf">Закон Республики Узбекистан об образовании</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/Kadrlar_tayyorlash_uz.pdf">Kadrlar tayyorlash milliy dasturi</a></div>
								<div className="docBox"><a href="http://rppm.uz/documents/Kadrlar_tayyorlash_ru.pdf">Закон о национальной программе по подготовке кадров</a></div>
							</div>
						</div>
					</fieldset>
				</div>
				<div className="container card card-body my-4 shadow-sm">
					<fieldset>
						<legend>Video darslar</legend>
						<div className="form-row">
							<div className="col-md-12 videoFiles center">
								<ReactPlayer className='react-player' width="300px" height="300px"
								             url={_.apiHost + '/videoCourses/1-videodars.mp4'} controls/>
								<ReactPlayer className='react-player' width="300px" height="300px"
								             url={_.apiHost + '/videoCourses/2-videodars.mp4'} controls/>
								<ReactPlayer className='react-player' width="300px" height="300px"
								             url={_.apiHost + '/videoCourses/3-videodars.mp4'} controls/>
								<ReactPlayer className='react-player' width="300px" height="300px"
								             url={_.apiHost + '/videoCourses/4-videodars.mp4'} controls/>
								<ReactPlayer className='react-player' width="300px" height="300px"
								             url={_.apiHost + '/videoCourses/5-videodars.mp4'} controls/>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		)
	}
}

export default CertificationTest;