import React, {Component} from 'react';
import {strings, apiHost} from "./Utils";
import TestItem from "./TestItem";
import swal from 'sweetalert';
import axios from 'axios';

class Testing5 extends Component {

  constructor() {
    super();
    this.state = {
      questionNumber: 0,
      selectedTest: JSON.parse(localStorage.getItem('selectedTest')),
      tests: JSON.parse(localStorage.getItem('selectedTestQuestions')),
      testAnswers: [],
      pupilAnswers: [],
      pupil: JSON.parse(localStorage.getItem('pupil')),
      question: ''
    }
  }

  componentDidMount() {
    let pupilAnswers = [];
    let selectedTest = this.state.tests;
    for (let i=0; i<selectedTest.length; i++) {
      pupilAnswers[i] = '';
    }
    this.setState({
      pupilAnswers: pupilAnswers,
      question: selectedTest[0].question,
      testAnswers: selectedTest[0].answers
    });
  }

  toNextQuestion(answerId) {
    let q_number = this.state.questionNumber < this.state.tests.length - 1 ? this.state.questionNumber + 1 : 0;
    let selectedTest = this.state.tests[q_number];
    let pupilAnswers = [...this.state.pupilAnswers];
    pupilAnswers[this.state.questionNumber] = answerId;
    let unAnswered = 0;
    for(let i=0; i<pupilAnswers.length; i++) {
      if (pupilAnswers[i] === '') {
        unAnswered = unAnswered + 1;
      }
    }
    this.setState({
      questionNumber: q_number,
      question: selectedTest.question,
      testAnswers: selectedTest.answers,
      pupilAnswers: pupilAnswers,
      fullAnswered: unAnswered === 0
    });
    this.setState({
      answerId: answerId
    })
  }

  onFinishClick() {
	  let pupilAnswers = this.state.pupilAnswers;
	  let unAnswered = '';
	  for(let i=0; i<pupilAnswers.length; i++) {
      unAnswered = unAnswered + (unAnswered.length > 0 ? '_' : '') + pupilAnswers[i];
	  }
	  console.log(unAnswered);
	  axios.get(apiHost + '/test/saveResult?pupilId=' + this.state.pupil.id + '&testMethodId=' + this.state.selectedTest.id + '&testResult=' + unAnswered)
		  .then(result => {
			  if (result.data.statusCode === 200) {
				  swal(result.data.message, '', 'success');
			  }
		  });
  }

  render() {
    return (
      <div style={{width: '95%', padding: '20px'}}>
        <div style={{width: '50%', float: 'left'}}><p>{strings.pupil}: {this.state.pupil.name}</p></div>
        <div style={{width: '50%', float: 'right'}}><p>{strings.method}: {this.state.selectedTest.name}</p></div>
        <hr style={{marginTop: '50px'}}/>
        <div style={{width: '100%'}}>
          <TestItem questionOrder={this.state.questionNumber + 1} testId={+this.state.selectedTest.id}
                    question={this.state.question} answers={this.state.testAnswers} toNextQuestion={this.toNextQuestion.bind(this)}/>

        </div>

        {this.state.fullAnswered &&
        <div style={{width: '100%', padding: '20px'}}>
          <button id='testFinishBtn' className="btn btn-block" onClick={this.onFinishClick.bind(this)}>{strings.finish}</button>
        </div>
        }
      </div>
    )
  }
}

export default Testing5;