import React, {Component} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as _ from './../Utils';
import AdminMenu from "./AdminMenu";

class AddSchoolView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			districts: [],
			schools: [],
			schoolName: '',
			userName: '',
			password: '',
		};
	}

	onChangeStates() {
		let stateId = document.getElementById('states').value;
		axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let districts = data.data.data.map(user=> {
						return <option key={user.id} value={user.id}>{user.name}</option>
					});
					this.setState({
						districts: districts
					})
				} else {
					this.setState({
						districts: []
					})
				}
			})
	}

	onChangeDistrict() {
		let districtId = document.getElementById('districts').value;
		axios.get(_.apiHost + '/pupil/school/list?districtId=' + districtId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let schoolList = [];
					for (let i = 0; i < data.data.data.length; i++) {
						let school = data.data.data[i];
						schoolList.push(<option key={school.id} value={school.id}>{school.name}</option>)
					}
					this.setState({
						schools: schoolList
					})
				} else {
					this.setState({
						schools: []
					})
				}
			});
		axios.get(_.apiHost + '/school/generateLoginPass?districtId=' + districtId)
			.then(data => {
				if (data.data.statusCode === 200) {
					this.setState({
						userName: data.data.data.userName,
						password: data.data.data.password
					})
				}
			});
	}

	saveSchool() {
		let errors = 0;
		let districtId = document.getElementById('districts').value;
		let schoolName = document.getElementById('schoolName').value;
		errors = _.validateSelect('districts', errors);
		errors = _.validateField('schoolName', schoolName, errors);
		if (errors === 0) {
			axios.post(_.apiHost + '/school/add?districtId=' + districtId + '&name=' + schoolName + '&userName=' + this.state.userName + '&password=' + this.state.password)
				.then(result => {
					if (result.data.statusCode === 200) {
						Swal.fire({
							title: 'Natija',
							text: result.data.message,
							type: 'info',
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'OK'
						});
					} else {
						Swal.fire({
							title: 'Natija',
							text: result.data.message,
							type: 'error',
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'OK'
						});
					}
				});
		}
	}

	render() {
		return (
			<div>
				<AdminMenu/>
				<div className="container card card-body my-4 shadow-sm mt-5">
					<div className="form-group">
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="region">Hudud</label>
							</div>
							<div className="col-md-9">
								<select className="form-control" id="states" onChange={this.onChangeStates.bind(this)}>
									<option value="1">Qoraqalpog`iston Respublikasi</option>
									<option value="2">Andijon viloyati</option>
									<option value="3">Buxoro viloyati</option>
									<option value="4">Qashqadaryo viloyati</option>
									<option value="5">Jizzax viloyati</option>
									<option value="6">Navoiy viloyati</option>
									<option value="7">Namangan viloyati</option>
									<option value="8">Samarqand viloyati</option>
									<option value="9">Sirdaryo viloyati</option>
									<option value="10">Surxondaryo viloyati</option>
									<option value="11">Toshkent viloyati</option>
									<option value="12">Farg`ona viloyati</option>
									<option value="13">Xorazm viloyati</option>
									<option value="14">Toshkent shahri</option>
								</select>
							</div>
						</div>
					</div>
					<div className="form-group">
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="districts">Tuman</label>
							</div>
							<div className="col-md-9">
								<select className="form-control" id="districts" onChange={this.onChangeDistrict.bind(this)}>
									{this.state.districts}
								</select>
							</div>
						</div>
					</div>
					<div className="form-group">
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="schoolName">Maktab nomi</label>
							</div>
							<div className="col-md-9">
								<input className="form-control" id="schoolName" defaultValue={this.state.schoolName} />
							</div>
						</div>
					</div>
					<div className="form-group">
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="userName">Login</label>
							</div>
							<div className="col-md-3">
								<span className="form-control" id="userName">{this.state.userName}</span>
							</div>
							<div className="col-md-3">
								<label htmlFor="password">Parol</label>
							</div>
							<div className="col-md-3">
								<span className="form-control" id="password">{this.state.password}</span>
							</div>
						</div>
					</div>
					<div className="form-group">
						<div className="form-row">
							<div className="col-md-12 text-center">
								<button type="button" className="btn btn-primary" onClick={this.saveSchool.bind(this)}>Saqlash</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AddSchoolView;