import React, {Component} from 'react';
import './App.css';
import axios from "axios";
import * as _ from './Utils';
import AdminMenu from "./admin/AdminMenu";
import TashxisMenu from "./admin/TashxisMenu";

class ReTrainingTestStartPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      districts: [],
      teachers: [],
      groups: []
    }
  }

  componentDidMount() {
    let groupList = [];
    for (let i = 1; i <= 50; i++) {
      groupList.push(<option key={i} value={i}>{i + '-guruh'}</option>)
    }
    this.setState({
      groups: groupList
    })
  }

  onChangeStates() {
    let stateId = document.getElementById('states').value;
    axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
      .then(data => {
        if (data.data.statusCode === 200) {
          let districtList = [];
          for (let i = 0; i < data.data.data.length; i++) {
            let user = data.data.data[i];
            districtList.push(<option key={user.id} value={user.id}>{user.name}</option>)
          }
          this.setState({
            districts: districtList
          })
        } else {
          this.setState({
            districts: []
          })
        }
      })
  }

  onChangeDistrict() {
    let districtId = document.getElementById('districts').value;
    axios.get(_.apiHost + '/teacher/listByGroup?groupId=' + districtId)
      .then(data => {
        if (data.data.statusCode === 200) {
          let teacherList = [];
          for (let i = 0; i < data.data.data.length; i++) {
            let pupil = data.data.data[i];
            teacherList.push(<option key={pupil.id}
                                     value={pupil.id}>{pupil.fullName}</option>)
          }
          this.setState({
            teachers: teacherList
          })
        } else {
          this.setState({
            teachers: []
          })
        }
      })
  }

  startTest() {
    let teachers = document.getElementById('teachers');
    localStorage.setItem('teacher', {"id": teachers.value, "name": teachers.options[teachers.selectedIndex].text});
    window.open('/rtTest', '_self');
  }

  render() {
    return (
      <div className="content" style={{width: "100%"}}>
        {_.isAdmin() &&
        <AdminMenu/>
        }
        {_.isTashxis() &&
        <TashxisMenu/>
        }
        <div className="flt1 rp_inner">
          <div className="row">
            <div className="col s6">
              <span>Guruh:</span>
            </div>
            <div className="col s6">
              <select id='districts' onChange={this.onChangeDistrict.bind(this)}>
                {this.state.groups}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <span>O`qituvchi:</span>
            </div>
            <div className="col s6">
              <select id="teachers">
                <option value="0">Tanlang</option>
                {this.state.teachers}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <span></span>
            </div>
            <div className="col s6">
              <button className="btn btn-block" onClick={this.startTest.bind(this)}>Boshlash</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ReTrainingTestStartPage;