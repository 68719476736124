import React, {Component} from 'react';
import {Link, NavLink} from "react-router-dom";
import {logout} from "../Utils";

class AdminMenu extends Component {

    logout() {
        localStorage.removeItem('userData');
        window.open('/', '_self');
    }

    render() {
        return (
            <ul className="header fixed-top">
                <li><NavLink to="/adminHome">Bosh sahifa</NavLink></li>
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-555"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">O`qituvchilar</Link>
                    <div className="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-555">
                        <NavLink className="dropdown-item" to="/addTeacher">Kiritish</NavLink>
                        <NavLink className="dropdown-item" to="/batchImport">Import qilish</NavLink>
                        <NavLink className="dropdown-item" to="/attTestResult">Qayta tayyorlov natijalari</NavLink>
                        <NavLink className="dropdown-item" to="/psychTestResult">Psixologik test natijalari</NavLink>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-555"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Hisobotlar</Link>
                    <div className="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-555">
                        <NavLink className="dropdown-item" to="/report5">O`quvchi soni</NavLink>
                        <NavLink className="dropdown-item" to="/report1">DDS hisoboti</NavLink>
                        <NavLink className="dropdown-item" to="/report2">QX hisoboti</NavLink>
                        <NavLink className="dropdown-item" to="/report3">IQAM hisoboti</NavLink>
                        <NavLink className="dropdown-item" to="/report4">Temperament hisoboti</NavLink>
                    </div>
                </li>
                <li><NavLink to="/psychList">Psixologlar</NavLink></li>
                <li><NavLink to="/addSchool">Maktab qo`shish</NavLink></li>
                <li className="nav-item dropdown float-right app-link">
                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-777" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">Administrator</span>
                    <div className="dropdown-menu dropdown-secondary -cursor-pointer">
                        <span className="dropdown-item app-link" onClick={logout.bind(this)}>Chiqish</span>
                    </div>
                </li>
            </ul>
        )
    }
}

export default AdminMenu;