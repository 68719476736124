import React, {Component} from 'react';
import {Link, NavLink} from "react-router-dom";
import Modal from "react-responsive-modal";
import ChangePasswordView from "../ChangePasswordView";
import {logout} from "../Utils";

class DistrictMenu extends Component {

  constructor() {
    super();
    this.state = {
      openModal: false
    }
  }

  openModal() {
    this.setState({
      openModal: true
    })
  }

  closeModal() {
    this.setState({
      openModal: false
    })
  }

  render() {
    let user = JSON.parse(localStorage.getItem('userData'));
    return (
      <div>
        <ul className="header fixed-top">
          <li><NavLink to="/districtHome">Bosh sahifa</NavLink></li>
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-555" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">Hisobotlar
            </Link>
            <div className="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-555">
              <NavLink className="dropdown-item" to="/report5">O`quvchi soni</NavLink>
              <NavLink className="dropdown-item" to="/report1">DDS hisoboti</NavLink>
              <NavLink className="dropdown-item" to="/report2">QX hisoboti</NavLink>
              <NavLink className="dropdown-item" to="/report3">IQAM hisoboti</NavLink>
            </div>
          </li>
          <li><NavLink to="/help2">Yordam</NavLink></li>
          <li className="nav-item dropdown float-right">
            <Link className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-777" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">{user.districtName}
            </Link>
            <div className="dropdown-menu dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-777">
              <Link className="dropdown-item" href="#" onClick={this.openModal.bind(this)}>Parolni o`zgartirish</Link>
              <Link className="dropdown-item" onClick={logout.bind(this)}>Chiqish</Link>
            </div>
          </li>
        </ul>

        <Modal open={this.state.openModal} center closeOnEsc={true} onClose={this.closeModal.bind(this)}>
          <ChangePasswordView onClose={this.closeModal.bind(this)}/>
        </Modal>
      </div>
    )
  }
}

export default DistrictMenu;