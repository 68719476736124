import React, {Component} from 'react';
import './App.css';

class ReTrainingTestResult extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ball1: 0,
      ball2: 0,
      ball3: 0
    }
  }

  componentDidMount() {
    let split = this.props.testResult.split('|');
    if (split.length === 3) {
      this.setState({
        ball1: split[0],
        ball2: split[1],
        ball3: split[2]
      })
    }
  }

  closeResultPopup() {
    this.props.closeResultPopup();
  }

  render() {
    return (
      <div className="p-3">
        <h5 style={{padding: "10px 0"}}>{this.props.teacherName}ning yakuniy test natijalari:</h5>
        <table id="testResultTable">
          <tbody>
          <tr>
            <td>
              <span>1-blok bo`yicha</span><br/><span>(maksimal 100 ball)</span>
            </td>
            <td>
              <span>2-blok bo`yicha</span><br/><span>(maksimal 100 ball)</span>
            </td>
            <td>
              <span>3-blok bo`yicha</span><br/><span>(maksimal 100 ball)</span>
            </td>
          </tr>
          <tr>
            <td className="text-center font-weight-bold">
              {this.state.ball1}
            </td>
            <td className="text-center font-weight-bold">
              {this.state.ball2}
            </td>
            <td className="text-center font-weight-bold">
              {this.state.ball3}
            </td>
          </tr>
          </tbody>
        </table>
        <div className="form-row">
          <div className="col-md-12 text-center mt-2">
            <button className="btn btn-success" onClick={this.closeResultPopup.bind(this)}>OK</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ReTrainingTestResult;