import React, {Component} from 'react';
import '../App.css';
import axios from "axios";
import * as _ from '../Utils';
import ReactTable from 'react-table';
import AdminMenu from "./AdminMenu";

class AdminTeacherList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      teachers: [],
      showAddButton: props.showAddButton !== undefined && props.showAddButton !== null ? props.showAddButton : true
    }
  }

  componentDidMount() {
    axios.get(_.apiHost + '/teacher/list1')
      .then(result => {
        if (result.data.statusCode === 200) {
          this.setState({
            teachers: result.data.data !== undefined ? result.data.data : []
          })
        }
      })
  }

  openAddTeacherForm() {
    window.open('/addTeacher', '_self');
  }

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div>
        <div id='__react-content' className="flt topblock">
          <AdminMenu/>
        </div>
        {userData !== undefined && userData !== null &&
        <table>
          <tbody>
          {this.state.showAddButton &&
          <tr>
            <td className="right">
              <button className="btn btn-block" style={{width: "initial"}} onClick={this.openAddTeacherForm.bind(this)}>
                O`qituvchi qo`shish
              </button>
            </td>
          </tr>
          }
          <tr>
            <td>
              <ReactTable
                data={this.state.teachers}
                columns={[{
                  columns: [{
                    Header: _.strings.teacherName,
                    accessor: 'fullName'
                  }, {
                    Header: _.strings.districtName,
                    accessor: 'districtName'
                  }, {
                    Header: _.strings.schoolName,
                    accessor: 'schoolName'
                  }]
                }]}
                noDataText={_.strings.noDataText}
                previousText={_.strings.previousText}
                nextText={_.strings.nextText}
                pageText={_.strings.pageText}
                ofText={_.strings.ofText}
                rowsText={_.strings.rowsText}
                defaultPageSize={10}
                resizable={true}
                showPageSizeOptions={true}
                className="-striped -highlight"
                style={{width: "1000px"}}
              />
            </td>
          </tr>
          </tbody>
        </table>
        }
        {(userData === undefined || userData === null) &&
        <span>Tizimga kirish uchun login varolni to`g`ri tering</span>
        }
      </div>
    )
  }
}

export default AdminTeacherList;