import React, {Component} from 'react';

class TashxisMenu extends Component {

  logout() {
    localStorage.removeItem('userData');
    window.open('/', '_self');
  }

  openAddTeacherForm() {
    window.open('/addTeacher', '_self');
  }

  render() {
    return (
      <nav className="menu">
        <ol>
          <li className="menu-item"><a href="/tashxisHome">Bosh sahifa</a></li>
          <li className="menu-item">
            <span className="app-link">O`qituvchilar</span>
            <ol className="sub-menu">
              <li className="menu-item"><a href="/skillTestStart">Malaka oshirish</a></li>
              <li className="menu-item"><a href="/attTestStart">Qayta tayyorlov</a></li>
            </ol>
          </li>
          <li className="menu-item"><a href="/aHelp">Yordam</a></li>
          <li className="menu-item">
            <button className="menuButton" onClick={this.logout.bind(this)}>CHIQISH</button>
          </li>
        </ol>
      </nav>
    )
  }
}

export default TashxisMenu;