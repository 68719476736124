import React, {Component} from 'react';
import {strings} from "./Utils";

class TestWidget extends Component {

    onAnswerClick(answerId) {
        let rb = document.getElementById('rb' + answerId);
        rb.checked = true;
        this.props.toNextQuestion(answerId);
    }

    render() {
        let answerList = [];
        this.props.answers.forEach(answer => {
            answerList.push(
                <div id={'answer' + answer.id} key={answer.id}>
                    <label>
                        <input id={'rb' + answer.id} type="radio" name="answerRB" value={answer.answer}
                               onClick={this.onAnswerClick.bind(this, answer.id)}/>
                        <span className="ml-2">{answer.answer}</span>
                    </label>
                </div>
            )
        });

        return (
            <div>
                <p><b>{strings.question + ' №: ' + this.props.questionOrder + '.  ' + this.props.question}</b></p>
                <div style={{paddingTop: "20px"}}>
                    {answerList}
                </div>
            </div>
        )
    }
}

export default TestWidget;