import React, {Component} from 'react';
import '../App.css';
import axios from "axios";
import * as _ from '../Utils';
import RegionMenu from "../region/RegionMenu";
import AdminMenu from "../admin/AdminMenu";
import DistrictMenu from "../district/DistrictMenu";

class RegionPupilReport4View extends Component {

	constructor() {
		super();
		let userData = JSON.parse(localStorage.getItem('userData'));
		this.state = {
			reportRows: [],
			userData: userData
		};
		this.fetchReportData = this.fetchReportData.bind(this);
	}

	componentDidMount() {
		this.fetchReportData();
	}

	fetchReportData() {
		let userData = JSON.parse(localStorage.getItem('userData'));
		let regionId = userData !== undefined && userData !== null ? userData.stateId : null;
		let klass = document.getElementById('klasses').value;
		axios.get(_.apiHost + '/report/testReport4?relatedTO=' + userData.role + '&relatedId=' + regionId + '&klass=' + klass)
			.then(result => {
				if (result.data.statusCode === 200) {
					let options = [];
					for (let i = 0; i < result.data.data.length; i++) {
						let user = result.data.data[i];
						options.push(<tr key={user.schoolId}>
							<td>{user.schoolId}</td>
							<td>{user.schoolName}</td>
							<td className="text-center">{user.pupilCount}</td>
							<td className="text-center">{user.boysCount}</td>
							<td className="text-center">{user.girlsCount}</td>
							<td className="text-center">{user.participatesCount}</td>
							<td className="text-center">{user.nonParticipatesCount}</td>
							<td className="text-center">{user.result1Count}</td>
							<td className="text-center">{user.result2Count}</td>
							<td className="text-center">{user.result3Count}</td>
							<td className="text-center">{user.result4Count}</td>
						</tr>)
					}
					this.setState({
						reportRows: options
					})
				}
			})
	}

	render() {
		return (
			<div className="content">
				{_.isAdmin() &&
				<AdminMenu/>
				}
				{_.isRegion() &&
				<RegionMenu/>
				}
				{_.isDistrict() &&
				<DistrictMenu/>
				}
				{this.state.userData !== undefined && this.state.userData !== null &&
				<div className="card-body my-4 shadow-sm">
					<div className="form-row form-group">
						<div className="col-md-2">
							<select id="klasses" className="form-control" onChange={this.fetchReportData}>
								<option value="11">5-sinf</option>
							</select>
						</div>
					</div>
					<div className="form-row form-group">
						<div className="col-md-12 text-center">
							<p>{_.isAdmin() ? 'Respublikadagi ' : _.isRegion() ? this.state.userData.stateName + ' ' : _.isDistrict() ? this.state.userData.stateName + " " + this.state.userData.districtName : ''}
								umumiy o'rta ta'lim muassasalarini 5-sinf o'quvchilarida o'tkazilgan "Temperamentni aniqlash" testi natijalari to'g'risida ma'lumot</p>
						</div>
					</div>
					<div className="form-row form-group">
						<div className="col-md-12">
							<table className="table table-striped table-bordered">
								<thead>
								<tr>
									<th rowSpan="2">№</th>
									<th rowSpan="2">{_.isAdmin() ? 'Hudud nomi' : _.isRegion() ? 'Tuman nomi' : _.isDistrict() ? 'Maktab nomi' : ''}</th>
									<th rowSpan="2">Jami 5-sinf o`quvchilari soni</th>
									<th colSpan="2">Shundan</th>
									<th colSpan="2">Testda</th>
									<th colSpan="4">Temperamentni aniqlash testi bo'yicha</th>
								</tr>
								<tr>
									<th className="w80px">o`g`il</th>
									<th className="w80px">qiz</th>
									<th>qatnashdi</th>
									<th>qatnashmadi</th>
									<th>“Xоlerik” tipiga mansub</th>
									<th>“Flegmatik” tipiga mansub</th>
									<th>“Sangvinik” tipiga mansub</th>
									<th>“Melanxоlik” tipiga mansub</th>
								</tr>
								</thead>
								<tbody>
								{this.state.reportRows}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				}
			</div>
		)
	}
}

export default RegionPupilReport4View;