import React, {Component} from 'react';
import Header from "./Header";
import {Navigate} from "react-router";
import swal from 'sweetalert';
import * as _ from './Utils';
import axios from 'axios';

class ShortTimeCoursesPage extends Component {

    constructor() {
        super();
        let teacher = JSON.parse(localStorage.getItem('teacher'));
        this.state = {
            teacher: teacher,
            isNavigate: false,
            isNavigateToLogin: false,
            showPaymentModal: false,
            showAcceptCodeWidget: false,
            cardNumber: '',
            cardExpireDate: '',
            humo: true,
            uzCard: false,
            phoneNumber: '',
            approveCode: '',
            paymentSucceeded: true,
            currentTime: new Date().getTime()
        }
    }

    openPage(courseID, courseName) {
        localStorage.setItem('courseID', courseID);
        localStorage.setItem('courseName', courseName);
        window.open('/courseView', '_self');
    }

    downloadCertificate(courseId) {
        if (this.state.teacher !== undefined && this.state.teacher !== null && this.state.teacher.id !== undefined && this.state.teacher.id !== null) {
            window.open(_.apiHost + '/files/certificates/' + this.state.teacher.id + "_" + courseId + '.jpg', '_blank ');
        }
    }

    showHideModal(value) {
        this.setState({
            showPaymentModal: value
        })
    }

    onValueChange(e) {
        let value = (e.target.id === 'humo' || e.target.id === 'uzCard') ? e.target.checked : e.target.value.trim();
        if (e.target.id === 'humo') {
            this.setState({
                uzCard: false,
                humo:true
            });
        } else if(e.target.id === 'uzCard') {
            this.setState({
                uzCard: true,
                humo:false
            });
        } else {
            this.setState({
                [e.target.id]: value
            });
        }
    }

    payment() {
        let currentTime = this.state.paymentSucceeded ? new Date().getTime() : this.state.currentTime;
        if (this.state.cardNumber.length === 16 && ((this.state.cardExpireDate.length === 5 && this.state.cardExpireDate.includes('/')) || (this.state.cardExpireDate.length === 4 && !this.state.cardExpireDate.includes('/')))) {
            this.setState({
                showAcceptCodeWidget: true
            });
            if (this.state.teacher !== undefined && this.state.teacher !== null && this.state.teacher.id !== undefined && this.state.teacher.id !== null) {
                let saveBtn = document.getElementById('saveButton');
                saveBtn.disabled = true;

                let courseId = document.getElementById('course').value;
                axios.post(_.apiHost + '/teacher/payment?teacherId=' + this.state.teacher.id + '&cardNumber=' + this.state.cardNumber +
                    '&cardExpireDate=' + this.state.cardExpireDate + '&courseId=' + courseId + '&phoneNumber=' + this.state.phoneNumber +
                    '&currentTime=' + currentTime + '&approveCode=' + this.state.approveCode)
                    .then(result=>{
                        this.setState({
                            paymentSucceeded: false
                        });
                        if (result.data.statusCode === 200) {
                            let teacher = this.state.teacher;
                            if (Number(courseId) === 4) {
                                teacher.status4 = "598b2f707befaa5502f2aa571355b8a8";
                            } else if (Number(courseId) === 5) {
                                teacher.status5 = "598b2f505befaa5502f2aa571355b5a5";
                            } else if (Number(courseId) === 6) {
                                teacher.status6 = "598b2f606befaa5502f2aa571355b6a6";
                            }
                            this.setState({
                                teacher: teacher
                            });
                            localStorage.setItem('teacher', teacher);
                            swal(result.data.message, '', 'success');
                            window.location.href = 'http://rppm.uz/files/#/shortCourses';
                        } else if (result.data.statusCode === 250) {
                            this.setState({
                                currentTime: result.data.data.name
                            });
                            saveBtn.disabled = false;
                            swal(result.data.message, '', 'warning');
                        } else {
                            saveBtn.disabled = false;
                            swal(result.data.message, '', 'error');
                        }
                    })
            }
        }
    }

    startTest(methodId) {
        let teacher = JSON.parse(localStorage.getItem('teacher'));
        if (teacher !== undefined && teacher !== null && Number(teacher.id) > 0) {
            localStorage.setItem('methodId', methodId);
            window.open('/moTest2', '_self');
        }
    }

    logout() {
        localStorage.removeItem('teacher');
        localStorage.removeItem('methodId');
        window.open('/startCourse', '_self');
    }

    render() {
        console.log(this.state.teacher);
        if (this.state.teacher === undefined || this.state.teacher === null) {
            this.setState({
                isNavigateToLogin: true
            })
        }
        if (this.state.isNavigate) {
            return <Navigate to='/courseView'/>
        }
        if (this.state.isNavigateToLogin) {
            return <Navigate to='/startCourse'/>
        }
        return (
            <div>
                <Header/>
                <div className="container">
                    <div className="form-row">
                        <div className="col-md-12 text-right d-flex p-0">
                            <div className="w-100">
                                <span><b>Tinglovchi: </b>{this.state.teacher !== null ? this.state.teacher.lastName + ' ' + this.state.teacher.firstName + ' ' + this.state.teacher.middleName : ''}</span>
                                <button className="btn btn-info ml-2" onClick={this.logout.bind(this)}><i className="fa fa-sign-out"/></button>
                            </div>
                        </div>
                    </div>
                    <div className="form-row mt-2">
                        <div className="col-md-12 p-3 text-center method-name">
                            <h4>Ijtimoiy-psixologik xizmatda reabilitatsiya masalalari yoʻnalishi</h4>
                            <button className="btn btn-info" onClick={this.startTest.bind(this, 49)}>Testni boshlash</button>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 p-3 text-center method-name">
                            <h4>“Oʻqituvchilarda kasbiy stress va emotsional soʻnish holatlari proﬁlaktikasi” yoʻnalishi</h4>
                            <button className="btn btn-info" onClick={this.startTest.bind(this, 50)}>Testni boshlash</button>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 p-3 text-center method-name">
                            <h4>“Koʻrish, eshitish, tushunish” yoʻnalishi</h4>
                            <button className="btn btn-info" onClick={this.startTest.bind(this, 51)}>Testni boshlash</button>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 p-3 text-center method-name">
                            <h4>“Men oʻzim” yoʻnalishi</h4>
                            <button className="btn btn-info" onClick={this.startTest.bind(this, 52)}>Testni boshlash</button>
                        </div>
                    </div>
                    <div className="form-row mt-5">
                        <ul style={{listStyleType: "disc"}}>
                            <li><p>1 - Mavzu. Sotsial psixologik xizmat tizimida reabilitatsiya masalalari</p>
                                <ul style={{listStyleType: "disc"}}>
                                    <li><a href="http://rppm.uz/files/uz/1.1.docx" className="lesson">1.1 Sotsial psixologik
                                        xizmat tizimida reabilitatsiya masalalari.</a></li>
                                    <li><a href="http://rppm.uz/files/uz/1.2.pptx" className="lesson">1.2 Sotsial psixologik
                                        xizmat tizimida reabilitatsiya masalalari taqdimoti.</a></li>
                                </ul>
                            </li>
                        </ul>
                        <ul style={{listStyleType: "disc"}}>
                            <li><p>2 - Mavzu. Ekstremal vaziyatlarga tushib qolgan insonlar bilan olib boriladigan psixologik chora tadbirlar, stress buzilishlari</p>
                                <ul style={{listStyleType: "disc"}}>
                                    <li><a href="http://rppm.uz/files/uz/2.1.docx" className="lesson">2.1 Ekstremal
                                        vaziyatlarga tushib qolgan insonlar bilan olib boriladigan.</a></li>
                                    <li><a href="http://rppm.uz/files/uz/2.2.pptx" className="lesson">2.2 Ekstremal
                                        vaziyatlarga tushib qolgan insonlar bilan olib boriladigan taqdimoti.</a></li>
                                </ul>
                            </li>
                        </ul>
                        <ul style={{listStyleType: "disc"}}>
                            <li><p>3 - Mavzu. Zoʻrlikni boshidan oʻtqazgan bolalarga psixologik yordam tamoyillari</p>
                                <ul style={{listStyleType: "disc"}}>
                                    <li><a href="http://rppm.uz/files/rus/3.1.docx" className="lesson">3.1 Zoʻrlikni boshidan
                                        oʻtqazgan bolalarga psixologik yordam tamoyillari.</a></li>
                                    <li><a href="http://rppm.uz/files/rus/3.2.pptx" className="lesson">3.2 Zoʻrlikni boshidan
                                        oʻtqazgan bolalarga psixologik yordam tamoyillari taqdimoti.</a></li>
                                </ul>
                            </li>
                        </ul>
                        <ul style={{listStyleType: "disc"}}>
                            <li><p>4 - Mavzu. Suitsidal harakatlarni sodir etgan bolaga yordam berish tamoyili</p>
                                <ul style={{listStyleType: "disc"}}>
                                    <li><a href="http://rppm.uz/files/rus/4.1.docx" className="lesson">4.1 Suitsidal
                                        harakatlarni sodir etgan bolaga yordam berish tamoyili</a></li>
                                    <li><a href="http://rppm.uz/files/rus/4.2.pptx" className="lesson">4.2 Suitsidal
                                        harakatlarni sodir etgan bolaga yordam berish tamoyili taqdimoti</a></li>
                                </ul>
                            </li>
                        </ul>
                        <ul style={{listStyleType: "disc"}}>
                            <li><p>5 - Mavzu. Yoʻqotish sindromini boshdan kechirgan bolalar bilan ijtimoiy psixologik ishlash </p>
                                <ul style={{listStyleType: "disc"}}>
                                    <li><a href="http://rppm.uz/files/rus/5.1.docx" className="lesson">5.1 Yoʻqotish
                                        sindromini boshdan kechirgan bolalar bilan ijtimoiy.</a></li>
                                    <li><a href="http://rppm.uz/files/rus/5.2.pptx" className="lesson">5.1 Yoʻqotish
                                        sindromini boshdan kechirgan bolalar bilan ijtimoiy taqdimoti.</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortTimeCoursesPage;