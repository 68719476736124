import React, {Component} from 'react';
import axios from "axios";
import * as _ from './Utils';
import SchoolMenu from "./school/SchoolMenu";

class PupilsTest5 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      states: [],
      districts: [],
      schools: [],
      pupils: [],
      methods: [],
    }
  }

  componentDidMount() {
    let classNumber = document.getElementById('klass').value;
    axios.get(_.apiHost + '/test/byClasses?klass=' + classNumber)
      .then(data => {
        if (data.data.statusCode === 200) {
          let methodList = [];
          if (data.data.data !== null) {
            for (let i = 0; i < data.data.data.length; i++) {
              let pupil = data.data.data[i];
              methodList.push(<option key={pupil.id} value={pupil.id}>{pupil.name}</option>)
            }
          }
          this.setState({
            methods: methodList
          })
        } else {
          this.setState({
            methods: []
          })
        }
      })
  }

  onChangeSchool() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let schoolId = userData.stateId;
    let classNumber = document.getElementById('klass').value;
    let classLetter = document.getElementById('klassLetter').value;
    axios.get(_.apiHost + '/pupil/list2?schoolId=' + schoolId + '&klass=' + classNumber + '&klassLetter=' + classLetter)
      .then(data => {
        if (data.data.statusCode === 200) {
          let pupilList = [];
          if (data.data.data !== null) {
            for (let i = 0; i < data.data.data.length; i++) {
              let pupil = data.data.data[i];
              pupilList.push(<option key={pupil.id}
                                     value={pupil.id}>{pupil.lastName} {pupil.firstName} {pupil.middleName}</option>)
            }
          }
          this.setState({
            pupils: pupilList
          })
        } else {
          this.setState({
            pupils: []
          })
        }
      })
  }

  onChangePupil() {
    let pupilList = document.getElementById('pupilList');
    localStorage.setItem('pupil', {"id": pupilList.value, "name": pupilList.options[pupilList.selectedIndex].text});
  }

  startTest() {
    let klass = document.getElementById('klass').value;
    let pupilList = document.getElementById('pupilList');
    let method = document.getElementById('methodList');
    let methodName = method.options[method.selectedIndex].text;
    let language = document.getElementById('language').value;
    localStorage.setItem('klass', klass);
    localStorage.setItem('pupil', {"id": pupilList.value, "name": pupilList.options[pupilList.selectedIndex].text});
    localStorage.setItem('selectedTest', {"id": method.value, "name": methodName});
    localStorage.setItem('language', language);
    axios.get(_.apiHost + '/test/getTest?methodId=' + method.value + '&language=' + language)
      .then(result => {
        if (result.data.statusCode === 200) {
          localStorage.setItem('selectedTestQuestions', result.data.data);
          window.open('/testing5', '_self');
        }
      });
  }

  render() {
    return (
      <div style={{width: "100%"}}>
        <SchoolMenu/>
        <div style={{width: "50%", marginTop: "30px"}}>
          <div className="row">
            <div className="col s6">
              <span>Sinfi:</span>
            </div>
            <div className="col s6">
              <select id="klass" style={{float: "left", width: "50%"}}
                      onChange={this.onChangeSchool.bind(this)}>
                <option value="5">5</option>
                <option value="11">11</option>
                <option value="10">10</option>
                <option value="9">9</option>
              </select>
              <select id="klassLetter" style={{width: "50%"}} onChange={this.onChangeSchool.bind(this)}>
                <option value=""> sinf</option>
                <option value="A">A-sinf</option>
                <option value="B">B-sinf</option>
                <option value="V">V-sinf</option>
                <option value="G">G-sinf</option>
                <option value="D">D-sinf</option>
                <option value="E">E-sinf</option>
                <option value="J">J-sinf</option>
                <option value="Z">Z-sinf</option>
                <option value="I">I-sinf</option>
                <option value="K">K-sinf</option>
                <option value="L">L-sinf</option>
                <option value="M">M-sinf</option>
                <option value="N">N-sinf</option>
                <option value="O">O-sinf</option>
                <option value="P">P-sinf</option>
                <option value="Q">Q-sinf</option>
                <option value="R">R-sinf</option>
                <option value="S">S-sinf</option>
                <option value="T">T-sinf</option>
                <option value="U">U-sinf</option>
                <option value="F">F-sinf</option>
                <option value="X">X-sinf</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col s6">
              <span>O`quvchining F.I.Sh:</span>
            </div>
            <div className="col s6">
              <select id='pupilList' onChange={this.onChangePupil.bind(this)}>
                <option value="0">Tanlang</option>
                {this.state.pupils}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col s6">
              <span>Metodika:</span>
            </div>
            <div className="col s6">
              <select id='methodList'>
                {this.state.methods}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col s6">
              <span>Test tili:</span>
            </div>
            <div className="col s6">
              <select id='language'>
                <option value="1">O`zbek</option>
                <option value="2">Rus</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              &nbsp;
            </div>
            <div className="col s6 center">
              <button className="btn btn-block" style={{width: "inherit", marginTop: "20px"}}
                      onClick={this.startTest.bind(this)}>Testni boshlang
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PupilsTest5;