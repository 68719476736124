import React, {Component} from 'react';
import LocalizedStrings from 'react-localization';
export const strings = new LocalizedStrings({	uz: {
		logout: "Chiqish",
		login: "Kirish",
		pupilName: "O`quvchining F.I.Sh",
		teacherName: "O`qituvchining F.I.Sh",
		testMethodName: "Metodika nomi",
		dateOfTest: "O`tkazilgan sana",
		testResult: "Test natijasi",
		pageText: "Sahifa",
		nextText: "Keyingi",
		previousText: "Oldingi",
		noDataText: "Ma'lumotlar yo`q",
		ofText: "",
		rowsText: "qator",
		pupil: "O`quvchi",
		method: "Metodika",
		finish: "Yakunlash",
		question: "Savol",
		peopleInitial: "F.I.Sh",
		districtName: "Tumani",
		schoolName: "Maktabi",
		dateOfBirth: "Tug`ilgan vaqti",
		teacher: "O`qituvchi",
		testDate: "Sana va vaqt",
		lastName: "Familiyasi",
		firstName: "Ismi",
		middleName: "Sharifi",
		action: "Amal",
		edit: "Tahrirlash",
		delete: "O`chirish",
		language: "Ta'lim tili",
		selectLanguage: "Ta'lim tilini tanlang",
		birthDate: "Tug`ilgan sanasi",
		survey: "So`rovnoma",
		klass: "Sinfi"
	},
	en: {
		logout: "Logout",
		login: "Login",
		pupilName: "Name of Pupil",
		teacherName: "Name of Teacher",
		testMethodName: "Method name",
		dateOfTest: "Test Date",
		testResult: "Test result",
		pageText: "Page",
		nextText: "Next",
		previousText: "Previous",
		noDataText: "No data",
		ofText: "of",
		rowsText: "rows",
		pupil: "Pupil",
		method: "Method",
		finish: "Finish",
		question: "Question",
		peopleInitial: "L.F.M name",
		districtName: "District name",
		schoolName: "School name",
		dateOfBirth: "Birth date",
		teacher: "Teacher",
		testDate: "Date and time",
		lastName: "LastName",
		firstName: "FirstName",
		middleName: "MiddleName",
		action: "Action",
		edit: "Edit",
		delete: "Delete",
		language: "Language",
		selectLanguage: "Choose language",
		birthDate: "Birth date",
		survey: "Survey",
		klass: "Klass"
	},
	ru: {
		logout: "Выйти",
		login: "Войти",
		pupilName: "Ф.И.О ученика",
		teacherName: "Ф.И.О учителя",
		testMethodName: "Названия метода",
		dateOfTest: "Дата проведения",
		testResult: "Результат теста",
		pageText: "Страница",
		nextText: "Следующая",
		previousText: "Предыдущая",
		noDataText: "Нет данных",
		ofText: "от",
		rowsText: "строки",
		pupil: "Ученик",
		method: "Методика",
		finish: "Заканчивать",
		question: "Вопрос",
		peopleInitial: "Ф.И.О",
		districtName: "Район",
		schoolName: "Школа",
		dateOfBirth: "Дата рождения",
		teacher: "Учитель",
		testDate: "Дата и время",
		lastName: "Фамилия",
		firstName: "Имя",
		middleName: "Отчество",
		action: "Действия",
		edit: "Редактировать",
		delete: "Удалить",
		language: "Язык обучения",
		selectLanguage: "Выберите язык обучения",
		birthDate: "День рождения",
		survey: "Опросник",
		klass: "Класс"
	}
});
strings.setLanguage('uz');

export const apiHost = 'http://rppm.uz';
// export const apiHost = 'http://localhost:8080';

export function requestHeader() {
	return {
		headers: {
			'Content-Encoding': 'deflate, gzip',
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': true,
			'Access-Control-Max-Age': 3600,
			'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
			'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
			'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept"
		}
	}
}

export function getRegions() {
	return [<option key="0" value="0">Tanlang</option>,<option key="1" value="1">Qoraqalpog`iston Respublikasi</option>,<option key="2" value="2">Andijon viloyati</option>,<option key="3" value="3">Buxoro viloyati</option>,
			<option key="4" value="4">Qashqadaryo viloyati</option>,<option value="5" key="5">Jizzax viloyati</option>,<option value="6" key="6">Navoiy viloyati</option>,<option value="7" key="7">Namangan viloyati</option>,
			<option value="8" key="8">Samarqand viloyati</option>,<option value="9" key="9">Sirdaryo viloyati</option>,<option value="10" key="10">Surxondaryo viloyati</option>,<option value="11" key="11">Toshkent viloyati</option>,
			<option value="12" key="12">Farg`ona viloyati</option>,<option value="13" key="13">Xorazm viloyati</option>,<option value="14" key="14">Toshkent shahri</option>];
}

export function NavigateToLogin() {
	window.open('/', '_self');
}

export function logout() {
	localStorage.removeItem('userData');
	window.open('/', '_self');
}

export function checkValid(value) {
	return value !== undefined && value !== null && value !== '';
}

export function checkNumberValid(fieldValue) {
	return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue > 0;
}

export function checkDateValid(fieldValue) {
	return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue > 0;
}

export function validateField(fieldId, fieldValue, errors) {
	if (!checkValid(fieldValue)) {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function validateNumberField(fieldId, fieldValue, errors) {
	if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue !== 0) {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function validateDateField(fieldId, fieldValue, errors) {
	if (!checkDateValid(fieldValue)) {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function validateSelect(fieldId, errors) {
	let fieldVal = document.getElementById(fieldId).value;
	if (fieldVal === undefined || fieldVal === null || fieldVal === '' || fieldVal === '0') {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function inputValueChanged(e) {
	if (checkValid(e.target.value)) {
		e.target.style.boxShadow = "none";
	} else if (checkValid(e.target.value)) {
		e.target.style.boxShadow = "0px 0px 5px 5px red";
	}
}

export function selectValueChanged(e) {
	if (validateSelect(e.target.id, 0) === 0) {
		e.target.style.boxShadow = "none";
	}
}

export function isAdmin() {
	let userData = JSON.parse(localStorage.getItem('userData'));
	return userData !== undefined && userData !== null && userData.role === "ADMIN";
}

export function isTashxis() {
	let userData = JSON.parse(localStorage.getItem('userData'));
	return userData !== undefined && userData !== null && userData.role === "TASHXIS";
}

export function isRegion() {
	let userData = JSON.parse(localStorage.getItem('userData'));
	return userData !== undefined && userData !== null && userData.role === "STATE";
}

export function isDistrict() {
	let userData = JSON.parse(localStorage.getItem('userData'));
	return userData !== undefined && userData !== null && userData.role === "DISTRICT";
}

export function isSchool() {
	let userData = JSON.parse(localStorage.getItem('userData'));
	return userData !== undefined && userData !== null && userData.role === "SCHOOL";
}

class Utils extends Component {

}

export default Utils;