import React, {Component} from 'react';
import ReactTable from 'react-table';
import axios from "axios";
import * as _ from './Utils';
import './react-table.css';
import './index.css';
import RegionMenu from "./region/RegionMenu";
import AdminMenu from "./admin/AdminMenu";

class PsychologistList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      psychologistList: []
    }
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    axios.get(_.apiHost + '/psychologist/list' + (userData.stateId > -1 ? '2?stateId=' + userData.stateId : ''))
      .then(result => {
        if (result.data.statusCode === 200) {
          this.setState({
            psychologistList: result.data.data !== undefined ? result.data.data : []
          })
        }
      })
  }

  openAddPsychologistForm() {
    window.open('/addPsychologist', '_self');
  }

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div>
        <div id='__react-content' className="flt topblock">
          {userData.stateId > -1 &&
          <RegionMenu/>
          }
          {userData.stateId === -1 &&
          <AdminMenu/>
          }
        </div>
        <table>
          <tbody>
          <tr>
            <td className="right">
              <button className="btn btn-block" style={{width: "initial"}}
                      onClick={this.openAddPsychologistForm.bind(this)}>Psixolog qo`shish
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <ReactTable filterable={true}
                          data={this.state.psychologistList}
                          columns={[{
                            columns: [{
                              id: 'fullName',
                              Header: _.strings.peopleInitial,
                              accessor: 'fullName',
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)
                            }, {
                              Header: _.strings.districtName,
                              accessor: 'districtName',
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)
                            }, {
                              Header: _.strings.schoolName,
                              accessor: 'schoolName',
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)
                            }, {
                              Header: _.strings.dateOfBirth,
                              accessor: 'birthDate',
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)
                            }]
                          }]}
                          noDataText={_.strings.noDataText}
                          previousText={_.strings.previousText}
                          nextText={_.strings.nextText}
                          pageText={_.strings.pageText}
                          ofText={_.strings.ofText}
                          rowsText={_.strings.rowsText}
                          defaultPageSize={10}
                          sortable={true}
                          multiSort={false}
                          resizable={true}
                          showPageSizeOptions={true}
                          className="-striped -highlight"
                          style={{width: "1000px"}}
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default PsychologistList;