import React, {Component} from 'react';
import Header from "./Header";
import axios from 'axios';
import * as _ from './Utils';
import {Link} from "react-router-dom";
import {Navigate} from "react-router";

class ShortTimeCoursesSummaryPage extends Component {

    constructor() {
        super();
        let courseName = localStorage.getItem('courseName');
        let courseID = localStorage.getItem('courseID');
        this.state = {
            courseName: courseName,
            courseID: courseID,
            toTesting: false
        }
    }

    goToTesting(language) {
        let methodId = 19 + this.state.courseID;
        let teacher = localStorage.getItem('teacher');
        localStorage.setItem('pupil', {"id": teacher.id, "name": teacher.lastName + ' ' + teacher.firstName});
        localStorage.setItem('selectedTest', {"id": methodId, "name": "Psixolog mahorat maktabi: " + this.state.courseID + "-mavzu"});
        localStorage.setItem('language', language);
        axios.get(_.apiHost + '/test/list?methodId=' + methodId + '&language=' + language)
            .then(result => {
                if (result.data.statusCode === 200) {
                    localStorage.setItem('selectedTestQuestions', result.data.data);
                    window.open('/testing', '_self');
                }
            });
    }

    render() {
        if (this.state.toTesting === true) {
            return <Navigate to='/testing'/>
        }
        return (
            <div>
                <Header/>
                <div className="container text-center">
                    <div className="form-row" style={{marginTop: "20px"}}>
                        <div className="col-md-12 text-center">
                            <span className="linkStyle">{this.state.courseName}</span>
                            <br/>
                        </div>
                        <div className="col-md-12 text-center my-5">
                            <span style={{fontSize: "28px"}}>Mavzu materiallarini siz quyidagi telegram kanali orqali o`qishingiz mumkin:</span>
                            <br/>
                            <a href="https://t.me/joinchat/AAAAAEyYreSl6Ezg-pFyxg" style={{fontSize: "28px"}}>Psixolog mahorat maktabi</a>
                        </div>
                    </div>
                    <iframe src={"/course" + this.state.courseID + ".html"} title="Mavzu"
                            style={{height: "60vh", width: "100%", border: "none"}}>

                    </iframe>
                    <div className="form-row">
                        <div className="col-md-12 text-center my-4">
                            <div>
                                <Link className="btn btn-success" to="/shortCourses">Ortga qaytish</Link>
                                &nbsp;&nbsp;
                                <button className="btn btn-success" onClick={this.goToTesting.bind(this, 1)}>
                                    {this.state.courseID}-mavzu testi (o`zbekcha)
                                </button>
                                &nbsp;&nbsp;
                                <button className="btn btn-success" onClick={this.goToTesting.bind(this, 2)}>
                                    Тест по {this.state.courseID}-тема (русский)
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortTimeCoursesSummaryPage;