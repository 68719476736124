import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import {getRegions} from '../Utils';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class AddTeacherView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			districts: [],
			schools: [],
			disabledVal1: true,
			disabledVal2: true,
			birthDate: new Date()
		};
		this.setBirthDate = this.setBirthDate.bind(this);
	}

	setBirthDate(value) {
		this.setState({
			birthDate: value
		})
	}
	
	save() {
		let errors = 0;
		//Asosiy ma'lumotlar
		let lastName = document.getElementById('lastName').value;
		errors = _.validateField('lastName', lastName, errors);
		let firstName = document.getElementById('firstName').value;
		errors = _.validateField('firstName', firstName, errors);
		let middleName = document.getElementById('middleName').value;
		errors = _.validateField('middleName', middleName, errors);
		let birthDate = this.state.birthDate;
		errors = _.validateField('birthDate', birthDate, errors);

		let gender = document.getElementById('gender').value;
		errors = _.validateSelect('gender', errors);
		let nationalityId = document.getElementById('nationality').value;
		errors = _.validateSelect('nationality', errors);

		let institution = document.getElementById('institution1').value;
		errors = _.validateField('institution1', institution, errors);
		let institutionYear = document.getElementById('institutionYear1').value;
		errors = _.validateField('institutionYear1', institutionYear, errors);
		let tendency = document.getElementById('tendency1').value;
		errors = _.validateField('tendency1', tendency, errors);

		let institution2 = document.getElementById('institution2').value;
		let institutionYear2 = document.getElementById('institutionYear2').value;
		let tendency2 = document.getElementById('tendency2').value;

		//Qayta tayyorlov
		let reTrainingInstitution = document.getElementById('reTrainingInstitution').value;
		let reTrainingYear = document.getElementById('reTrainingYear').value;
		let reTrainingTendency = document.getElementById('reTrainingTendency').value;
		if (!this.state.disabledVal1) {
			errors = _.validateField('reTrainingInstitution', reTrainingInstitution, errors);
			errors = _.validateField('reTrainingYear', reTrainingYear, errors);
			errors = _.validateSelect('reTrainingTendency', errors);
		}

		let allExperience = document.getElementById('allExperience').value;
		errors = _.validateField('allExperience', allExperience, errors);
		let pedExperience = document.getElementById('pedExperience').value;
		errors = _.validateField('pedExperience', pedExperience, errors);
		let scienceExperience = document.getElementById('scienceExperience').value;
		errors = _.validateField('scienceExperience', scienceExperience, errors);
		let toifa = document.getElementById('toifa').value;
		errors = _.validateSelect('toifa', errors);
		let workSchool = document.getElementById('workSchool').value;
		errors = _.validateSelect('workSchool', errors);
		let positionId = document.getElementById('position').value;
		errors = _.validateField('position', positionId, errors);
		let scienceName = document.getElementById('scienceName').value;
		// errors = _.validateField('scienceName', scienceName, errors);

		//Malaka oshirish
		let trainingInstitution = document.getElementById('trainingInstitution').value;
		let trainingYear = document.getElementById('trainingYear').value;
		let trainingTendency = document.getElementById('trainingTendency').value;
		if (!this.state.disabledVal2) {
			errors = _.validateField('trainingInstitution', trainingInstitution, errors);
			errors = _.validateField('trainingYear', trainingYear, errors);
			errors = _.validateField('trainingTendency', trainingTendency, errors);
		}

		if (+errors === 0) {
			let formData = {};
			formData.lastName = lastName;
			formData.firstName = firstName;
			formData.middleName = middleName;
			formData.birthDate = birthDate;
			formData.gender = Number(gender);
			formData.nationality = Number(nationalityId);
			formData.institution = institution;
			formData.institutionYear = Number(institutionYear);
			formData.tendency = tendency;

			formData.institution2 = institution2;
			formData.institutionYear2 = Number(institutionYear2);
			formData.tendency2 = tendency2;

			formData.reTrainingType = this.state.disabledVal1;
			formData.reTrainingInstitution = reTrainingInstitution;
			formData.reTrainingYear = Number(reTrainingYear);
			formData.reTrainingTendency = reTrainingTendency;

			formData.allExperience = Number(allExperience);
			formData.pedExperience = Number(pedExperience);
			formData.scienceExperience = Number(scienceExperience);
			formData.toifa = Number(toifa);
			formData.schoolId = Number(workSchool);
			formData.position = positionId;
			formData.scienceName = scienceName;

			formData.trainingType = this.state.disabledVal2;
			formData.trainingInstitution = trainingInstitution;
			formData.trainingYear = trainingYear;
			formData.trainingTendency = trainingTendency;

			axios.post(_.apiHost + '/teacher/add2', formData)
			.then(result => {
				if (result.data.statusCode === 200) {
						swal(result.data.message, "", 'success');
					window.open(window.location.protocol + '//' + window.location.host + "/teachersTest3", '_self');
					} else {
						swal(result.data.message, "", 'error');
					}
				})
		} else {
			swal("Hamma ma'lumotlarni kiriting", "", 'error');
		}
	}

	enableVal1() {
		this.setState({
			disabledVal1: false
		})
	}

	disableVal1() {
		this.setState({
			disabledVal1: true
		})
	}

	enableVal2() {
		this.setState({
			disabledVal2: false
		})
	}

	disableVal2() {
		this.setState({
			disabledVal2: true
		})
	}

	onChangeRegion(regionElement, districtElement) {
		let stateId = document.getElementById([regionElement]).value;
		axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let districts = data.data.data.map(user=> {
						return <option key={user.id} value={user.id}>{user.name}</option>
					});
					this.setState({
						[districtElement]: districts
					})
				} else {
					this.setState({
						[districtElement]: []
					})
				}
			})
	}

	onChangeDistrict(districtElement, schoolElement) {
		let district = document.getElementById([districtElement]);
		axios.get(_.apiHost + '/pupil/school/list?districtId=' + district.value)
			.then(data => {
				if (data.data.statusCode === 200) {
					let schoolList = [];
					for (let i = 0; i < data.data.data.length; i++) {
						let school = data.data.data[i];
						schoolList.push(<option key={school.id} value={school.id}>{school.name}</option>)
					}
					this.setState({
						[schoolElement]: schoolList
					})
				} else {
					this.setState({
						[schoolElement]: []
					})
				}
			});
		district.style.backgroundColor = "#ffffff !important";
	}

	render() {
		return (
			<div className="content">
				<div className="container card card-body my-4">
					<fieldset>
						<legend>Asosiy ma'lumotlar</legend>
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="lastName">Familiyasi</label>
								<input id="lastName" className="form-control"/>
							</div>
							<div className="col-md-3">
								<label htmlFor="firstName">Ismi</label>
								<input id="firstName" className="form-control"/>
							</div>
							<div className="col-md-3">
								<label htmlFor="middleName">Otasining ismi</label>
								<input id="middleName" className="form-control"/>
							</div>
							<div className="col-md-3">
								<label htmlFor="birthDate">Tug`ilgan sanasi</label>
								<DatePicker id="birthDate" selected={this.state.birthDate} onChange={this.setBirthDate.bind(this)} dateFormat="dd.MM.yyyy" className="form-control"/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="gender">Jinsi</label>
								<select id="gender" className="form-control">
									<option value="1">Erkak</option>
									<option value="2">Ayol</option>
								</select>
							</div>
							<div className="col-md-3">
								<label htmlFor="nationality">Millati</label>
								<select id="nationality" className="form-control">
									<option value="0">Tanlang</option>
									<option value="1">O`zbek</option>
									<option value="2">Rus</option>
									<option value="3">Qoraqalpoq</option>
									<option value="4">Qozoq</option>
									<option value="5">Qirg`iz</option>
									<option value="6">Tojik</option>
									<option value="7">Turkman</option>
									<option value="8">Boshqa</option>
								</select>
							</div>
						</div>
						<div className="form-row mt-4">
							<div className="col-md-12 text-center">
								<h4>Ma'lumoti</h4>
							</div>
						</div>
						<fieldset>
							<legend>1-mutaxassisligi</legend>
							<div className="form-row">
								<div className="col-md-5">
									<label htmlFor="institution1">Oliy o`quv yurti nomi</label>
									<input id="institution1" className="form-control"/>
								</div>
								<div className="col-md-5">
									<label htmlFor="tendency1">Mutaxassisligi</label>
									<input id="tendency1" className="form-control"/>
								</div>
								<div className="col-md-2">
									<label htmlFor="institutionYear1">Tugatgan yili</label>
									<input id="institutionYear1" type="number" className="form-control"/>
								</div>
							</div>
						</fieldset>
						<fieldset>
							<legend>2-mutaxassisligi</legend>
							<div className="form-row">
								<div className="col-md-5">
									<label htmlFor="institution2">Oliy o`quv yurti nomi</label>
									<input id="institution2" className="form-control"/>
								</div>
								<div className="col-md-5">
									<label htmlFor="tendency2">Mutaxassisligi</label>
									<input id="tendency2" className="form-control"/>
								</div>
								<div className="col-md-2">
									<label htmlFor="institutionYear2">Tugatgan yili</label>
									<input id="institutionYear2" type="number" className="form-control"/>
								</div>
							</div>
						</fieldset>
						<fieldset>
							<legend>Qayta tayyorlash kurslari</legend>
							<div className="form-row">
								<div className="col-md-1 ml-3">
									<input className="form-check-input" type="radio" name="reTrainingType" id="reTrainingTypeNo" value="option1" onClick={this.disableVal1.bind(this)} defaultChecked/>
									<label className="form-check-label" htmlFor="reTrainingTypeNo">
										O`tmagan
									</label>
								</div>
								<div className="col-md-1 ml-3">
									<input className="form-check-input" type="radio" name="reTrainingType" id="reTrainingTypeYes" value="option2" onClick={this.enableVal1.bind(this)}/>
									<label className="form-check-label" htmlFor="reTrainingTypeYes">
										O`tgan
									</label>
								</div>
								<div className="col-md-3">
									<label htmlFor="reTrainingInstitution">Ta'lim muassasasi nomi</label>
									<input id="reTrainingInstitution" className="form-control" disabled={this.state.disabledVal1}/>
								</div>
								<div className="col-md-3">
									<label htmlFor="reTrainingTendency">Yo`nalishi</label>
									<input id="reTrainingTendency" className="form-control" disabled={this.state.disabledVal1}/>
								</div>
								<div className="col-md-2">
									<label htmlFor="reTrainingYear">Tugatgan yili</label>
									<input id="reTrainingYear" type="number" className="form-control" disabled={this.state.disabledVal1}/>
								</div>
							</div>
						</fieldset>
					</fieldset>
					<fieldset>
						<legend>Ish faoliyati</legend>
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="allExperience">Umumiy ish staji</label>
								<input id="allExperience" type="number" className="form-control"/>
							</div>
							<div className="col-md-3">
								<label htmlFor="pedExperience">Pedagogik ish staji</label>
								<input id="pedExperience" type="number" className="form-control"/>
							</div>
							<div className="col-md-3">
								<label htmlFor="scienceExperience">Fan bo`yicha ish staji</label>
								<input id="scienceExperience" type="number" className="form-control"/>
							</div>
							<div className="col-md-3">
								<label htmlFor="toifa">Toifasi</label>
								<select id="toifa" className="form-control">
									<option value="0">Tanlang</option>
									<option value="1">Oliy</option>
									<option value="2">1-toifa</option>
									<option value="3">2-toifa</option>
									<option value="4">Mutaxassis</option>
								</select>
							</div>
						</div>
						<div className="form-row mt-4">
							<div className="col-md-12 text-center">
								<h4>Ish joyi</h4>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-4">
								<label htmlFor="workRegion">Hududi</label>
								<select id="workRegion" className="form-control" onChange={this.onChangeRegion.bind(this, 'workRegion', 'districts')}>
									{getRegions()}
								</select>
							</div>
							<div className="col-md-4">
								<label htmlFor="workDistrict">Tumani</label>
								<select id="workDistrict" className="form-control" onChange={this.onChangeDistrict.bind(this, 'workDistrict', 'schools')}>
									<option value="0">Tanlang</option>
									{this.state.districts}
								</select>
							</div>
							<div className="col-md-4">
								<label htmlFor="school">Maktabi</label>
								<select id="workSchool" className="form-control">
									<option value="0">Tanlang</option>
									{this.state.schools}
								</select>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6">
								<label htmlFor="position">Lavozimi</label>
								<input id="position" className="form-control"/>
							</div>
							<div className="col-md-6">
								<label htmlFor="scienceName">Qaysi fandan dars beradi</label>
								<input id="scienceName" className="form-control"/>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Malaka oshirganligi</legend>
						<div className="form-row">
							<div className="col-md-1 ml-3">
								<input className="form-check-input" type="radio" name="trainingType" id="trainingTypeNo" value="option1" onClick={this.disableVal2.bind(this)} defaultChecked/>
								<label className="form-check-label" htmlFor="trainingTypeNo">
									O`tmagan
								</label>
							</div>
							<div className="col-md-1 ml-3">
								<input className="form-check-input" type="radio" name="trainingType" id="trainingTypeYes" value="option2" onClick={this.enableVal2.bind(this)}/>
								<label className="form-check-label" htmlFor="trainingTypeYes">
									O`tgan
								</label>
							</div>
							<div className="col-md-4">
								<label htmlFor="trainingInstitution">Ta'lim muassasasi nomi</label>
								<input id="trainingInstitution" className="form-control" disabled={this.state.disabledVal2}/>
							</div>
							<div className="col-md-3">
								<label htmlFor="trainingTendency">Yo`nalishi</label>
								<input id="trainingTendency" className="form-control" disabled={this.state.disabledVal2}/>
							</div>
							<div className="col s3">
								<label htmlFor="trainingYear">Yili</label>
								<input id="trainingYear" className="form-control" type="number" disabled={this.state.disabledVal2}/>
							</div>
						</div>
					</fieldset>
					<div className="form-row text-center mt-3">
						<div className="col-md-12">
							<button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}