import React, {Component} from 'react';
import '../App.css';
import DistrictMenu from "./DistrictMenu";
import {NavigateToLogin} from "../Utils";

class DistrictHomePage extends Component {

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
	  if (userData === undefined || userData === null || userData.role !== 'DISTRICT') {
		  NavigateToLogin();
	  }
    return (
      <div style={{width: "100%"}}>
        {userData !== undefined && userData !== null && userData.role === 'DISTRICT' &&
        <div id='__react-content' className="flt topblock">
          <DistrictMenu/>
        </div>
        }
      </div>
    )
  }
}

export default DistrictHomePage;