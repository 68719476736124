import React, {Component} from 'react';
import Header from "./Header";

class ChooseClass extends Component {

	constructor() {
		super();
		this.state = {
			language: 'uz',
			uz: ['Tilni tanlang', 'Eshitishida muammolari bo’lgan bolalarga taqdim etiladigan onlayn darslar', 'Tayyorlov sinfi', '1-sinf', '2-sinf', '3-sinf'],
			qqr: ['Tilni tanlang', 'Eshitishida muammolari bo’lgan bolalarga taqdim etiladigan onlayn darslar', 'Tayyorlov sinfi', '1-sinf', '2-sinf', '3-sinf'],
			ru: ['Выберите язык', 'Онлайн уроки для детей с нарушением слуха', 'Подготовительный класс', '1-класс', '2-класс', '3-класс'],
			langStrings: []
		};
	}

	componentDidMount() {
		this.setState({
			langStrings: this.state.uz
		})
	}

	openVideoPage(klass) {
		this.props.history.push('/video/' + klass + '?lang=' + this.state.language);
		// window.open('/video/' + klass + '?lang=' + this.state.language, '_self')
	}

	setLanguage(language) {
		this.setState({
			language: language,
			langStrings: language === 'ru' ? this.state.ru : this.state.uz
		})
	}

	render() {
		return (
			<div>
				<Header/>

				<div className="container">
					<div className="form-row text-center">
						<div className="col-md-12">
							<span>{this.state.langStrings[0]}</span>
						</div>
					</div>

					<div className="col-md-2"/>
					<div className="form-row" style={{marginBottom: "50px"}}>
						<div className="col-md-2"/>
						<div className="col-md-2">
							<div className="flt2" onClick={this.setLanguage.bind(this, 'uz')}>
								<button className="btn btn-block">O`zbek</button>
							</div>
						</div>
						<div className="col-md-1"/>
						<div className="col-md-2">
							<div onClick={this.setLanguage.bind(this, 'ru')}>
								<button className="btn btn-block">Rus</button>
							</div>
						</div>
						<div className="col-md-1"/>
						<div className="col-md-2">
							<div onClick={this.setLanguage.bind(this, 'qqr')}>
								<button className="btn btn-block">Qoraqalpoq</button>
							</div>
						</div>
						<div className="col-md-2"/>
					</div>

					<div className="form-row text-center">
						<div className="col-md-12">
							<span><b>{this.state.langStrings[1]}</b></span>
						</div>
					</div>

					<table width="100%">
						<tbody>
						<tr className="form-group">
							<td width="50%" style={{float: "right"}}>
								<div className="classBox flt2" onClick={this.openVideoPage.bind(this, 0)}>
									<span>{this.state.langStrings[2]}</span>
								</div>
							</td>
							<td width="50%" style={{float: "none"}}>
								<div className="classBox" onClick={this.openVideoPage.bind(this, 1)}>
									<span>{this.state.langStrings[3]}</span>
								</div>
							</td>
						</tr>
						<tr className="form-group">
							<td width="50%" style={{float: "right"}}>
								<div className="classBox flt2" onClick={this.openVideoPage.bind(this, 2)}>
									<span>{this.state.langStrings[4]}</span>
								</div>
							</td>
							<td width="50%" style={{float: "none"}}>
								<div className="classBox" onClick={this.openVideoPage.bind(this, 3)}>
									<span>{this.state.langStrings[5]}</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}

export default ChooseClass;