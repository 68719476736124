import React, {Component} from 'react';
import './App.css';
import axios from "axios";
import swal from 'sweetalert';
import * as _ from './Utils';
import Header from "./Header";
import {Link} from "react-router-dom";

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			states: [],
			districts: [],
			schools: [],
			pupils: [],
			methods: [],
			showAlert: false,
			toSchool: false,
			toDistrict: false,
			toRegion: false,
			toReport: false,
			toTashxis: false,
			toTesting: false,
		}
	}

	onLogin() {
		let userName = document.getElementById('userName').value;
		let password = document.getElementById('password').value;
		let data = new FormData();
		data.append("userName", userName);
		data.append("password", password);
		axios.post(_.apiHost + '/user/login', data)
			.then(result => {
				if (result.data.statusCode === 200) {
					localStorage.setItem('userData', result.data.data);
					let userName = result.data.data.userName;
					let userRole = result.data.data.role;
					localStorage.setItem('userData', JSON.stringify(result.data.data));
					if (userName === 'admin') {
						window.open(window.location.href + "adminHome", "_self");
					} else if (userName === 'tashxis') {
						window.open("/tashxisHome", "_self");
					} else if (userName === 'report') {
						window.open("/reportHome", "_self");
					} else if (userRole === 'SCHOOL') {
						window.open("/schoolHome", "_self");
					} else if (userRole === 'DISTRICT') {
						window.open("/districtHome", "_self");
					} else if (userRole === 'STATE') {
						window.open("/regionHome", "_self");
					} else if (userRole === 'TESTING') {
						window.open("/teachersTest", "_self");
					} else {
						swal('Login yoki parol xato kiritilgan', '', 'error');
					}
				} else if (result.data.statusCode === 500) {
					swal('Login yoki parol xato kiritilgan', '', 'error');
				}
			})
	}

	render() {
		return (
			<div>
				<Header/>
				<p className="text-center"><h4>Psixologik-pedagogik diagnostika metodikalarni elektron dasturiga xush kelibsiz</h4></p>

				<div className="form-row">
					<div className="col-md-12 d-flex">
                        <div className="classBox">
                            <Link to="/publicTest" style={{fontSize: "24px"}}>Maktab o`quvchilari uchun</Link>
                        </div>
                        <div className="classBox">
                            <Link to="/teachersTest3" style={{fontSize: "24px"}}>Maktab o`qituvchilari uchun</Link>
                        </div>
                        <div className="classBox">
                            <Link to="/teachersTest3" style={{fontSize: "16px"}}>Umumta'lim maktablari pedagoglarining kompetentsiyasini ijtimoiy – psixologik baholash</Link>
                        </div>
                        {/*<div className="classBox">
                            <Link to="/certTest" style={{fontSize: "24px"}}>Attestatsiyadan o`tuvchi psixologlar uchun sinov testi</Link>
                        </div>
                        <div className="classBox">
                            <Link to="/startCourse" style={{fontSize: "24px"}}>Psixolog mahorat maktabi</Link>
                        </div>*/}
                        <div className="classBox">
                            <Link to="/startCourse" style={{fontSize: "24px"}}>Qisqa muddatli malaka oshirish kursi</Link>
                        </div>
						<div className="classBox">
							<div className="form-row">
								<div className="col-md-12 text-center">
									<h5>Tizimga kirish</h5>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="userName" className="sr-only">Login</label>
									<input id="userName" className="form-control" placeholder="Login" autoFocus=""/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="password" className="sr-only">Parol</label>
									<input type="password" id="password" className="form-control" placeholder="Parol"/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-12 text-center">
									<button className="btn btn-primary btn-block" type="button" onClick={this.onLogin.bind(this)}>
										Kirish
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Home;