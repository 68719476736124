import React, {Component} from 'react';
import '../App.css';
import axios from "axios";
import * as _ from '../Utils';
import ReactTable from 'react-table';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AdminTeacherTestResultList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            methodId: 17,
            groupNumber: '',
            groups: [],
            testResult: []
        }
    }

    componentDidMount() {
        let groupList = [];
        for (let i = 1; i <= 50; i++) {
            groupList.push(<option key={i} value={i}>{i + '-guruh'}</option>)
        }
        this.setState({
            groups: groupList
        })
    }

    handleChangeMethod(e) {
        this.setState({
            methodId: e.target.value
        });
        this.fetchTestResult();
    }

    handleChangeGroup(e) {
        this.setState({
            groupNumber: e.target.value
        });
        this.fetchTestResult();
    }

    fetchTestResult() {
        if (this.state.methodId !== undefined && this.state.methodId !== null && this.state.methodId !== '' &&
            this.state.groupNumber !== undefined && this.state.groupNumber !== null && this.state.groupNumber !== '') {
            axios.get(_.apiHost + '/test/reTrainingTestResult?groupNumber=' + this.state.groupNumber + '&methodId=' + this.state.methodId)
                .then(data => {
                    if (data.data.statusCode === 200) {
                        this.setState({
                            testResult: data.data.data
                        })
                    } else {
                        this.setState({
                            testResult: []
                        })
                    }
                })
        }
    }

    exportToExcel() {
        let groupNumber = document.getElementById('groupNumber').value;
        cookies.set('groupNumber', groupNumber, {path: '/'});
        let form = document.getElementById('excelForm');
        form.submit();
    }

    render() {
        let userData = JSON.parse(localStorage.getItem('userData'));
        return (
            <div>
                {userData !== undefined && userData !== null &&
                <div className="w-100">
                    <div className="row" style={{height: "3rem"}}>
                        <div className="col s5">
                            <div className="form-group row">
                                <label htmlFor="testMethod" className="col-sm-2 col-form-label">Turi: </label>
                                <div className="col-sm-10">
                                    <select id="testMethod" className="form-control"
                                            onChange={this.handleChangeMethod.bind(this)}>
                                        <option value="15">Qayta tayyorlov</option>
                                        <option value="17">Malaka oshirish</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col s5">
                            <div className="form-group row">
                                <label htmlFor="groupNumber" className="col-sm-2 col-form-label">Guruhi: </label>
                                <div className="col-sm-10">
                                    <select id="groupNumber" className="form-control"
                                            onChange={this.handleChangeGroup.bind(this)}>
                                        {this.state.groups}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col s2 float-right pr-0">
                            <form id="excelForm" className="float-right" method="post"
                                  action={_.apiHost + "/common/testReportPDF"}>
                                <button className="btn btn-info" onClick={this.exportToExcel.bind(this)}>PDF</button>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <ReactTable
                            data={this.state.testResult}
                            columns={[{
                                columns: [{
                                    Header: _.strings.teacherName,
                                    accessor: 'pupilName'
                                }, {
                                    Header: _.strings.districtName,
                                    accessor: 'districtName'
                                }, {
                                    Header: _.strings.schoolName,
                                    accessor: 'schoolName'
                                }, {
                                    Header: _.strings.testDate,
                                    accessor: 'dateOfTest'
                                }, {
                                    Header: _.strings.testResult,
                                    accessor: 'testResult'
                                }]
                            }]}
                            noDataText={_.strings.noDataText}
                            previousText={_.strings.previousText}
                            nextText={_.strings.nextText}
                            pageText={_.strings.pageText}
                            ofText={_.strings.ofText}
                            rowsText={_.strings.rowsText}
                            defaultPageSize={20}
                            resizable={true}
                            showPageSizeOptions={true}
                            className="-striped -highlight"
                            style={{width: "100%"}}
                        />
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default AdminTeacherTestResultList;