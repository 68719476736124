import React, {Component} from 'react';
import axios from "axios";
import * as _ from './Utils';
import {getRegions} from './Utils';
import {Navigate} from "react-router";

export default class TeachersPublicTest2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            states: [],
            districts: [],
            schools: [],
	        teachers: [],
            toTesting: false
        }
    }

    startTest() {
	    let teacher = document.getElementById('teacher');
	    let teacherName = teacher.options[teacher.selectedIndex].text;
        localStorage.setItem('pupil', JSON.stringify({
            "id": teacher.value,
            "name": teacherName
        }));

        localStorage.setItem('language', 1);
        localStorage.setItem('selectedTest', JSON.stringify({"id": 13, "name": "Kasbiy so`nish darajasi"}));
        axios.get(_.apiHost + '/test/list?methodId=' + 10 + '&language=1')
            .then(result => {
                if (result.data.statusCode === 200) {
	                localStorage.setItem('selectedTestQuestions', JSON.stringify(result.data.data));
	                this.setState({
		                toTesting: true
	                });
                }
            });
    }

	onChangeStates() {
		let stateId = document.getElementById('region').value;
		axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let districtList = [];
					for (let i = 0; i < data.data.data.length; i++) {
						let user = data.data.data[i];
						districtList.push(<option key={user.id} value={user.id}>{user.name}</option>)
					}
					districtList.unshift(<option key="0" value="0">Tanlang</option>);
					this.setState({
						districts: districtList
					})
				} else {
					this.setState({
						districts: []
					})
				}
			})
	}

	onChangeDistrict() {
		let districtId = document.getElementById('district').value;
		axios.get(_.apiHost + '/pupil/school/list?districtId=' + districtId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let schoolList = data.data.data.map(school=> {
						return <option key={school.id} value={school.id}>{school.name}</option>
					});
					schoolList.unshift(<option key="0" value="0">Tanlang</option>);
					this.setState({
						schools: schoolList
					})
				} else {
					this.setState({
						schools: []
					})
				}
			})
	}

	onChangeSchool(e) {
		let schoolId = e.target.value;
		axios.get(_.apiHost + '/teacher/list4?schoolId=' + schoolId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let teacherList = data.data.data.map(school=> {
						return <option key={school.id} value={school.id}>{school.name}</option>
					});
					teacherList.unshift(<option key="0" value="0">Tanlang</option>);
					this.setState({
						teachers: teacherList
					})
				} else {
					this.setState({
						teachers: []
					})
				}
			})
	}

	openPage() {
		window.open(window.location.protocol + '//' + window.location.host + "/addTeacher2", '_self');
	}

    render() {
	    if (this.state.toTesting) {
		    return <Navigate to='/testing'/>
	    }
        return (
            <div className="content">
                <div className="container card card-body shadow-sm">
                    <div className="form-row">
                        <div className="col-md-12 text-right">
                            <button className="btn btn-info" onClick={this.openPage.bind(this)}>Ro`yxatdan o`tish</button>
                        </div>
                    </div>
	                <div className="form-row mt-2">
	                    <div className="col-md-12 text-center">
	                        <h4>Testni jarayonini boshlash</h4>
	                    </div>
	                </div>
	                <div className="form-row mt-2">
                        <div className="col-md-6">
                            <label htmlFor="region">Hududi:</label>
                            <select id='region' className="form-control" onChange={this.onChangeStates.bind(this)}>
	                            {getRegions()}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="district">Tumani(shahar):</label>
                            <select id='district' className="form-control" onChange={this.onChangeDistrict.bind(this)}>
	                            {this.state.districts}
                            </select>
                        </div>
                    </div>
	                <div className="form-row">
                        <div className="col-md-6">
                            <label htmlFor="school">Maktabi:</label>
                            <select id='school' className="form-control" onChange={this.onChangeSchool.bind(this)}>
	                            {this.state.schools}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="school">O`qituvchi:</label>
                            <select id='teacher' className="form-control">
	                            {this.state.teachers}
                            </select>
                        </div>
                    </div>

                    <div className="form-row mt-2">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-success" onClick={this.startTest.bind(this)}>Testni boshlash</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}