import React, {Component} from 'react';
import ReactTable from 'react-table';
import axios from "axios";
import * as _ from './Utils';
import './react-table.css';
import './index.css';
import SchoolMenu from "./school/SchoolMenu";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class PupilResults extends Component {

	constructor(props) {
		super(props);
		let methods = [];
		methods.push(<option value="14">11-sinf kompleks testi</option>);
		let userData = JSON.parse(localStorage.getItem('userData'));
		this.state = {
			testResults: [],
			userData: userData,
			methods: methods
		}
	}

	componentDidMount() {
		let methodId = document.getElementById('testMethod').value;
		this.fetchData(methodId);
	}

	fetchData(methodId) {
		let user = JSON.parse(localStorage.getItem('userData'));
		let schoolId = user.stateId;
		axios.get(_.apiHost + '/test/result?schoolId=' + schoolId + '&methodId=' + methodId)
			.then(result => {
				if (result.data.statusCode === 200) {
					this.setState({
						testResults: result.data.data !== undefined ? result.data.data : []
					})
				}
			})
	}

	exportToPDF(resultId) {
		cookies.set('resultId', resultId, {path: '/'});
		let form = document.getElementById('pdfForm');
		form.submit();
	}

	exportToExcel(schoolId) {
		let klass = document.getElementById('klasses').value;
		let methodId = document.getElementById('testMethod').value;
		cookies.set('schoolId', schoolId, {path: '/'});
		cookies.set('klass', klass, {path: '/'});
		cookies.set('methodId', methodId, {path: '/'});
		let form = document.getElementById('excelForm');
		form.submit();
	}

	onKlassChange() {
		let klass = document.getElementById('klasses').value;
		if (klass === '7' || klass === '11') {
			let methods = [];
			methods.push(<option value="14">11-sinf kompleks testi</option>);
			this.setState({
				methods: methods
			});
			this.fetchData(14);
		} else if (klass === '9') {
			let methods = [];
			methods.push(<option value="16">9-sinf kompleks testi</option>);
			this.setState({
				methods: methods
			});
			this.fetchData(16);
		}
	}

	render() {
		let schoolId = this.state.userData.stateId;
		return (
			<div className="content">
				<SchoolMenu/>
				{this.state.userData !== undefined && this.state.userData !== null &&
				<div className="container card card-body my-4 shadow-sm">
					<div className="form-row">
						<div className="col-md-2">
							<select id="klasses" className="form-control" onChange={this.onKlassChange.bind(this)}>
								<option value="11">11-sinf</option>
								<option value="10">11-sinf</option>
								<option value="9">9-sinf</option>
								<option value="8">8-sinf</option>
								<option value="7">7-sinf</option>
							</select>
						</div>
						<div className="col-md-3">
							<select id="testMethod" className="form-control">
								{this.state.methods}
							</select>
						</div>
						<div className="col-md-5"/>
						<div className="col-md-2 text-right">
							<form id="excelForm" method="post" className="right"
							      action={_.apiHost + "/common/pupilTestResultExcel"}>
								<button className="btn btn-success btn-block" onClick={this.exportToExcel.bind(this, schoolId)}>Excel
								</button>
							</form>
						</div>
					</div>
					<div className="form-row">
						<div className="col s12">
							<ReactTable filterable={true}
							            data={this.state.testResults}
							            columns={[{
								            columns: [{
									            id: 'pupilName',
									            Header: _.strings.pupilName,
									            accessor: 'pupilName',
									            filterMethod: (filter, row) =>
										            row[filter.id].startsWith(filter.value)
								            }, {
									            Header: _.strings.dateOfTest,
									            accessor: 'dateOfTest',
									            filterMethod: (filter, row) =>
										            row[filter.id].startsWith(filter.value)
								            }, {
									            Header: _.strings.testResult,
									            Cell: r => (
										            <div>
											            {r.original.testMethod.id === 16 &&
											            <form id="pdfForm" method="post"
											                  action={_.apiHost + "/common/pupilTestResultPDF"}
											                  style={{float: "left", marginLeft: "5px"}}>
												            <button className="btn btn-success btn-sm"
												                    onClick={this.exportToPDF.bind(this, r.original.id)}>
													            PDF
												            </button>
											            </form>
											            }
											            {r.original.testMethod.id !== 16 &&
											            <span>{r.original.testResult}</span>
											            }
										            </div>
									            )
								            }]
							            }]}
							            noDataText={_.strings.noDataText}
							            previousText={_.strings.previousText}
							            nextText={_.strings.nextText}
							            pageText={_.strings.pageText}
							            ofText={_.strings.ofText}
							            rowsText={_.strings.rowsText}
							            defaultPageSize={10}
							            sortable={true}
							            multiSort={false}
							            resizable={true}
							            showPageSizeOptions={true}
							            className="-striped -highlight"
							            style={{width: "100%"}}
							/>
						</div>
					</div>
				</div>
				}
			</div>
		)
	}
}

export default PupilResults;