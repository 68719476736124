import React, {Component} from 'react';
import '../App.css';
import axios from "axios";
import * as _ from '../Utils';
import ReactTable from 'react-table';
import RegionMenu from "./RegionMenu";

class RegionTeacherList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      teachers: [],
      showAddButton: props.showAddButton !== undefined && props.showAddButton !== null ? props.showAddButton : true
    }
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let regionId = userData !== undefined && userData !== null ? userData.stateId : null;
    axios.get(_.apiHost + '/teacher' + (regionId !== null && regionId > 0 ? '/list2?regionId=' + regionId : '/list1'))
      .then(result => {
        if (result.data.statusCode === 200) {
          this.setState({
            teachers: result.data.data !== undefined ? result.data.data : []
          })
        }
      })
  }

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div>
        <div id='__react-content' className="flt topblock">
          <RegionMenu/>
        </div>
        {userData !== undefined && userData !== null &&
        <table>
          <tbody>

          <tr>
            <td>
              <ReactTable filterable={true}
                          data={this.state.teachers}
                          columns={[{
                            columns: [{
                              id: 'fullName',
                              Header: _.strings.teacherName,
                              accessor: 'fullName',
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)
                            }, {
                              Header: _.strings.districtName,
                              accessor: 'districtName',
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)
                            }, {
                              Header: _.strings.schoolName,
                              accessor: 'schoolName',
                              filterMethod: (filter, row) =>
                                row[filter.id].startsWith(filter.value)
                            }]
                          }]}
                          noDataText={_.strings.noDataText}
                          previousText={_.strings.previousText}
                          nextText={_.strings.nextText}
                          pageText={_.strings.pageText}
                          ofText={_.strings.ofText}
                          rowsText={_.strings.rowsText}
                          defaultPageSize={10}
                          sortable={true}
                          multiSort={false}
                          resizable={true}
                          showPageSizeOptions={true}
                          className="-striped -highlight"
                          style={{width: "1000px"}}
              />
            </td>
          </tr>
          </tbody>
        </table>
        }
        {(userData === undefined || userData === null) &&
        <span>Tizimga kirish uchun login varolni to`g`ri tering</span>
        }
      </div>
    )
  }
}

export default RegionTeacherList;