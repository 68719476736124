import React, {Component} from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import * as _ from './Utils';
import {Navigate} from "react-router";

class ShortCourseLoginPage extends Component {

    constructor() {
        super();
        this.state = {
            isNavigate: false,
        }
    }

    login() {
        let errors = 0;
        let userName = document.getElementById('userName').value;
        let password = document.getElementById('password').value;
        if (userName === '') {
            errors += 1;
        }
        if (password === '') {
            errors += 1;
        }
        if (+errors > 0) {
            swal('Login va parolni kiriting', '', 'error');
        } else {
            let data = new FormData();
            data.append('userName', userName);
            data.append('password', password);
            axios.post(_.apiHost + '/teacher/short/login', data)
                .then(result => {
                    if (result.data.statusCode === 200) {
                        localStorage.setItem('teacher', JSON.stringify(result.data.data));
                        window.open('/shortCourses', '_self');
                        /*this.setState({
                            isNavigate: true
                        })*/
                    } else {
                        swal(result.data.message, '', 'error');
                    }
                })
        }
    }

    render() {
        if (this.state.isNavigate) {
            return <Navigate to='/shortCourses'/>
        }
        return (
            <div className="container text-center">
                <div className="col-md-12 text-center" style={{margin: "auto"}}>
                    <form className="form-signin" method="post">
                        <h1 className="h4 mb-3 font-weight-normal">Tizimga kirish</h1>
                        <label htmlFor="userName" className="sr-only">Login</label>
                        <input type="text" id="userName" name="userName" className="form-control" placeholder="Login" required autoFocus="" onChange={this.setValue}/>
                        <label htmlFor="password" className="sr-only">Parol</label>
                        <input type="password" id="password" name="password" className="form-control" placeholder="Parol" required onChange={this.setValue}/>
                        <button className="btn btn-primary btn-block" type="button" onClick={this.login.bind(this)}>Kirish</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ShortCourseLoginPage;