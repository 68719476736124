import React, {Component} from 'react';
import {strings} from "./Utils";

class TestItem extends Component {
  onAnswerClick(answerId) {
    let rb = document.getElementById('rb' + answerId);
    rb.checked = true;
    this.props.toNextQuestion(answerId);
  }

  render() {
    let answerList = [];
    this.props.answers.forEach(answer => {
      answerList.push(
          <div id={'answer' + answer.id} key={answer.id} style={{textAlign: 'left', width: '100%', cursor: 'pointer'}} onClick={this.onAnswerClick.bind(this, answer.id)}>
            <input id={'rb' + answer.id} type="radio" name="answerRB" value={answer.answer} className="mr-2"/><span>{answer.answer}</span>
          </div>
      )
    });

    return (
        <div style={{textAlign: 'left'}}>
          <p>{strings.question + ' №: ' + this.props.questionOrder + '.  ' + this.props.question}</p>
          <div>
            {answerList}
          </div>
        </div>
    )
  }
}

export default TestItem;