import React, {Component} from 'react';
import './App.css';
import axios from "axios";
import * as _ from './Utils';
import TestWidget from "./TestWidget";
import Modal from 'react-responsive-modal';
import ReTrainingTestResult from "./ReTrainingTestResult";
import Swal from "sweetalert2";

class ReTrainingTestPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      teacherName: '',
      teacherId: null,
      question: '',
      questions: [],
      questionCount: 0,
      questionNumber: 0,
      pupilsAnswers: [],
      answers: [],
      fullAnswered: false,
      showResultPopup: false,
      testResult: ''
    }
  }

  componentDidMount() {
    let teacher = JSON.parse(localStorage.getItem('teacher'));
    if (teacher !== undefined && teacher !== null) {
      this.setState({
        teacherId: teacher.id,
        teacherName: teacher.name
      })
    }
    let methodId = localStorage.getItem('methodId');
    axios.get(_.apiHost + '/test/checkTestDone?methodId=' + methodId + '&teacherId=' + teacher.id).then(checkResult => {
      if (checkResult.data.statusCode === 200) {
        if (Number(checkResult.data.data) === 0) {
          axios.get(_.apiHost + '/test/reTrainingTest?methodId=' + methodId)
            .then(result => {
              if (result.data.statusCode === 200) {
                let pupilsAnswers = [];
                for (let i = 0; i < result.data.data.length; i++) {
                  pupilsAnswers.push(0);
                }
                this.setState({
                  questions: result.data.data,
                  question: result.data.data[0].question,
                  answers: result.data.data[0].answers,
                  pupilsAnswers: pupilsAnswers
                });
              }
            });
        } else {
          Swal.fire({
            title: 'Siz bu testdan avval o`tgansiz!',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then(() => {
            if (Number(methodId) === 53) {
              window.open('/moTest', '_self');
            } else {
              window.open('/shortCourses', '_self');
            }
          })
        }
      }
    })
  }

  toNextQuestion(pupilsAnswer) {
    let pupilsAnswers = [...this.state.pupilsAnswers];
    pupilsAnswers[this.state.questionNumber] = pupilsAnswer;
    let q_number = this.state.questionNumber < this.state.questions.length - 1 ? this.state.questionNumber + 1 : 0;
    let question = this.state.questions[q_number].question;
    console.log(pupilsAnswers);

    let fullAnswered = false;
    let count = 0;
    for (let i = 0; i < pupilsAnswers.length; i++) {
      if (Number(pupilsAnswers[i]) > 0) {
        count++;
      }
    }
    if (count === pupilsAnswers.length) {
      fullAnswered = true;
    }
    this.setState({
      pupilsAnswers: pupilsAnswers,
      questionNumber: q_number,
      question: question,
      fullAnswered: fullAnswered
    });

    let answers1 = this.state.questions[q_number].answers;
    this.setState({
      answers: answers1
    });
  }

  onFinishClick() {
    let tens = '';
    for (let i = 0; i < this.state.pupilsAnswers.length; i++) {
      tens = tens + this.state.pupilsAnswers[i] + '_';
    }
    let methodId = localStorage.getItem('methodId');
    let teacherId = JSON.parse(localStorage.getItem('teacher')).id;
    axios.post(_.apiHost + '/test/saveTeacherResult?methodId=' + methodId + '&teacherId=' + teacherId + '&testResult=' + tens)
      .then(result => {
        if (+result.data.statusCode === 200) {
          if (Number(methodId) === 15 || Number(methodId) === 53) {
            this.setState({
              showResultPopup: true,
              testResult: result.data.data
            });
          } else {
            Swal.fire({
              title: 'Test natijasi',
              text: result.data.data,
              icon: 'info',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then(() => {
              if (Number(methodId) === 53) {
                window.open('/moTest', '_self');
              } else {
                window.open('/shortCourses', '_self');
              }
            })
          }
        } else {
          Swal.fire({
            title: 'Xatolik',
            text: result.data.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
        }
      });
  }

  closeResultPopup() {
    let methodId = localStorage.getItem('methodId');
    this.setState({
      showResultPopup: false,
      question: '',
      testResult: '',
      questions: [],
      questionCount: 0,
      questionNumber: 0,
      answers: []
    });
    if (Number(methodId) === 53) {
      window.open('/moTest', '_self');
      localStorage.removeItem('teacher');
    } else {
      window.open('/shortCourses', '_self');
    }
  }

  render() {
    return (
      <div style={{width: "100%"}}>
        <div className="row">
          <div className="input-field col s12">
            <span>Test topshiruvchi: {this.state.teacherName}</span>
          </div>
        </div>
        <hr/>
        <div style={{textAlign: "left", marginLeft: "10px"}}>
          <TestWidget questionOrder={this.state.questionNumber + 1} question={this.state.question}
                      answers={this.state.answers} toNextQuestion={this.toNextQuestion.bind(this)}/>
        </div>
        {this.state.fullAnswered &&
        <div style={{width: '100%', padding: '20px'}}>
          <button className="btn btn-success" onClick={this.onFinishClick.bind(this)}>{_.strings.finish}</button>
        </div>
        }
        {this.state.showResultPopup &&
        <Modal center open={this.state.showResultPopup} onClose={this.closeResultPopup.bind(this)}>
          <ReTrainingTestResult teacherName={this.state.teacherName} testResult={this.state.testResult}
                                closeResultPopup={this.closeResultPopup.bind(this)}/>
        </Modal>
        }
      </div>
    )
  }
}

export default ReTrainingTestPage;