import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import {strings} from '../Utils';
import '../index.css';
// import './../bootstrap/4.4.1/css/bootstrap.css';
import ReactTable from 'react-table';
import Swal from 'sweetalert2';
import Modal from "react-responsive-modal";
import AddPupilView from "./AddPupilView";
import SchoolMenu from "./SchoolMenu";
import BatchImportPupils from "./BatchImportPupils";

class SchoolPupilList extends Component {

    constructor(props) {
        super(props);
        let userData = JSON.parse(localStorage.getItem('userData'));
        this.state = {
            districts: [],
            schools: [],
            pupil: null,
            pupils: [],
            addPupilModal: false,
            importPupilModal: false,
            editPupilModal: false,
            userData: userData
        }
    }

    componentDidMount() {
        this.getPupilList();
    }

    getPupilList() {
        let schoolId = this.state.userData !== undefined && this.state.userData !== null ? this.state.userData.stateId : null;
        let klass = document.getElementById('klass').value;
        let klassLetter = document.getElementById('klassLetter').value;
        if (klass === undefined || klass === null) {
            klass = 11;
        }
        axios.get(_.apiHost + '/pupil/list2?schoolId=' + schoolId + '&klass=' + klass + '&klassLetter=' + klassLetter)
            .then(result => {
                if (result.data.statusCode === 200) {
                    let resultData = result.data !== undefined && result.data !== null && result.data.data !== undefined && result.data.data !== null ? result.data.data : [];
                    this.setState({
                        pupils: resultData
                    });
                }
            })
    }

    openAddPupilForm() {
        this.setState({
            addPupilModal: true
        })
    }

    closeAddPupilForm() {
        this.setState({
            addPupilModal: false
        })
    }

    openImportPupilForm() {
        this.setState({
            importPupilModal: true
        })
    }

    closeImportPupilForm() {
        this.setState({
            importPupilModal: false
        })
    }

    closeEditPupilForm() {
        this.setState({
            pupil: null,
            editPupilModal: false
        })
    }

    downloadPDF(pupil) {
        const link = document.createElement('a');
        link.href = _.apiHost + '/files/' + pupil.testResultFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    editPupil(pupil) {
        this.setState({
            pupil: pupil,
            editPupilModal: true
        })
    }

    deletePupil(pupilId) {
        Swal.fire({
            title: "O`quvchi ma`lumotini o`chirasizmi?",
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ha',
            cancelButtonText: 'Yo`q'
        }).then((result) => {
            if (result.value) {
                axios.post(_.apiHost + '/pupil/delete?pupilId=' + pupilId)
                    .then(data => {
                        if (data.data.statusCode === 200) {
                            this.getPupilList();
                            Swal.fire(
                                'O`chirildi!',
                                'O`quvchi ma`lumotlari o`chirildi',
                                'success'
                            )
                        } else {
                            Swal.fire(
                                'Xatolik',
                                'O`quvchi ma`lumotlarini o`chirishda xatolik',
                                'error'
                            )
                        }
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    'O`quvchi ma`lumotini o`chirish bekor qilindi',
                    'info'
                )
            }
        })
    }

    render() {
        return (
            <div>
                <SchoolMenu/>
                {this.state.userData !== undefined && this.state.userData !== null &&
                <div className="container card card-body my-4 shadow-sm">
                    <div className="row">
                        <div className="col-md-2">
                            <select id="klass" className="form-control" onChange={this.getPupilList.bind(this)}>
                                <option value="11">11</option>
                                <option value="10">10</option>
                                <option value="9">9</option>
                                <option value="8">8</option>
                                <option value="7">7</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select id="klassLetter" className="form-control" onChange={this.getPupilList.bind(this)}>
                                <option value=""> sinf</option>
                                <option value="A">A-sinf</option>
                                <option value="B">B-sinf</option>
                                <option value="V">V-sinf</option>
                                <option value="G">G-sinf</option>
                                <option value="D">D-sinf</option>
                                <option value="E">E-sinf</option>
                                <option value="J">J-sinf</option>
                                <option value="Z">Z-sinf</option>
                                <option value="I">I-sinf</option>
                                <option value="K">K-sinf</option>
                                <option value="L">L-sinf</option>
                                <option value="M">M-sinf</option>
                                <option value="N">N-sinf</option>
                                <option value="O">O-sinf</option>
                                <option value="P">P-sinf</option>
                                <option value="Q">Q-sinf</option>
                                <option value="R">R-sinf</option>
                                <option value="S">S-sinf</option>
                                <option value="T">T-sinf</option>
                                <option value="U">U-sinf</option>
                                <option value="F">F-sinf</option>
                                <option value="X">X-sinf</option>
                            </select>
                        </div>
                        <div className="col-md-3"/>
                        <div className="col-md-2">
                            <button className="btn btn-success" onClick={this.openAddPupilForm.bind(this)}>O`quvchi
                                qo`shish
                            </button>
                        </div>
                        <div className="col-md-3 text-right">
                            <button className="btn btn-success btn-block" onClick={this.openImportPupilForm.bind(this)}>
                                Exceldan import qilish
                            </button>
                        </div>
                    </div>

                    <Modal open={this.state.addPupilModal} center closeOnEsc={true}
                           onClose={this.closeAddPupilForm.bind(this)}>
                        <AddPupilView/>
                    </Modal>
                    <Modal open={this.state.importPupilModal} center closeOnEsc={true}
                           onClose={this.closeImportPupilForm.bind(this)}>
                        <BatchImportPupils/>
                    </Modal>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <ReactTable data={this.state.pupils}
                                        columns={[{
                                            columns: [{
                                                Header: '№',
                                                accessor: 'order',
                                                width: 50
                                            }, {
                                                Header: _.strings.klass,
                                                accessor: 'fullKlass'
                                            }, {
                                                Header: _.strings.lastName,
                                                accessor: 'lastName'
                                            }, {
                                                Header: _.strings.firstName,
                                                accessor: 'firstName'
                                            }, {
                                                Header: _.strings.middleName,
                                                accessor: 'middleName'
                                            }, {
                                                Header: _.strings.language,
                                                accessor: 'language.name'
                                            }, {
                                                Header: _.strings.birthDate,
                                                accessor: 'birthDate'
                                            }, {
                                                Header: _.strings.action,
                                                Cell: p => (
                                                    <div>
                                                        {p.original.testResultFileLink !== null && p.original.testResultFileLink !== '' &&
                                                        <button type="button" className="btn btn-default">
                                                            <i class="fa fa-file-word-o" style={{fontSize: "24px"}}
                                                               aria-hidden="true" title="Word"
                                                               onClick={this.downloadPDF.bind(this, p.original)}></i>
                                                        </button>
                                                        }
                                                        <button type="button" className="btn btn-default" aria-label="Left Align">
                                                            <i class="fa fa-edit" style={{fontSize: "24px"}} aria-hidden="true" title={strings.edit} onClick={this.editPupil.bind(this, p.original)}></i>
                                                        </button>
                                                        <button type="button" className="btn btn-default" aria-label="Left Align">
                                                            <i class="material-icons" aria-hidden="true" title={strings.delete} onClick={this.deletePupil.bind(this, p.original.id)}>delete_forever</i>
                                                        </button>
                                                    </div>
                                                )
                                            }]
                                        }]}
                                        noDataText={_.strings.noDataText}
                                        previousText={_.strings.previousText}
                                        nextText={_.strings.nextText}
                                        pageText={_.strings.pageText}
                                        ofText={_.strings.ofText}
                                        rowsText={_.strings.rowsText}
                                        defaultPageSize={10}
                                        multiSort={false}
                                        resizable={true}
                                        showPageSizeOptions={true}
                                        className="-striped -highlight"
                                        style={{width: "100%"}}
                            />
                            <Modal open={this.state.editPupilModal} center closeOnEsc={true}
                                   onClose={this.closeEditPupilForm.bind(this)}>
                                <AddPupilView pupil={this.state.pupil}/>
                            </Modal>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default SchoolPupilList;