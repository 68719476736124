import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import * as _ from './Utils';
import axios from "axios";
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/uz-latn';
import 'moment-timezone';
import Header from "./Header";
import {Link} from 'react-router-dom';

class VideoPage extends Component {

  constructor(props) {
    super(props);
	  const urlParams = new URLSearchParams(window.location.search);
	  const language = urlParams.get('lang');
    this.state = {
      videoList: [],
      thumbnails: [],
      oldVideos: [],
      timeShift: [],
      currentDate: new Date(),
	    language: language
    }
  }

  componentDidMount() {
    let klass = this.props.match.params.klass;
    let currentDate = this.state.currentDate;
    axios.get(_.apiHost + '/onlineStudy/video/list?klass=' + klass + '&language=' + this.state.language + '&date=')
      .then(result => {
        if (result.data.statusCode === 200) {
          let oldVideos = [];
          for (let i = 0; i < result.data.data.length; i++) {
            oldVideos.push(<Moment locale="uz" format="DD MMMM"
                                   className="dateStyle btn" onClick={this.getDateVideos.bind(this, result.data.data[i].name)}>{result.data.data[i].name}</Moment>)
          }

          this.setState({
            oldVideos: oldVideos
          })
        }
      });
    //Get time shift
    axios.get(_.apiHost + '/onlineStudy/timeShift?klass=' + klass + '&language=' + this.state.language + '&date=' + moment(new Date()).format('DD.MM.YYYY'))
      .then(result => {
        if (result.data.statusCode === 200) {
          let timeShift = [];
          for (let i = 0; i < result.data.data.length; i++) {
            let datum = result.data.data[i];
            timeShift.push(<tr><td>{datum.science}</td><td>{datum.teacher}</td></tr>);
          }
          this.setState({
            timeShift: timeShift
          })
        }
      });
    this.getVideos(klass, currentDate);
  }

  getVideos(klass, date) {
    axios.get(_.apiHost + '/onlineStudy/video/list?klass=' + klass + '&language=' + this.state.language + '&date=' + moment(date, 'YYYY-MM-DD HH:mm'))
      .then(result => {
        if (result.data.statusCode === 200) {
          let thumbnails = [];
          for (let i = 0; i < result.data.data.length; i++) {
            thumbnails.push(<ReactPlayer className='react-player' width="300px" height="100%"
                                         url={_.apiHost + result.data.data[i].url} controls/>)
          }

          this.setState({
            videoList: result.data.data,
            thumbnails: thumbnails
          })
        }
      })
  }

  getDateVideos(date) {
    this.setState({
      currentDate: date
    });
    let klass = this.props.match.params.klass;
    this.getVideos(klass, date);
  }

  render() {
    return (
      <div>
        <Header/>
        <div className="form-row text-center">
          <div className="col-md-6">
            {this.state.oldVideos}
          </div>
          <div className="col-md-6">
            <p><b>{this.state.language === 'ru' ? 'Расписание уроков' : 'Dars jadvali'} (<Moment locale="uz" format="DD.MM.YYYY">{new Date()}</Moment>)</b></p>
            <table>
              <tbody>
                {this.state.timeShift}
              </tbody>
            </table>
          </div>
        </div>

        {this.state.videoList.length > 0 &&
        <div className="text-center">
          <Moment locale="uz" format="DD MMMM">{this.state.currentDate}</Moment>
          <div className="videoFiles center">
            {this.state.thumbnails}
          </div>
        </div>
        }
        <div className="form-row text-center">
          <div className="col-md-12">
            <Link className="btn btn-success" to="/online">{this.state.language === 'ru' ? 'Назад' : 'Ortga qaytish'}</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default VideoPage;