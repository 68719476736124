import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import '../index.css';
import swal from 'sweetalert';

class AddPupilForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      districts1: [],
      schools1: []
    }
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let regionId = userData !== undefined && userData !== null ? userData.stateId : null;
    axios.get(_.apiHost + '/pupil/district/list?stateId=' + regionId)
      .then(result => {
        if (result.data.statusCode === 200) {
          let result1 = result.data.data !== undefined ? result.data.data : [];
          if (result1.length > 0) {
            let options = [];
            for (let i = 0; i < result1.length; i++) {
              let user = result1[i];
              options.push(<option key={user.id} value={user.id}>{user.name}</option>)
            }
            this.setState({
              districts1: options
            })
          }
        }
      })
  }

  getSchoolList() {
    let districtId = document.getElementById('districts1').value;
    axios.get(_.apiHost + '/pupil/school/list?districtId=' + districtId)
      .then(result => {
        if (result.data.statusCode === 200) {
          let resultData = result.data.data !== undefined ? result.data.data : [];
          if (resultData.length > 0) {
            let options = [];
            for (let i = 0; i < resultData.length; i++) {
              let item = resultData[i];
              options.push(<option key={item.id} value={item.id}>{item.name}</option>)
            }
            this.setState({
              schools1: options
            })
          }
        }
      })
  }

  savePupil() {
    let schoolId = document.getElementById('schools1').value;
    let klass = document.getElementById('klasses').value;
    let lastName = document.getElementById('lastName').value;
    let firstName = document.getElementById('firstName').value;
    let middleName = document.getElementById('middleName').value;
    let errors = 0;
    errors = _.validateSelect('schools1', errors);
    errors = _.validateField('klasses', klass, errors);
    errors = _.validateField('lastName', lastName, errors);
    errors = _.validateField('firstName', firstName, errors);
    errors = _.validateField('middleName', middleName, errors);
    if (errors === 0) {
      let formData = new FormData();
      formData.append("schoolId", schoolId);
      formData.append("klass", klass);
      formData.append("lastName", lastName);
      formData.append("firstName", firstName);
      formData.append("middleName", middleName);
      axios.post(_.apiHost + '/pupil/add', formData)
        .then(result => {
          if (result.data.statusCode === 200) {
            swal("Ma'lumot saqlandi", "", 'success');
          } else {
            swal("Ma'lumot saqlashda xatolik", "", 'error');
          }
        })
    } else {
      swal("Hamma ma'lumotlarni kiriting", "", 'error');
    }
  }

  render() {
    return (
      <div className="row">
        <form className="col s12" style={{padding: "20px", width: "450px"}}>
          <div className="row">
            <div className="input-field col s6">
              <span>Tumani</span>
            </div>
            <div className="input-field col s6">
              <select id="districts1" onChange={this.getSchoolList.bind(this)}>
                {this.state.districts1}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s6">
              <span>Maktabi</span>
            </div>
            <div className="input-field col s6">
              <select id="schools1">
                {this.state.schools1}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s6">
              <span>Sinfi</span>
            </div>
            <div className="input-field col s6">
              <select id="klasses">
                <option value="11">11-sinf</option>
                <option value="10">10-sinf</option>
                <option value="9">9-sinf</option>
                <option value="8">8-sinf</option>
                <option value="7">7-sinf</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s6">
              <span>Familiyasi</span>
            </div>
            <div className="input-field col s6">
              <input id="lastName" type="text" className="validate"/>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s6">
              <span>Ismi</span>
            </div>
            <div className="input-field col s6">
              <input id="firstName" type="text" className="validate"/>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s6">
              <span>Sharifi</span>
            </div>
            <div className="input-field col s6">
              <input id="middleName" type="text" className="validate"/>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s6">
              <span>&nbsp;</span>
            </div>
            <div className="input-field col s6">
              <button className="btn btn-block" onClick={this.savePupil.bind(this)}>Saqlash</button>
            </div>
          </div>
        </form>
      </div>

    )
  }
}

export default AddPupilForm;