import React, {Component} from 'react';
import axios from 'axios';
import * as _ from '../Utils';
import swal from 'sweetalert';
import AdminMenu from "../admin/AdminMenu";
import TashxisMenu from "../admin/TashxisMenu";

class AddTeacherForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      districts: [],
      schools: [],
      groups: [],
      userName: ''
    }
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let stateId = null;
    if (userData !== undefined && userData !== null) {
      stateId = userData.stateId;
      this.setState({
        userName: userData.userName
      })
    }
    axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
      .then(data => {
        if (data.data.statusCode === 200) {
          let districtList = [];
          for (let i = 0; i < data.data.data.length; i++) {
            let user = data.data.data[i];
            districtList.push(<option key={user.id} value={user.id}>{user.name}</option>)
          }
          this.setState({
            districts: districtList
          })
        } else {
          this.setState({
            districts: []
          })
        }
      });

    let groupList = [];
    for (let i = 1; i <= 50; i++) {
      groupList.push(<option key={i} value={i}>{i + '-guruh'}</option>)
    }
    this.setState({
      groups: groupList
    })
  }

  getDistricts(e) {
    let stateId = e.target.value;
    axios.get(_.apiHost + '/pupil/district/list?stateId=' + stateId)
      .then(data => {
        if (data.data.statusCode === 200) {
          let districtList = [];
          for (let i = 0; i < data.data.data.length; i++) {
            let user = data.data.data[i];
            districtList.push(<option key={user.id} value={user.id}>{user.name}</option>)
          }
          this.setState({
            districts: districtList
          })
        } else {
          this.setState({
            districts: []
          })
        }
      })
  }

  saveTeacher() {
    let errors = 0;
    let districtId = document.getElementById("districts").value;
    errors = _.validateSelect('districts', errors);
    let schoolName = document.getElementById("school").value;
    errors = _.validateField('school', schoolName, errors);
    let relatedTo = document.getElementById("relatedTo").value;
    errors = _.validateField('relatedTo', relatedTo, errors);
    let group = document.getElementById("group").value;
    errors = _.validateField('group', group, errors);
    let lastName = document.getElementById("lastName").value;
    errors = _.validateField('lastName', lastName, errors);
    let firstName = document.getElementById("firstName").value;
    errors = _.validateField('firstName', firstName, errors);
    let middleName = document.getElementById("middleName").value;
    errors = _.validateField('middleName', middleName, errors);

    if (+errors === 0) {
      let formData = new FormData();
      formData.append("districtId", districtId);
      formData.append("schoolName", schoolName);
      formData.append("relatedTo", relatedTo);
      formData.append("groupNumber", group);
      formData.append("lastName", lastName);
      formData.append("firstName", firstName);
      formData.append("middleName", middleName);

      axios.post(_.apiHost + '/teacher/add1', formData)
        .then(result => {
          if (result.data.statusCode === 200) {
            swal(result.data.message, '', 'success');
            // window.open('/addTeacher', '_self')
          } else {
            swal(result.data.message, '', 'error');
          }
        })
    } else {
      swal("Hamma ma'lumotlarni kiriting", "", 'error');
    }
  }

  render() {
    return (
      <div>
        <div id='__react-content' className="flt topblock">
          {_.isAdmin() &&
          <AdminMenu/>
          }
          {_.isTashxis() &&
          <TashxisMenu/>
          }
        </div>
        {(this.state.userName === 'admin' || this.state.userName === 'tashxis') &&
        <div className="row">
          <div className="col s6">
            <span>Viloyat</span>
          </div>
          <div className="col s6">
            <select id="regions" name="regions" onChange={this.getDistricts.bind(this)}>
              <option value="1">Qoraqalpog`iston Respublikasi</option>
              <option value="2">Andijon viloyati</option>
              <option value="3">Buxoro viloyati</option>
              <option value="4">Qashqadaryo viloyati</option>
              <option value="5">Jizzax viloyati</option>
              <option value="6">Navoiy viloyati</option>
              <option value="7">Namangan viloyati</option>
              <option value="8">Samarqand viloyati</option>
              <option value="9">Sirdaryo viloyati</option>
              <option value="10">Surxondaryo viloyati</option>
              <option value="11">Toshkent viloyati</option>
              <option value="12">Farg`ona viloyati</option>
              <option value="13">Xorazm viloyati</option>
              <option value="14">Toshkent shahri</option>
            </select>
          </div>
        </div>
        }
        <div className="row">
          <div className="col s6">
            <span>Tuman</span>
          </div>
          <div className="col s6">
            <select id="districts" name="districts" onChange={_.selectValueChanged}>
              <option value={0}>Tanlang</option>
              {this.state.districts}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <span>Maktab</span>
          </div>
          <div className="col s6">
            <input id="school" name="school" type="text" placeholder="Tashkilot nomi"
                   onChange={_.inputValueChanged}/>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <span>Guruh</span>
          </div>
          <div className="col s6">
            <select id="group" name="group" type="text" onChange={_.inputValueChanged}>
              {this.state.groups}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <span>Turi</span>
          </div>
          <div className="col s6">
            <select id="relatedTo">
              <option value="MALAKA_OSHIRISH">Malaka oshirish</option>
              <option value="QAYTA_TAYYORLOV">Qayta tayyorlov</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <span>Familiyasi</span>
          </div>
          <div className="col s6">
            <input id="lastName" name="lastName" type="text" placeholder="Familiyasi" onChange={_.inputValueChanged}/>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <span>Ismi</span>
          </div>
          <div className="col s6">
            <input id="firstName" name="firstName" type="text" placeholder="Ismi" onChange={_.inputValueChanged}/>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <span>Sharifi</span>
          </div>
          <div className="col s6">
            <input id="middleName" name="middleName" type="text" placeholder="Sharifi" onChange={_.inputValueChanged}/>
          </div>
        </div>
        <div className="row">
          <div className="col s6">

          </div>
          <div className="col s6">
            <button className="btn btn-block" onClick={this.saveTeacher.bind(this)}>Saqlash</button>
          </div>
        </div>
      </div>
    )
  }
}

export default AddTeacherForm;