import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import './../bootstrap/4.4.1/css/bootstrap.min.css';

import "react-datepicker/dist/react-datepicker.css";

class AddPupilView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            birthDate: new Date(),
            pupil: props.pupil !== undefined && props.pupil !== null ? props.pupil : {
                id: null,
                klass: 11,
                klassLetter: "",
                lastName: "",
                firstName: "",
                middleName: "",
                birthDate2: new Date().getTime(),
                language: {
                    id: 1,
                    name: "O`zbek"
                }
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            pupil: {
                ...this.state.pupil,
                birthDate2: date.getTime()
            }
        })
    }

    savePupil() {
        let userData = JSON.parse(localStorage.getItem('userData'));
        let schoolId = userData !== undefined && userData !== null ? userData.stateId : null;
        let klass = document.getElementById('klasses').value;
        let klassLetter = document.getElementById('klassLetters').value;
        let lastName = document.getElementById('lastName').value;
        let firstName = document.getElementById('firstName').value;
        let middleName = document.getElementById('middleName').value;
        let language = document.getElementById('languages').value;
        let errors = 0;
        errors = _.validateField('lastNameDiv', lastName, errors);
        errors = _.validateField('firstNameDiv', firstName, errors);
        errors = _.validateField('middleNameDiv', middleName, errors);
        errors = _.validateDateField('birthDateDiv', this.state.pupil.birthDate2, errors);
        errors = _.validateField('languagesDiv', language, errors);
        if (errors === 0) {
            let formData = new FormData();
            formData.append("klass", klass);
            formData.append("klassLetter", klassLetter);
            formData.append("lastName", lastName);
            formData.append("firstName", firstName);
            formData.append("middleName", middleName);
            formData.append("birthDate", this.state.pupil.birthDate2);
            formData.append("language", language);
            if (this.state.pupil.id === null) {
                formData.append("schoolId", schoolId);
                axios.post(_.apiHost + '/pupil/add1', formData)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            swal("Ma'lumot saqlandi", "", 'success');
                        } else {
                            swal("Ma'lumot saqlashda xatolik", "", 'error');
                        }
                    })
            } else if (this.state.pupil.id !== null) {
                formData.append("id", this.state.pupil.id);
                axios.post(_.apiHost + '/pupil/edit', formData)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            swal("Ma'lumot yangilandi", "", 'success');
                        } else {
                            swal("Ma'lumot saqlashda xatolik", "", 'error');
                        }
                    })
            }
        } else {
            swal("Hamma ma'lumotlarni kiriting", "", 'error');
        }
    }

//District0207   g00e30t05
    render() {
        return (
            <div className="content">
                <div className="container card card-body my-4 shadow-sm">
                    <div className="form-row">
                        <div className="col-md-5">
                            <span>Sinfi</span>
                        </div>
                        <div className="col-md-3">
                            <select id="klasses" className="form-control" defaultValue={this.state.pupil.klass}>
                                <option value="11">11</option>
                                <option value="10">10</option>
                                <option value="9">9</option>
                                <option value="8">8</option>
                                <option value="7">7</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <select id="klassLetters" className="form-control" defaultValue={this.state.pupil.klassLetter}>
                                <option value="0"></option>
                                <option value="A">A-sinf</option>
                                <option value="B">B-sinf</option>
                                <option value="V">V-sinf</option>
                                <option value="G">G-sinf</option>
                                <option value="D">D-sinf</option>
                                <option value="E">E-sinf</option>
                                <option value="J">J-sinf</option>
                                <option value="Z">Z-sinf</option>
                                <option value="I">I-sinf</option>
                                <option value="K">K-sinf</option>
                                <option value="L">L-sinf</option>
                                <option value="M">M-sinf</option>
                                <option value="N">N-sinf</option>
                                <option value="O">O-sinf</option>
                                <option value="P">P-sinf</option>
                                <option value="Q">Q-sinf</option>
                                <option value="R">R-sinf</option>
                                <option value="S">S-sinf</option>
                                <option value="T">T-sinf</option>
                                <option value="U">U-sinf</option>
                                <option value="F">F-sinf</option>
                                <option value="X">X-sinf</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-5">
                            <span>Familiyasi</span>
                        </div>
                        <div className="col-md-7" id="lastNameDiv">
                            <input id="lastName" type="text" className="validate form-control" defaultValue={this.state.pupil.lastName}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-5">
                            <span>Ismi</span>
                        </div>
                        <div className="col-md-7" id="firstNameDiv">
                            <input id="firstName" type="text" className="validate form-control" defaultValue={this.state.pupil.firstName}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-5">
                            <span>Sharifi</span>
                        </div>
                        <div className="col-md-7" id="middleNameDiv">
                            <input id="middleName" type="text" className="validate form-control" defaultValue={this.state.pupil.middleName}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-5">
                            <span>Tug`ilgan sanasi</span>
                        </div>
                        <div className="col-md-7" id="birthDateDiv">
                            <DatePicker id="birthDate" selected={this.state.pupil.birthDate2}
                                        onChange={this.handleChange}
                                        name="startDate"
                                        dateFormat="MM/dd/yyyy" className="validate form-control"/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-5">
                            <span>Ta'lim tili</span>
                        </div>
                        <div className="col-md-7" id="languagesDiv">
                            <select id="languages" className="form-control" defaultValue={this.state.pupil.language}>
                                <option value="1">O`zbek</option>
                                <option value="2">Rus</option>
                                <option value="3">Qoraqalpoq</option>
                                <option value="4">Qozoq</option>
                                <option value="5">Qirg`iz</option>
                                <option value="6">Tojik</option>
                                <option value="7">Turkman</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-5">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-md-7">
                            <button className="btn btn-block btn-success" onClick={this.savePupil.bind(this)}>Saqlash</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddPupilView;