import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Home from './Home';
import Testing from './Testing';
import PsychologistList from "./PsychologistList";
import AddPsychologist from "./region/AddPsychologist";
import PupilResults from "./PupilResults";
import Schools from "./Schools";
import Help from './Help';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TeachersTest from "./TeachersTest";
import RegionHomePage from "./region/RegionHomePage";
import RegionTeacherList from "./region/RegionTeacherList";
import AddTeacherForm from "./region/AddTeacherForm";
import ReTrainingTestStartPage from "./ReTrainingTestStartPage";
import AdminHomePage from "./admin/AdminHomePage";
import AdminTeacherList from "./admin/AdminTeacherList";
import ReTrainingTestPage from "./ReTrainingTestPage";
import AdminTeacherTestResultList from "./admin/AdminTeacherTestResultList";
import TashxisHomePage from "./admin/TashxisHomePage";
import ReportForm from "./admin/ReportForm";
import AddPupilForm from "./region/AddPupilForm";
import RegionPupilList from "./region/RegionPupilList";
import BatchImportTeachers from "./admin/BatchImportTeachers";
import ImproveSkillsTestStartPage from "./ImproveSkillsTestStartPage";
import ImproveSkillsTestPage from "./ImproveSkillsTestPage";
import AddPupilView from "./school/AddPupilView";
import SchoolHomePage from "./school/SchoolHomePage";
import SchoolPupilList from "./school/SchoolPupilList";
import BatchImportPupils from "./school/BatchImportPupils";
import DistrictHomePage from "./district/DistrictHomePage";
import PupilsTest11 from "./PupilsTest11";
import PupilSurveyView from "./school/PupilSurveyView";
import SchoolPupilSurveyList from "./school/SchoolPupilSurveyList";
import RegionPupilReport1View from "./reports/RegionPupilReport1View";
import RegionPupilReport2View from "./reports/RegionPupilReport2View";
import RegionPupilReport3View from "./reports/RegionPupilReport3View";
import RegionPupilCountReport from "./region/RegionPupilCountReport";
import PupilsTest5 from "./PupilsTest5";
import Testing5 from "./Testing5";
import ChooseClass from "./ChooseClass";
import VideoPage from "./VideoPage";
import RegionPupilReport4View from "./reports/RegionPupilReport4View";
import PublicTest from "./PublicTest";
import CertificationTest from "./CertificationTest";
import AddSchoolView from "./admin/AddSchoolView";
import ShortTimeCoursesStartPage from "./ShortTimeCoursesStartPage";
import ShortTimeCoursesPage from "./ShortTimeCoursesPage";
import ShortTimeCoursesSummaryPage from "./ShortTimeCoursesSummaryPage";
import ShortCourseLoginPage from "./ShortCourseLoginPage";
import TeachersPublicTest from "./TeachersPublicTest";
import TeacherPsychTestResultList from "./admin/TeacherPsychTestResultList";
import AddTeacherView from "./region/AddTeacherView";
import TeachersPublicTest2 from "./TeachersPublicTest2";
import SeminarExamStartView from "./SeminarExamStartView";
import ReTrainingTestStartPage2 from "./ReTrainingTestStartPage2";
import * as _ from "./Utils";
import AdminMenu from "./admin/AdminMenu";
import TashxisMenu from "./admin/TashxisMenu";

class App extends Component {

    render() {
        return (
            <>
                <div className="topBlock">
                    {_.isAdmin() &&
                        <AdminMenu/>
                    }
                    {_.isTashxis() &&
                        <TashxisMenu/>
                    }
                </div>
                <div className="container-fluid content">
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/testing" component={Testing}/>
                        <Route path="/schools" component={Schools}/>
                        <Route path="/help" component={Help}/>
                        <Route path="/addPupil" component={AddPupilForm}/>
                        <Route path="/online" component={ChooseClass}/>
                        <Route path="/video/:klass" component={VideoPage}/>
                        <Route path="/certTest" component={CertificationTest}/>
                        <Route path="/startCourse" component={ShortTimeCoursesStartPage}/>
                        <Route path="/shortCourses" component={ShortTimeCoursesPage}/>
                        <Route path="/courseView" component={ShortTimeCoursesSummaryPage}/>
                        <Route path="/courseLogin" component={ShortCourseLoginPage}/>
                        <Route path="/seminarExam" component={SeminarExamStartView}/>
                        {/*<Route path="/semExam" component={SeminarExamStartView2}/>*/}

                        {/*Tashxis cabinet*/}
                        <Route path="/tashxisHome" component={TashxisHomePage}/>
                        <Route path="/addTeacher" component={AddTeacherForm}/>
                        <Route path="/addTeacher2" component={AddTeacherView}/>

                        {/*Admin cabinet*/}
                        <Route path="/batchImport" component={BatchImportTeachers}/>
                        <Route path="/adminHome" component={AdminHomePage}/>
                        <Route path="/atList" component={AdminTeacherList}/>
                        <Route path="/attTestStart" component={ReTrainingTestStartPage}/>
                        <Route path="/skillTestStart" component={ImproveSkillsTestStartPage}/>
                        <Route path="/rtTest" component={ReTrainingTestPage}/>
                        <Route path="/istTest" component={ImproveSkillsTestPage}/>
                        <Route path="/attTestResult" component={AdminTeacherTestResultList}/>
                        <Route path="/psychTestResult" component={TeacherPsychTestResultList}/>
                        <Route path="/reportHome" component={ReportForm}/>
                        <Route path="/addSchool" component={AddSchoolView}/>

                        {/*School cabinet*/}
                        <Route path="/schoolHome" component={SchoolHomePage}/>
                        <Route path="/addSPupil" component={AddPupilView}/>
                        <Route path="/spList" component={SchoolPupilList}/>
                        <Route path="/importPupils" component={BatchImportPupils}/>
                        <Route path="/publicTest" component={PublicTest}/>
                        <Route path="/pupilsTest" component={PupilsTest11}/>
                        <Route path="/pupilsTest5" component={PupilsTest5}/>
                        <Route path="/testing5" component={Testing5}/>
                        <Route path="/testResults" component={PupilResults}/>
                        <Route path="/survey" component={PupilSurveyView}/>
                        <Route path="/surveyList" component={SchoolPupilSurveyList}/>
                        <Route path="/teachersTest" component={TeachersTest}/>
                        <Route path="/teachersTest2" component={TeachersPublicTest}/>
                        <Route path="/teachersTest3" component={TeachersPublicTest2}/>

                        {/*District cabinet*/}
                        <Route path="/districtHome" component={DistrictHomePage}/>
                        <Route path="/addPsychologist" component={AddPsychologist}/>

                        {/*State cabinet*/}
                        <Route path="/regionHome" component={RegionHomePage}/>
                        <Route path="/rplist" component={RegionPupilList}/>
                        <Route path="/rtlist" component={RegionTeacherList}/>
                        <Route path="/psychList" component={PsychologistList}/>

                        {/*Reports*/}
                        <Route path="/report1" component={RegionPupilReport1View}/>
                        <Route path="/report2" component={RegionPupilReport2View}/>
                        <Route path="/report3" component={RegionPupilReport3View}/>
                        <Route path="/report4" component={RegionPupilReport4View}/>
                        <Route path="/report5" component={RegionPupilCountReport}/>

                        <Route path="/moTest" component={ReTrainingTestStartPage2}/>
                        <Route path="/moTest2" component={ReTrainingTestPage}/>

                    </Switch>
                </div>
            </>
        );
    }
}

export default withRouter(App);