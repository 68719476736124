import React, {Component} from 'react';
import axios from "axios";
import * as _ from './../Utils';
import Swal from 'sweetalert2';

class PupilSurveyView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            survey: props.survey,
            pupil: props.pupil,
            instType: 1,
            tendencies: [],
            institutions: [],
            ministries: [],
        }
    }

    componentDidMount() {
        if (this.state.survey !== null) {
            if (this.state.survey.studyType !== null && (this.state.survey.studyType.id === 2 || this.state.survey.studyType.id === 3)) {
                this.getColleges(this.state.survey.region.id, this.state.survey.studyType.id);
                this.getTendencies1(this.state.survey.studyType.id);
            }
        }
    }

    getTendencies(collegeType) {
        let tendencies = [];
        axios.get(_.apiHost + '/colleges/tendencies?collegeType=' + collegeType)
            .then(result => {
                if (result.data.statusCode === 200) {
                    let a_hashmap = result.data.data;
                    for (let j = 0; j < a_hashmap.length; j++) {
                        tendencies.push(
                            <option key={a_hashmap[j].id} value={a_hashmap[j].id}>{a_hashmap[j].name}</option>
                        );
                    }
                    this.setState({
                        instType: Number(collegeType),
                        tendencies: tendencies
                    });
                }
            });
    }

    inst1Change(e) {
        let inst1Value = e.target.value;
        this.getTendencies1(inst1Value);
    }

    getTendencies1(inst1Value) {
        if (Number(inst1Value) === 2) {
            this.getTendencies(2);
        } else if (Number(inst1Value) === 3) {
            this.getTendencies(1);
        }
    }

    onChangeStates(e) {
        let studyType = document.getElementById('studyType').value;
        if (+studyType === 3) {
            studyType = 1
        }
        this.getColleges(e.target.value, studyType);
    }

    getColleges(regionId, studyType) {
        axios.get(_.apiHost + '/colleges/list?regionId=' + regionId + '&collegeType=' + studyType)
            .then(result => {
                if (result.data.statusCode === 200) {
                    let colleges = [];
                    for (let i = 0; i < result.data.data.length; i++) {
                        let user = result.data.data[i];
                        colleges.push(<option key={user.id} value={user.id}>{user.name}</option>)
                    }
                    this.setState({
                        institutions: colleges
                    })
                } else {
                    this.setState({
                        institutions: []
                    })
                }
            })
    }

    saveSurvey() {
        let errors = 0, haveReduction = 0, choiceType = 0, language = 0;

        let studyType = document.getElementById('studyType').value;
        errors = _.validateField('studyType', studyType, errors);

        let haveReduction1 = document.getElementById('haveReduction1').checked;
        if (haveReduction1) {
            haveReduction = 1;
        }
        let haveReduction2 = document.getElementById('haveReduction2').checked;
        if (haveReduction2) {
            haveReduction = 2
        }
        let haveReduction3 = document.getElementById('haveReduction3').checked;
        if (haveReduction3) {
            haveReduction = 3
        }
        errors = errors + _.checkNumberValid(haveReduction) ? 0 : 1;

        let institutions = 0, tendencies = 0;
        if (studyType > 1) {
            institutions = document.getElementById('institutions').value;
            errors = errors + _.checkValid(institutions) ? 0 : 1;
            tendencies = document.getElementById('tendencies').value;
            errors = errors + _.checkValid(tendencies) ? 0 : 1;
        }

        let option1 = document.getElementById('option1').checked;
        if (option1) {
            choiceType = 1;
        }
        let option2 = document.getElementById('option2').checked;
        if (option2) {
            choiceType = 2;
        }
        let option3 = document.getElementById('option3').checked;
        if (option3) {
            choiceType = 3;
        }
        let option4 = document.getElementById('option4').checked;
        if (option4) {
            choiceType = 4;
        }
        errors = errors + _.checkNumberValid(choiceType) ? 0 : 1;

        let language1 = document.getElementById('language1').checked;
        if (language1) {
            language = 1;
        }
        let language2 = document.getElementById('language2').checked;
        if (language2) {
            language = 2;
        }
        let language3 = document.getElementById('language3').checked;
        if (language3) {
            language = 3;
        }
        errors = errors + _.checkNumberValid(language) ? 0 : 1;

        if (errors === 0) {
            let formData = new FormData();
            formData.append("pupilId", this.state.pupil.id);
            formData.append("haveReduction", haveReduction);
            formData.append("studyType", studyType);
            formData.append("institution", institutions);
            formData.append("tendency", tendencies);
            formData.append("choiceType", choiceType);
            formData.append("language", language);
            axios.post(_.apiHost + '/pupil/saveSurvey', formData)
                .then(result => {
                    if (result.data.statusCode === 200) {
                        this.props.reloadList();
                        Swal.fire(
                            'Info',
                            "Ma'lumot saqlandi",
                            'success'
                        ).then((result) => {
                            this.props.onClose();
                        })
                    } else {
                        Swal.fire(
                            'Xatolik!',
                            "Ma'lumot saqlashda xatolik",
                            'error'
                        )
                    }
                })
        } else {
            Swal.fire(
                'Xatolik!',
                "Hamma ma'lumotlarni kiriting",
                'error'
            )
        }
    }

    render() {
        let survey = this.state.survey;
        return (
            <form>
                <div>
                    <div className="form-row">
                        <div className="col-md-12 text-center">
                            <h4><b>{this.state.pupil.name}</b></h4>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <span className="col-form-label">Siz hududingizdagi akademik litsey va kasb-hunar kоllejlari haqidagi ma`lumоtlarga egamisiz?</span>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-4 form-check">
                            <input type="radio" name="haveReduction" id="haveReduction1"
                                   defaultChecked={survey !== null && survey.haveReduction !== null && survey.haveReduction.id === 1}/>
                            <label className="form-check-label" htmlFor="haveReduction1">To`liq ma'lumоtga
                                egaman</label>
                        </div>
                        <div className="form-group col-md-4">
                            <input type="radio" name="haveReduction" id="haveReduction2"
                                   defaultChecked={survey !== null && survey.haveReduction !== null && survey.haveReduction.id === 2}/>
                            <label className="form-check-label" htmlFor="haveReduction2">Qisman ma'lumоtga
                                egaman</label>
                        </div>
                        <div className="form-group col-md-4">
                            <input type="radio" name="haveReduction" id="haveReduction3"
                                   defaultChecked={survey !== null && survey.haveReduction !== null && survey.haveReduction.id === 3}/>
                            <label className="form-check-label" htmlFor="haveReduction3">Ma'lumоtga ega emasman</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <span>9-sinfni muvaffaqiyatli bitirgandan so‘ng hududingizdagi qaysi ta`lim muassasasida o‘qishni davom ettirishni istaysiz?</span>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <select id="studyType" className="form-control" onChange={this.inst1Change.bind(this)}
                                    defaultValue={survey !== null ? survey.studyType !== null && survey.studyType.id : 1}>
                                <option value="1">Maktabning 10-sinfida</option>
                                <option value="2">Kasb-hunar maktabida</option>
                                <option value="3">Akademik litseyda</option>
                            </select>
                        </div>
                    </div>
                    {this.state.instType !== 1 &&
                    <div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <select id='states1' className="form-control" onChange={this.onChangeStates.bind(this)}
                                        defaultValue={survey !== null && survey.region !== null ? survey.region.id : 1}>
                                    <option value="1">Qoraqalpog`iston Respublikasi</option>
                                    <option value="2">Andijon viloyati</option>
                                    <option value="3">Buxoro viloyati</option>
                                    <option value="4">Qashqadaryo viloyati</option>
                                    <option value="5">Jizzax viloyati</option>
                                    <option value="6">Navoiy viloyati</option>
                                    <option value="7">Namangan viloyati</option>
                                    <option value="8">Samarqand viloyati</option>
                                    <option value="9">Sirdaryo viloyati</option>
                                    <option value="10">Surxondaryo viloyati</option>
                                    <option value="11">Toshkent viloyati</option>
                                    <option value="12">Farg`ona viloyati</option>
                                    <option value="13">Xorazm viloyati</option>
                                    <option value="14">Toshkent shahri</option>
                                    {this.state.instType === 3 &&
                                    <option value="15">Vazirliklar tasarrufidagi AL lar</option>
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <select id="institutions"
                                        defaultValue={survey !== null && survey.institution !== null ? survey.institution.id : ""}>
                                    {this.state.institutions}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <select id="tendencies"
                                        defaultValue={survey !== null && survey.tendency !== null ? survey.tendency.id : ""}>
                                    {this.state.tendencies}
                                </select>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <span>Kasb tanlashizda va kasb-hunar maktabida (10-sinfda, akademik litseyda) o‘qishni davom ettirishizga kimlar ko‘maklashdi?</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-row" id="choiceTypeDiv">
                            <div className="col-md-3">
                                <p>
                                    <input type="radio" name="choiceType" id="option1"
                                           defaultChecked={survey !== null && survey.choiceType !== null && survey.choiceType.id === 1}/>
                                    <label htmlFor="option1">Mustaqil o`zim</label>
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p>
                                    <input type="radio" name="choiceType" id="option2"
                                           defaultChecked={survey !== null && survey.choiceType !== null && survey.choiceType.id === 2}/>
                                    <label htmlFor="option2">Ota-onam</label>
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p>
                                    <input type="radio" name="choiceType" id="option3"
                                           defaultChecked={survey !== null && survey.choiceType !== null && survey.choiceType.id === 3}/>
                                    <label htmlFor="option3">O‘qituvchim</label>
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p>
                                    <input type="radio" name="choiceType" id="option4"
                                           defaultChecked={survey !== null && survey.choiceType !== null && survey.choiceType.id === 4}/>
                                    <label htmlFor="option4">O‘rtoqlarim bilan</label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <span>Kasb-hunar maktabi yoki akademik litseylarda ta`lim o‘zbek, rus va qoraqalpoq tillarida olib boriladi. Shundan kelib chiqib o‘qitish tilini tanlang.</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-row" id="languageDiv">
                            <div className="col-md-4">
                                <p>
                                    <input type="radio" name="language" id="language1"
                                           defaultChecked={survey !== null && survey.language !== null && survey.language.id === 1}/>
                                    <label htmlFor="language1">O`zbek tilida</label>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p>
                                    <input type="radio" name="language" id="language2"
                                           defaultChecked={survey !== null && survey.language !== null && survey.language.id === 2}/>
                                    <label htmlFor="language2">Rus tilida</label>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p>
                                    <input type="radio" name="language" id="language3"
                                           defaultChecked={survey !== null && survey.language !== null && survey.language.id === 3}/>
                                    <label htmlFor="language3">Qoraqalpoq tilida</label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-success" onClick={this.saveSurvey.bind(this)}>Saqlash</button>
                    </div>
                </div>
            </form>
        )
    }
}

export default PupilSurveyView;