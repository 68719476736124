import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import '../index.css';
import swal from 'sweetalert';
import RegionMenu from "./RegionMenu";

class PsychologistPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			testResults: [],
			districts: [],
			schools: [],
			disabledVal1: true,
			disabledVal2: true
		}
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem('userData'));
		axios.get(_.apiHost + '/pupil/district/list?stateId=' + userData.stateId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let districtList = [];
					for (let i = 0; i < data.data.data.length; i++) {
						let user = data.data.data[i];
						districtList.push(<option key={user.id} value={user.id}>{user.name}</option>)
					}
					this.setState({
						districts: districtList
					})
				} else {
					this.setState({
						districts: []
					})
				}
			})
	}

	onChangeDistrict(e) {
		let districtId = document.getElementById('district').value;
		axios.get(_.apiHost + '/pupil/school/list?districtId=' + districtId)
			.then(data => {
				if (data.data.statusCode === 200) {
					let schoolList = [];
					for (let i = 0; i < data.data.data.length; i++) {
						let school = data.data.data[i];
						schoolList.push(<option key={school.id} value={school.id}>{school.name}</option>)
					}
					this.setState({
						schools: schoolList
					})
				} else {
					this.setState({
						schools: []
					})
				}
			});
		e.target.style.backgroundColor = "#ffffff !important";
	}

	save() {
		let errors = 0;
		//Asosiy ma'lumotlar
		let schoolId = document.getElementById('school').value;
		errors = _.validateSelect('school', errors);
		let positionId = document.getElementById('position').value;
		errors = _.validateSelect('position', errors);
		let byState = document.getElementById('byState').value;
		errors = _.validateSelect('byState', errors);
		let lastName = document.getElementById('lastName').value;
		errors = _.validateField('lastName', lastName, errors);
		let firstName = document.getElementById('firstName').value;
		errors = _.validateField('firstName', firstName, errors);
		let middleName = document.getElementById('middleName').value;
		errors = _.validateField('middleName', middleName, errors);
		let birthDate = document.getElementById('birthDate').value;
		errors = _.validateField('birthDate', birthDate, errors);
		let gender = document.getElementById('gender').value;
		errors = _.validateSelect('gender', errors);
		let nationalityId = document.getElementById('nationality').value;
		errors = _.validateSelect('nationality', errors);
		let institution = document.getElementById('institution').value;
		errors = _.validateField('institution', institution, errors);
		let institutionYear = document.getElementById('institutionYear').value;
		errors = _.validateField('institutionYear', institutionYear, errors);
		let educationType = document.getElementById('educationType').value;
		errors = _.validateSelect('educationType', errors);
		let workExperience = document.getElementById('workExperience').value;
		errors = _.validateField('workExperience', workExperience, errors);

		//Diplom ma'lumotlari
		let speciality = document.getElementById('speciality').value;
		errors = _.validateField('speciality', speciality, errors);
		let specialization = document.getElementById('specialization').value;
		// errors = _.validateField('specialization', specialization, errors);
		let diplomaSeries = document.getElementById('diplomaSeries').value;
		errors = _.validateField('diplomaSeries', diplomaSeries, errors);
		let diplomaNumber = document.getElementById('diplomaNumber').value;
		errors = _.validateField('diplomaNumber', diplomaNumber, errors);
		let diplomaImage = document.getElementById('diplomaImage').files[0];
		errors = _.validateField('diplomaImage', diplomaImage, errors);

		//Malaka oshirish
		let trainingType = document.getElementById('trainingTypeYes').checked ? "YES" : "NO";
		let trainingInstitution = document.getElementById('trainingInstitution').value;
		let trainingYear = document.getElementById('trainingYear').value;
		let trainingMonth = document.getElementById('trainingMonth').value;
		let certificateImage = document.getElementById('certificateImage').files[0];
		if (trainingType === "YES") {
			errors = _.validateField('trainingInstitution', trainingInstitution, errors);
			errors = _.validateField('trainingYear', trainingYear, errors);
			errors = _.validateSelect('trainingMonth', errors);
			errors = _.validateField('certificateImage', certificateImage, errors);
		}

		//Qayta tayyorlov
		let reTrainingType = document.getElementById('reTrainingTypeYes').checked ? "YES" : "NO";
		let reTrainingInstitution = document.getElementById('reTrainingInstitution').value;
		let reTrainingYear = document.getElementById('reTrainingYear').value;
		let reTrainingMonth = document.getElementById('reTrainingMonth').value;
		let reTrainingDiplomaSeries = document.getElementById('reTrainingDiplomaSeries').value;
		let reTrainingDiplomaNumber = document.getElementById('reTrainingDiplomaNumber').value;
		let reTrainingDiplomaImage = document.getElementById('reTrainingDiplomaImage').files[0];
		if (reTrainingType === "YES") {
			errors = _.validateField('reTrainingInstitution', reTrainingInstitution, errors);
			errors = _.validateField('reTrainingYear', reTrainingYear, errors);
			errors = _.validateSelect('reTrainingMonth', errors);
			errors = _.validateField('reTrainingDiplomaSeries', reTrainingDiplomaSeries, errors);
			errors = _.validateField('reTrainingDiplomaNumber', reTrainingDiplomaNumber, errors);
			errors = _.validateField('reTrainingDiplomaImage', reTrainingDiplomaImage, errors);
		}

		if (+errors === 0) {
			let formData = new FormData();
			formData.append("schoolId", schoolId);
			formData.append("positionId", positionId);
			formData.append("byState", byState);
			formData.append("lastName", lastName);
			formData.append("firstName", firstName);
			formData.append("middleName", middleName);
			formData.append("birthDate", birthDate);
			formData.append("gender", gender);
			formData.append("nationalityId", nationalityId);
			formData.append("institution", institution);
			formData.append("institutionYear", institutionYear);
			formData.append("educationType", educationType);
			formData.append("workExperience", workExperience);

			formData.append("speciality", speciality);
			formData.append("specialization", specialization);
			formData.append("diplomaSeries", diplomaSeries);
			formData.append("diplomaNumber", diplomaNumber);
			formData.append("diplomaImage", diplomaImage);

			formData.append("trainingType", trainingType);
			formData.append("trainingInstitution", trainingInstitution);
			formData.append("trainingYear", trainingYear);
			formData.append("trainingMonth", trainingMonth);
			formData.append("certificateImage", certificateImage);

			formData.append("reTrainingType", reTrainingType);
			formData.append("reTrainingInstitution", reTrainingInstitution);
			formData.append("reTrainingYear", reTrainingYear);
			formData.append("reTrainingMonth", reTrainingMonth);
			formData.append("reTrainingDiplomaSeries", reTrainingDiplomaSeries);
			formData.append("reTrainingDiplomaNumber", reTrainingDiplomaNumber);
			formData.append("reTrainingDiplomaImage", reTrainingDiplomaImage);

			axios.post(_.apiHost + '/psychologist/add', formData)
				.then(result => {
					if (result.data.statusCode === 200) {
						swal("Ma'lumot saqlandi", "", 'success');
					} else {
						swal("Ma'lumot saqlashda xatolik", "", 'error');
					}
				})
		} else {
			swal("Hamma ma'lumotlarni kiriting", "", 'error');
		}
	}

	enableVal1() {
		this.setState({
			disabledVal1: true
		})
	}

	disableVal1() {
		this.setState({
			disabledVal1: false
		})
	}

	enableVal2() {
		this.setState({
			disabledVal2: true
		})
	}

	disableVal2() {
		this.setState({
			disabledVal2: false
		})
	}

	render() {
		return (
			<div className="content">
				<RegionMenu/>
				<div className="container card card-body my-4">
					<fieldset>
						<legend>Asosiy ma'lumotlar</legend>
						<div className="row">
							<div className="col s3">
								<label htmlFor="district">Tumani</label>
								<select id="district" onChange={this.onChangeDistrict.bind(this)}>
									<option value="0">Tanlang</option>
									{this.state.districts}
								</select>
							</div>
							<div className="col s3">
								<label htmlFor="school">Maktabi</label>
								<select id="school" onChange={_.selectValueChanged.bind(this)}>
									<option value="0">Tanlang</option>
									{this.state.schools}
								</select>
							</div>
							<div className="col s3">
								<label htmlFor="position">Ajratilgan shtat</label>
								<select id="position" onChange={_.selectValueChanged}>
									<option value="1">1</option>
									<option value="2">1.5</option>
									<option value="3">2</option>
									<option value="4">2.5</option>
									<option value="5">3</option>
								</select>
							</div>
							<div className="col s3">
								<label htmlFor="byState">Shtat bo`yicha</label>
								<select id="byState" onChange={_.selectValueChanged}>
									<option value="1">Asosiy shtatda</option>
									<option value="2">O`rindosh 0.25</option>
									<option value="3">O`rindosh 0.5</option>
									<option value="4">O`rindosh 0.75</option>
								</select>
							</div>
						</div>

						<div className="row">
							<div className="col s3">
								<label htmlFor="lastName">Familiyasi</label>
								<input id="lastName" type="text" onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="firstName">Ismi</label>
								<input id="firstName" type="text" onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="middleName">Otasining ismi</label>
								<input id="middleName" type="text" onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="birthDate">Tug`ilgan sanasi</label>
								<input id="birthDate" type="date" onChange={_.inputValueChanged}/>
							</div>
						</div>

						<div className="row">
							<div className="col s3">
								<label htmlFor="gender">Jinsi</label>
								<select id="gender" onChange={_.selectValueChanged}>
									<option value="1">Erkak</option>
									<option value="2">Ayol</option>
								</select>
							</div>
							<div className="col s3">
								<label htmlFor="nationality">Millati</label>
								<select id="nationality" onChange={_.selectValueChanged}>
									<option value="0">Tanlang</option>
									<option value="1">O`zbek</option>
									<option value="2">Rus</option>
									<option value="3">Qoraqalpoq</option>
									<option value="4">Qozoq</option>
									<option value="5">Qirg`iz</option>
									<option value="6">Tojik</option>
									<option value="7">Turkman</option>
									<option value="8">Boshqa</option>
								</select>
							</div>
							<div className="col s3">
								<label htmlFor="institution">Tugatgan oliygohi nomi</label>
								<input id="institution" type="text" onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="institutionYear">Tugatgan yili</label>
								<input id="institutionYear" type="number" onChange={_.inputValueChanged}/>
							</div>
						</div>
						<div className="row">
							<div className="col s3">
								<label htmlFor="workExperience">Psixolog sifatidagi ish staji</label>
								<input id="workExperience" type="number" onChange={_.inputValueChanged}/>
							</div>

							<div className="col s3">
								<label htmlFor="educationHighType">Ma'lumoti</label>
								<select id="educationType" onChange={_.selectValueChanged}>
									<option value="1">O`rta maxsus</option>
									<option value="2">Oliy</option>
									<option value="3">Bakalavr</option>
									<option value="4">Magistr</option>
									<option value="5">Fan nomzodi</option>
									<option value="6">Fan doktori</option>
								</select>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Diplom ma'lumotlari</legend>
						<div className="row">
							<div className="col s3">
								<label htmlFor="speciality">Diplom bo`yicha mutaxassisligi</label>
								<input id="speciality" type="text" onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="specialization">Diplom bo`yicha ixtisosligi</label>
								<input id="specialization" type="text" onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="diplomaSeries">Diplom seriyasi</label>
								<input id="diplomaSeries" type="text" onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="diplomaNumber">Diplom raqami</label>
								<input id="diplomaNumber" type="number" onChange={_.inputValueChanged}/>
							</div>
						</div>

						<div className="row">
							<div className="col s3">
								<label htmlFor="diplomaImage">Diplom rasmini yuklang:</label>
								<input id="diplomaImage" type="file" onChange={_.inputValueChanged}/>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Psixolog sifatidagi malaka oshirganligi</legend>
						<div className="row">
							<div className="col s3">
								<div>
									<label>
										<input name="trainingType" type="radio" id="trainingTypeNo"
										       defaultChecked={true}
										       onClick={this.enableVal1.bind(this)}/>
										<span>O`tmagan</span>
									</label>
									<label>
										<input name="trainingType" type="radio" id="trainingTypeYes"
										       onClick={this.disableVal1.bind(this)}/>
										<span>O`tgan</span>
									</label>
								</div>
							</div>
							<div className="col s3">
								<label htmlFor="trainingInstitution">Joyi</label>
								<input id="trainingInstitution" type="text" disabled={this.state.disabledVal1}
								       onChange={_.inputValueChanged}/>
							</div>

							<div className="col s3">
								<label htmlFor="trainingYear">Yili</label>
								<input id="trainingYear" type="number" disabled={this.state.disabledVal1}
								       onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="trainingMonth">Oyi</label>
								<select id="trainingMonth" disabled={this.state.disabledVal1}
								        onChange={_.selectValueChanged}>
									<option value="1">Yanvar</option>
									<option value="2">Fevral</option>
									<option value="3">Mart</option>
									<option value="4">Aprel</option>
									<option value="5">May</option>
									<option value="6">Iyun</option>
									<option value="7">Iyul</option>
									<option value="8">Avgust</option>
									<option value="9">Sentyabr</option>
									<option value="10">Oktyabr</option>
									<option value="11">Noyabr</option>
									<option value="12">Dekabr</option>
								</select>
							</div>
						</div>

						<div className="row">
							<div className="col s3">
								<label htmlFor="certificateImage">Sertifikat rasmini yuklang</label>
								<input id="certificateImage" type="file" disabled={this.state.disabledVal1}
								       onChange={_.inputValueChanged}/>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Psixolog sifatida qayta tayyorlovdan o`tganligi</legend>
						<div className="row">
							<div className="col s3">
								<div>
									<label>
										<input type="radio" id="reTrainingTypeNo" name="reTrainingType"
										       defaultChecked={true}
										       onClick={this.enableVal2.bind(this)}/>
										<span>O`tmagan</span>
									</label>
									<label>
										<input type="radio" id="reTrainingTypeYes" name="reTrainingType"
										       onClick={this.disableVal2.bind(this)}/>
										<span>O`tgan</span>
									</label>
								</div>
							</div>

							<div className="col s3">
								<label htmlFor="reTrainingInstitution">Joyi</label>
								<input id="reTrainingInstitution" type="text" disabled={this.state.disabledVal2}
								       onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="reTrainingYear">Yili</label>
								<input id="reTrainingYear" type="number" disabled={this.state.disabledVal2}
								       onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="reTrainingMonth">Oyi</label>
								<select id="reTrainingMonth" disabled={this.state.disabledVal2}
								        onChange={_.selectValueChanged}>
									<option value="1">Yanvar</option>
									<option value="2">Fevral</option>
									<option value="3">Mart</option>
									<option value="4">Aprel</option>
									<option value="5">May</option>
									<option value="6">Iyun</option>
									<option value="7">Iyul</option>
									<option value="8">Avgust</option>
									<option value="9">Sentyabr</option>
									<option value="10">Oktyabr</option>
									<option value="11">Noyabr</option>
									<option value="12">Dekabr</option>
								</select>
							</div>
						</div>

						<div className="row">
							<div className="col s3">
								<label htmlFor="reTrainingDiplomaSeries">Diplom seriyasi</label>
								<input id="reTrainingDiplomaSeries" type="text" disabled={this.state.disabledVal2}
								       onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="reTrainingDiplomaSeries">Diplom raqami</label>
								<input id="reTrainingDiplomaNumber" type="number" disabled={this.state.disabledVal2}
								       onChange={_.inputValueChanged}/>
							</div>
							<div className="col s3">
								<label htmlFor="reTrainingDiplomaImage">Diplom rasmini yuklang</label>
								<input id="reTrainingDiplomaImage" type="file" disabled={this.state.disabledVal2}
								       onChange={_.inputValueChanged}/>
							</div>
						</div>
					</fieldset>
				</div>
				<div className="row center">
					<div className="col s12">
						<button className="btn btn-default" onClick={this.save.bind(this)}>Saqlash</button>
					</div>
				</div>
			</div>
		)
	}
}

export default PsychologistPage;