import React, {Component} from 'react';
import SchoolMenu from "./school/SchoolMenu";
import DistrictMenu from "./district/DistrictMenu";
import RegionMenu from "./region/RegionMenu";
import * as _ from './Utils';

class Help extends Component {

  render() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return (
      <div style={{width: "100%"}}>
        {_.isSchool() &&
          <SchoolMenu/>
        }
        {_.isDistrict() &&
          <DistrictMenu/>
        }
        {_.isRegion() &&
          <RegionMenu/>
        }
        <div style={{padding: "20px"}}>
          <p>Maktab kabineti menyusi quyidagi tuzilishga ega: </p>
            <ol>
              <li>Bosh sahifa</li>
              <li>O`quvchilar</li>
              <li>O`qituvchilar</li>
              <li>Yordam</li>
              <li>Chiqish</li>
            </ol>
          <p>Har bir menyu qismi uchun alohida to`xtalib o`tamiz:</p>
          <ol>
            <li>Bosh sahifa - Bu maktab kabinetining dastlabki sahifasini ochish uchun ishlatiladi. Maktab kabineti ochilganda dastlab shu sahifa ochiladi</li>
            <li>O`quvchilar - Bu menyuda bir nechta qismlari bo`lib, Ular: <u>Ro`yxat</u>, <u>Psixologik test</u>, <u>Test natijasi</u>, <u>So`rovnoma</u></li>
            <ol>
              <li>Ro`yxat - O`quvchilar ro`yxatini sinflar kesimida chiqarish, Excelga eksport qilish imkoniyatlari mavjud. Ro`yxatning tepa qismida "O`quvchi qo`shish" va
                "Exceldan import qilish" tugmalari joylashgan. "O`quvchi qo`shish" tugmasi bosilganda tizimga yangi o`quvchi ma'lumotlarini kiritish oynasi ochiladi.
                "Exceldan import qilish" tugmasi bosilganda o`quvchilar ro`yxatini Exceldan tizimga yuklash oynasi ochiladi. Oynani tepa qismida sinfni tanlanadi.
                Fayl yuklash qismida XLSX formatdagi Excel fayl ko`rsatiladi. xt.uzedu.uz saytidagi maktab kabinetidan o`quvchilar ro`yxatini Excelga eksport qilib,
                XLSX formatda saqlab, keyin shu faylni yuklash mumkin. xt.uzedu.uz sayti maktab kabinetidan olingan Excel faylda biron bir o`zgartirish qilish shart emas.
                Agar Excel faylni o`zingiz to`ldirmoqchi bo`lsangiz, u holda mana bu shablondan foydalaning: <a href="./shablon.xlsx">Excel fayl shabloni</a>
                Ro`yxatda har bir o`quvchi ma'lumotlarini tahrirlash va o`chirish imkoniyatlari mavjud</li>
              <li>Psixologik test - O`quvchilar o`rtasida o`tkaziladigan psixologik testni boshlash sahifasi</li>
              <li>Test natijasi - O`quvchilar o`rtasida o`tkaziladigan psixologik test natijalarini ko`rish va zarur holda eksport qilish imkoniniyati mavjud</li>
              <li>So`rovnoma - O`quvchilar o`rtasida o`tkaziladigan so`rovnoma va uning natijalarini ko`rish va zarur holda eksport qilish imkoniniyati mavjud</li>
            </ol>
            <li>O`qituvchilar - O`qituvchilardan olinadigan psixologik testlarni boshlash sahifasi ochiladi</li>
            <li>Yordam - Maktab kabinetidagi fuksiyalar haqida ma'lumot beruvchi sahifa</li>
            <li>Profil - Foydalanuvchi profili
              <ol>
                <li>Parolni o`zgartirish - foydalanuvchi parolini o`zgartirish sahifasini ochish uchun</li>
                <li>Maktab kabinetidan chiqish - Maktab kabinetidan chiqish uchun bosiladi</li>
              </ol>
            </li>
          </ol>
        </div>
        {(userData === undefined || userData === null) &&
        <span>Tizimga kirish uchun login varolni to`g`ri tering</span>
        }
      </div>
    )
  }
}

export default Help;