import React, {Component} from 'react';
import '../App.css';
import axios from "axios";
import * as _ from '../Utils';
import RegionMenu from "./RegionMenu";
import AdminMenu from "../admin/AdminMenu";
import DistrictMenu from "../district/DistrictMenu";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import Modal from "react-responsive-modal";
import ChangePassword2View from "../ChangePassword2View";

class RegionPupilCountReport extends Component {

	constructor() {
		super();
		let userData = JSON.parse(localStorage.getItem('userData'));
		this.state = {
			reportRows: [],
			userData: userData,
			userType: userData !== undefined && userData !== null ? userData.role : '',
			userId: userData !== undefined && userData !== null ? userData.stateId : '',
			showPopup: false,
			showPasswordPopup: false,
			user: {}
		};
		this.fetchReportData = this.fetchReportData.bind(this);
	}

	componentDidMount() {
		this.fetchReportData(this.state.userType, this.state.userId);
	}

	openLink(stateId) {
		let userType = this.state.userType;
		if (this.state.userType === 'ADMIN') {
			this.setState({
				userType: 'STATE',
				userId: stateId
			});
			userType = 'STATE';
		} else if (this.state.userType === 'STATE') {
			this.setState({
				userType: 'DISTRICT',
				userId: stateId
			});
			userType = 'DISTRICT';
		}
		this.fetchReportData(userType, stateId);
	}

	fetchReportData(userType, stateId) {
		axios.get(_.apiHost + '/report/pupilCountReport?relatedTo=' + userType + '&relatedId=' + stateId)
			.then(result => {
				if (result.data.statusCode === 200) {
					let options = [];
					for (let i = 0; i < result.data.data.length; i++) {
						let user = result.data.data[i];
						options.push(<tr key={user.id}>
							<td>{user.id}</td>
							<td>
								<ContextMenuTrigger id={'id_' + user.schoolId}>
									<span className="app-link" onClick={this.openLink.bind(this, user.schoolId)}>{user.schoolName}</span>
								</ContextMenuTrigger>
								<ContextMenu id={'id_' + user.schoolId}>
									<MenuItem data={{name: 'check', id: user.schoolId}} onClick={this.handleClick}>Parolni ko`rish</MenuItem>
									<MenuItem data={{name: 'change', id: user.schoolId}} onClick={this.handleClick}>Parolni o`zgartirish</MenuItem>
								</ContextMenu>
							</td>
							<td className="text-center">{user.result1Count}</td>
							<td className="text-center">{user.result2Count}</td>
							<td className="text-center">{user.result3Count}</td>
						</tr>)
					}
					this.setState({
						reportRows: options
					})
				}
			})
	}

	handleClick = (e, data) => {
		console.log(`${data.name} ${data.id}`);
		let userType = this.state.userType;
		if (this.state.userType === 'ADMIN') {
			userType = 'STATE';
		} else if (this.state.userType === 'STATE') {
			userType = 'DISTRICT';
		} else if (this.state.userType === 'DISTRICT') {
			userType = 'SCHOOL';
		}
		axios.get(_.apiHost + '/user/getLoginPass?relatedTo=' + userType + '&relatedId=' + data.id)
			.then(result => {
				if (result.data.statusCode === 200) {
					if (`${data.name}` === 'check') {
						this.setState({
							user: result.data.data,
							showPopup: true
						})
					} else if (`${data.name}` === 'change') {
						this.setState({
							user: result.data.data,
							showPasswordPopup: true
						})
					}
				}
			});
	};

	hideModal(popupName) {
		this.setState({
			[popupName]: false
		})
	}

	hideChangePasswordModal() {
		this.setState({
			showPasswordPopup: false
		})
	}

	render() {
		return (
			<div className="content">
				{_.isAdmin() &&
				<AdminMenu/>
				}
				{_.isRegion() &&
				<RegionMenu/>
				}
				{_.isDistrict() &&
				<DistrictMenu/>
				}
				{this.state.userData !== undefined && this.state.userData !== null &&
				<div className="card-body my-4 shadow-sm">
					<div className="form-row form-group">
						<div className="container-fluid text-center">
							<p>{_.isAdmin() ? 'Respublikadagi ' : _.isRegion() ? this.state.userData.stateName : _.isDistrict() ? this.state.userData.stateName + " " + this.state.userData.districtName + " " : ''}
								umumiy o'rta ta'lim muassasalaridagi o'quvchilar soni to'g'risida ma'lumot</p>
						</div>
						<table className="table table-striped table-bordered">
							<thead>
							<tr>
								<th className="headerCell">№</th>
								<th
									className="headerCell">{_.isAdmin() ? 'Hudud nomi' : this.state.userData.role === "STATE" ? 'Tuman nomi' : this.state.userData.role === "DISTRICT" ? 'Maktab nomi' : ''}</th>
								<th className="headerCell">Jami 9-sinf</th>
								<th className="headerCell">Jami 10-sinf</th>
								<th className="headerCell">Jami 11-sinf</th>
							</tr>
							</thead>
							<tbody>
							{this.state.reportRows}
							</tbody>
						</table>
					</div>
				</div>
				}
				<Modal open={this.state.showPopup} center closeOnEsc={true} onClose={this.hideModal.bind(this, 'showPopup')}>
					<div style={{padding: "20px"}}>
						<table>
							<tbody>
							<tr>
								<td>Nomi:</td>
								<td>{this.state.user.role === 'SCHOOL' ? this.state.user.stateName + ' ' + this.state.user.districtName + ' ' + this.state.user.schoolName :
										 this.state.user.role === 'DISTRICT' ? this.state.user.stateName + ' ' + this.state.user.districtName :
										 this.state.user.role === 'STATE' ? this.state.user.stateName + ' ' + this.state.user.districtName : ''}</td>
							</tr>
							<tr>
								<td>Login:</td>
								<td>{this.state.user.userName}</td>
							</tr>
							<tr>
								<td>Parol:</td>
								<td>{this.state.user.password}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</Modal>
				<Modal open={this.state.showPasswordPopup} center closeOnEsc={true} onClose={this.hideModal.bind(this, 'showPasswordPopup')}>
					<ChangePassword2View user={this.state.user} onClose={this.hideModal.bind(this, 'showPasswordPopup')}/>
				</Modal>
			</div>
		)
	}
}

export default RegionPupilCountReport;