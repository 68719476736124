import React, {Component} from 'react';
import './App.css';
import axios from "axios";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import * as _ from './Utils';
import TestWidget from "./TestWidget";

class ImproveSkillsTestPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      teacherName: '',
      teacherId: null,
      question: '',
      questions: [],
      questionCount: 0,
      questionNumber: 0,
      pupilsAnswers: [],
      answers: [],
      fullAnswered: false,
      showResultPopup: false,
      testResult: ''
    }
  }

  componentDidMount() {
    let teacher = JSON.parse(localStorage.getItem('teacher'));
    if (teacher !== undefined && teacher !== null) {
      this.setState({
        teacherId: teacher.id,
        teacherName: teacher.name
      })
    }
    axios.get(_.apiHost + '/test/improveSkillsTest')
      .then(result => {
        if (result.data.statusCode === 200) {
          let pupilsAnswers = [];
          for (let i = 0; i < result.data.data.length; i++) {
            pupilsAnswers.push(0);
          }
          this.setState({
            questions: result.data.data,
            question: result.data.data[0].question,
            answers: result.data.data[0].answers,
            pupilsAnswers: pupilsAnswers
          });
        }
      })
  }

  toNextQuestion(pupilsAnswer) {
    let q_number = this.state.questionNumber < this.state.questions.length - 1 ? this.state.questionNumber + 1 : 0;
    let pupilsAnswers = [...this.state.pupilsAnswers];
    pupilsAnswers[this.state.questionNumber] = pupilsAnswer;
    let question = this.state.questions[q_number].question;

    let unAnswered = 0;
    for(let i=0; i<pupilsAnswers.length; i++) {
      if (pupilsAnswers[i] === '' || pupilsAnswers[i] === 0) {
        unAnswered = unAnswered + 1;
      }
    }
    let answers1 = this.state.questions[q_number].answers;
    this.setState({
      pupilsAnswers: pupilsAnswers,
      questionNumber: q_number,
      question: question,
      fullAnswered: unAnswered === 0,
      answers: answers1
    });
  }

  onFinishClick() {
    let tens = '';
    for (let i = 0; i < this.state.pupilsAnswers.length; i++) {
      tens = tens + this.state.pupilsAnswers[i] + '_';
    }
    axios.post(_.apiHost + '/test/saveTeacherResult?methodId=17&teacherId=' + this.state.teacherId + '&testResult=' + tens)
      .then(result => {
        if (+result.data.statusCode === 200) {
          Swal.fire({
            title: 'Test natijasi',
            text: result.data.data + ' ball',
            type: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
              localStorage.removeItem('pupil');
              localStorage.removeItem('selectedTest');
              this.setState = {
                question: '',
                questions: [],
                questionCount: 0,
                questionNumber: 0,
                pupilsAnswers: [],
                selectedTest: {},
                pupil: {}
              };
              window.open('/skillTestStart', '_self');
          });
        } else {
          swal(result.data.message, '', 'error');
        }
      });
  }

  closeResultPopup() {
    localStorage.removeItem('teacher');
    this.setState({
      showResultPopup: false,
      question: '',
      testResult: '',
      questions: [],
      questionCount: 0,
      questionNumber: 0,
      answers: []
    });
    window.open('/skillTestStart', '_self');
  }

  render() {
    return (
      <div style={{width: "100%"}}>
        <div className="row">
          <div className="input-field col s12">
            <span>Test topshiruvchi: {this.state.teacherName}</span>
          </div>
        </div>
        <hr/>
        <div style={{textAlign: "left", marginLeft: "10px"}}>
          <TestWidget questionOrder={this.state.questionNumber + 1} question={this.state.question}
                      answers={this.state.answers} toNextQuestion={this.toNextQuestion.bind(this)}/>
        </div>
        {this.state.fullAnswered &&
        <div style={{width: '100%', padding: '20px'}}>
          <button className="btn btn-block" onClick={this.onFinishClick.bind(this)}>FINISH</button>
        </div>
        }
      </div>
    )
  }
}

export default ImproveSkillsTestPage;