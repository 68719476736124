import React, {Component} from 'react';
import {apiHost, getRegions} from "./Utils";
import axios from 'axios';
import swal from 'sweetalert';

export default class SeminarExamStartView extends Component {

    constructor() {
        super();
        this.state = {
            listeners: [],
            answers: [],
            answerList: [],
            questions: [],
            questionList: [],
            startPage: true,
            showSaveBtn: false,
            questionOrder: 0,
            question: '',
            listenerName: '',
            listenerId: '',
            currDate: new Date(2021,6,14,30,0,0).getTime(),
            date1: new Date().getTime()
        }
    }

    onChangeState() {
        let region = document.getElementById('states').value;
        if (Number(region) > 0) {
            axios.get(apiHost + '/teacher/seminar/listeners?regionId=' + region).then(result=>{
                if (result.data.statusCode === 200) {
                    let options = result.data.data.map(row=>{
                        return <option key={row.id} value={row.id}>{row.name}</option>
                    });
                    this.setState({
                        listeners: options
                    })
                }
            })
        }
    }

    onStartTest() {
        let listener = document.getElementById('listener');
        let listenerName = listener.options[listener.selectedIndex].text;
        this.setState({
            startPage: false,
            listenerId: listener.value,
            listenerName: listenerName,
        });
        this.getQuestionList();
    }

    finishTest() {
        let result = this.state.answers.join('_');
        axios.post(apiHost + '/test/seminar/checkTest?listenerId=' + this.state.listenerId + '&result=' + result).then(result=>{
            if (result.data.statusCode === 200) {
                if (Number(result.data.data.result) >= 56) {
                    window.open(result.data.data.message, '_blank');
                } else {
                    swal(result.data.data.message, '', 'warning');
                }
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    onSelectAnswer(e) {
        let answers = this.state.answers;
        let answerId = e.target.id.replace('answer_', '');
        answers[this.state.questionOrder] = answerId;
        this.setState({
            answers: answers
        })
    }

    getQuestionList() {
        let langUzb = document.getElementById('langUzb').checked;
        axios.get(apiHost + '/test/seminarExamTest/list?language=' + (langUzb ? 1 : 2)).then(result=>{
            if (result.data.statusCode === 200) {
                let answerList = result.data.data[0].answers.map(row2 => {
                    return <div className="form-row">
                        <div className="col-sm-12">
                            <div className="form-check ml-2">
                                <input className="form-check-input" type="radio" name="answers" id={'answer_' + row2.id} onClick={this.onSelectAnswer.bind(this)}/>
                                <label className="form-check-label" htmlFor={"answer_" + row2.id}>{row2.answer}</label>
                            </div>
                        </div>
                    </div>
                });
                this.setState({
                    question: result.data.data[0].question,
                    questions: result.data.data,
                    answerList: answerList
                });
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    prevBtnClick() {
        let questionOrder = this.state.questionOrder;
        if (questionOrder > 0) {
            questionOrder = this.state.questionOrder - 1;
        } else {
            questionOrder = 39;
        }
        let answerList = this.state.questions[questionOrder].answers.map(row => {
            return <div className="form-row">
                <div className="col-sm-12">
                    <div className="form-check ml-2">
                        <input className="form-check-input" type="radio" name="answers" id={'answer_' + row.id} onClick={this.onSelectAnswer.bind(this)}/>
                        <label className="form-check-label" htmlFor={"answer_" + row.id}>{row.answer}</label>
                    </div>
                </div>
            </div>
        });
        this.setState({
            answerList: answerList,
            questionOrder: questionOrder,
            question: this.state.questions[questionOrder].question
        })
    }

    nextBtnClick() {
        let questionOrder = this.state.questionOrder;
        if (questionOrder === 39) {
            this.setState({
                showSaveBtn: true
            })
        }
        if (questionOrder < 39) {
            questionOrder = this.state.questionOrder + 1;
        } else {
            questionOrder = 0;
            this.setState({
                showSaveBtn: true
            })
        }
        let answerList = this.state.questions[questionOrder].answers.map(row => {
            return <div className="form-row">
                <div className="col-sm-12">
                    <div className="form-check ml-2">
                        <input className="form-check-input" type="radio" name="answers" id={'answer_' + row.id} onClick={this.onSelectAnswer.bind(this)}/>
                        <label className="form-check-label" htmlFor={"answer_" + row.id}>{row.answer}</label>
                    </div>
                </div>
            </div>
        });
        this.setState({
            answerList: answerList,
            questionOrder: questionOrder,
            question: this.state.questions[questionOrder].question
        })
    }

    render() {
        return (
            <div className="container">
                {this.state.startPage &&
                <div className="card card-body">
                    <div className="form-row">
                        <div className="col-md-12 text-center">
                            <h4>Xalq ta’limi tizimidagi umumiy o‘rta ta’lim muassasalarida tashkil etiladigan inklyuziv va korreksion sinf o‘qituvchilari, psixologlari uchun maqsadli malaka oshirish kursi</h4>
                        </div>
                    </div>

                    <div className="form-row mt-5 mb-5">
                        <div className="col-md-12">
                            <fieldset>
                                <legend>Tinglovchi ma'lumotlari</legend>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <label htmlFor="states">Hudud</label>
                                        <select id="states" className="form-control"
                                                onChange={this.onChangeState.bind(this)}>
                                            {getRegions()}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="listener">Tinglovchi</label>
                                        <select id="listener" className="form-control">
                                            {this.state.listeners}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-12 text-center">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="language" id="langUzb" checked/>
                                            <label className="form-check-label" htmlFor="langUzb">O`zbek</label>
                                        </div>
                                        <div className="form-check form-check-inline ml-5">
                                            <input className="form-check-input" type="radio" name="language" id="langRus"/>
                                            <label className="form-check-label" htmlFor="langRus">Rus</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-12 mt-5 text-center">
                                        <button className="btn btn-success" onClick={this.onStartTest.bind(this)}>Testni
                                            boshlash</button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                }
                {!this.state.startPage &&
                <div className="card card-body">
                    <div className="form-row">
                        <div className="col-md-12 text-center">
                            <h4><b>{'Tinglovchi: ' + this.state.listenerName}</b></h4>
                        </div>
                    </div>
                    <div className="form-row mt-5">
                        <div className="col-md-12">
                            <h4><b>{(this.state.questionOrder + 1) + ' - Savol: ' + this.state.question}</b></h4>
                        </div>
                    </div>
                    <div className="form-row mt-1">
                        <div className="col-md-12">
                            {this.state.answerList}
                        </div>
                    </div>

                    <div className="form-row mt-5">
                        <div className="col-md-4 text-right">
                            <button className="btn btn-info" onClick={this.prevBtnClick.bind(this)}>{'<< Oldingi'}</button>
                        </div>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4">
                            <button className="btn btn-info" onClick={this.nextBtnClick.bind(this)}>{'Keyingi >>'}</button>
                        </div>
                    </div>

                    {this.state.showSaveBtn &&
                    <div className="form-row mt-5">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-success"
                                    onClick={this.finishTest.bind(this)}>{'Yakunlash'}</button>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}